// 创建vue实例
import Vue from "vue";
import App from "./App.vue";
import createRouter from "./router";
// import createStore from "./store";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import $ from "jquery";
// import VueQr from "vue-qr";
// import QRCode from "qrcodejs2";
import createStore from "./store";
import { sync } from "vuex-router-sync";
import Meta from "vue-meta";
Vue.use(Meta);
// import BaiduMap from "vue-baidu-map";

// Vue.use(BaiduMap, {
//   ak: "YvuaGQU1cXDyHZN27YHCMiwjvGA9Yuhn",
// });
// 设置浏览器标题
Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
});
Vue.prototype.$axios = axios;
// Vue.prototype.qrCode = QRCode;
Vue.use(ElementUI);
// 跳转后返回页面顶部
const router = createRouter();
router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default function createApp() {
  const router = createRouter();
  const store = createStore();
  // 同步路由状态(route state)到 store
  sync(store, router);
  const app = new Vue({
    router,
    $,
    // VueQr,
    store,
    render: (h) => h(App),
  });
  return { app, router, store };
}
