<template>
  <div id="app">
    <!-- <detail-nav-bar></detail-nav-bar> -->
    <router-view />
  </div>
</template>

<script src="https://api.map.baidu.com/api?v=2.0&ak=YvuaGQU1cXDyHZN27YHCMiwjvGA9Yuhn"></script>
<script>
// import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
export default {
  // asyncData({ store }) {
  //   console.log(123);
  //   return Promise.all([store.dispatch("proList")]);
  // },
  // components: { DetailNavBar },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
  position: relative;
}
h4 {
  font-family: MicrosoftYaHeiLight;
  font-size: 30px;
  letter-spacing: 3px;
  color: #333333;
}
a {
  text-decoration: none;
}
ul,
li {
  list-style: none;
}
img {
  border: none;
}
</style>
