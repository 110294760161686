<template>
  <div id="linggong">
    <div class="top">
      <img src="@/assets/99linggong_img/linggong111.png" alt="" />
    </div>
    <div class="content" style="margin-top: 30px">
      <div>
        <img src="@/assets/99linggong_img/one/title1.png" alt="" />
      </div>
      <div style="margin-top: 28px; margin-bottom: 59px">
        <img src="@/assets/99linggong_img/one/content.png" alt="" />
      </div>
      <div class="one_content">
        <div style="flex: 1"></div>
        <div class="one_content" v-for="(item, index) in oneList" :key="index">
          <div style="margin: 0 27px 27px 0">
            <img :src="item.src" alt="" />
          </div>
        </div>

        <div style="flex: 1"></div>
      </div>
    </div>
    <div class="content">
      <div>
        <img src="@/assets/99linggong_img/two/title.png" alt="" />
      </div>
      <div style="margin-top: 56px">
        <img src="@/assets/99linggong_img/two/content.png" alt="" />
      </div>
    </div>
    <div
      class="content three_content"
      :style="{
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
    >
      <div>
        <div style="margin-bottom: 59px">
          <img src="@/assets/99linggong_img/three/title.png" alt="" />
        </div>
        <div>
          <span class="three_item">
            <img src="@/assets/99linggong_img/three/img1.png" alt="" />
          </span>
          <span class="three_item">
            <img src="@/assets/99linggong_img/three/img3.png" alt="" />
          </span>
          <span>
            <img src="@/assets/99linggong_img/three/img5.png" alt="" />
          </span>
        </div>
        <div style="margin: 30px 0 30px 0">
          <img
            src="@/assets/99linggong_img/three/fengeline.png"
            alt=""
            style="width: 100%"
          />
        </div>
        <div>
          <span class="three_item">
            <img src="@/assets/99linggong_img/three/img2.png" alt="" />
          </span>
          <span>
            <img src="@/assets/99linggong_img/three/img4.png" alt="" />
          </span>
        </div>
        <!-- 立即登录 -->
        <!-- <div class="login_">
          <div class="btn" @click="login_()" :class="{ btns: login == true }">
            立即登录
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Linggong",
  props: {
    three_img: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      login: false,
      oneList: [
        { src: require("@/assets/99linggong_img/one/img1.png") },
        { src: require("@/assets/99linggong_img/one/img2.png") },
        { src: require("@/assets/99linggong_img/one/img3.png") },
        { src: require("@/assets/99linggong_img/one/img4.png") },
      ],
    };
  },
  created() {
    console.log(this.three_img);
  },
  methods: {
    login_() {
      this.login = true;
    },
  },
};
</script>
<style scoped>
#linggong .top {
  margin-top: 60px;
}
#linggong .top img {
  width: 100%;
  height: auto;
}
.content {
  text-align: center;
  margin-top: 30px;
}
.one_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.three_content {
  padding-top: 200px;
  padding-bottom: 200px;
  /* height: 100%;
  background-image: url("../../assets/99linggong_img/three/bgc_img.png");
  background-size: cover; */
}
.three_item {
  margin-right: 89px;
}
.login_ {
  margin-top: 60px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.btn {
  text-align: center;
  width: 230px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  background-color: #5b5e66;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.btn:hover {
  background-color: #3d93f9;
}
.btns {
  background-color: #3d93f9;
}
</style>
