<template>
  <div >
    <detail-nav-bar></detail-nav-bar>
    <equity></equity>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Equity from "@/views/viewpage/Bookkeeping.vue";
import Foot from "@/components/foot/foot.vue";
// import banner from "@/components/banner.js";

export default {
  name: "Bookkeeping",
  components: { DetailNavBar, Equity, Foot },
   metaInfo: {
    title:
      "代理记账服务_高新记账_坏账梳理_税务风险评估_99企帮",
      // "税收优惠_核定征收_税收奖励_税务咨询_财税服务_财务外包_灵活用工_99企帮_值得信赖的企业服务平台",
   
  },
  data() {
    return {
      footbg: {
        // picture: require("@/assets/equity_img/gaolou.png"),
      },
    };
  },
  created() {},
  methods: {},
};
</script>
