var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"asingle"}},[_c('div',{staticClass:"wrap"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"content"},[_vm._m(1),_vm._v(" "),_c('img',{staticClass:"case",attrs:{"src":require("@/assets/asing_img/case.png"),"alt":""}}),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('img',{staticClass:"map",attrs:{"src":require("@/assets/asing_img/map.png"),"alt":""}}),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('ol',[_c('li',{on:{"click":function($event){return _vm.toDetali(
              'http://www.99qibang.com/schemeDetail.html?id=10ddd3835b574df999ed9770b6ec44fd'
            )}}},[_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9)]),_vm._v(" "),_c('li',{on:{"click":function($event){return _vm.toDetali('http://www.99qibang.com/serviceDetail.html?id=68')}}},[_vm._m(10),_vm._v(" "),_vm._m(11)]),_vm._v(" "),_c('li',{on:{"click":function($event){return _vm.toDetali(
              'http://www.99qibang.com/schemeDetail.html?id=b3a7e4b523474a5cb09c395542e3b926'
            )}}},[_vm._m(12)]),_vm._v(" "),_c('li',{on:{"click":function($event){return _vm.toDetali(
              'http://www.99qibang.com/serviceDetail.html?id=648a5af025a241d7aedd08880466bbf6'
            )}}},[_vm._m(13)])]),_vm._v(" "),_vm._m(14),_vm._v(" "),_vm._m(15),_vm._v(" "),_vm._m(16),_vm._v(" "),_vm._m(17)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("@/assets/asing_img/top.png"),"alt":""}}),_vm._v(" "),_c('button',{staticClass:"topbutton"},[_c('a',{attrs:{"target":"_blank","href":"https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"}},[_vm._v("免费咨询")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title oneTit"},[_c('a',{attrs:{"href":"/view/99shuiyou","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/asing_img/faster.png"),"alt":""}}),_vm._v(" "),_c('div',[_c('p',[_vm._v("节税效果")]),_vm._v(" "),_c('p',{staticClass:"explain"},[_vm._v("安全专业的为企业合理合法的降低税收负担")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buttonBox",attrs:{"href":"tel:400-099-3699"}},[_c('button',{staticClass:"telBtn"},[_c('a',{attrs:{"target":"_blank","href":"https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"}},[_vm._v("免费定制节税方案")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('a',{attrs:{"href":"/view/taxation","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/asing_img/light.png"),"alt":""}}),_vm._v(" "),_c('div',[_c('p',[_vm._v("税收优惠政策")]),_vm._v(" "),_c('p',{staticClass:"explain"},[_vm._v("高效对接优惠园区，精确匹配适合您的税收洼地")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lower"},[_c('p',[_vm._v("税负率降低"),_c('span',[_vm._v("80%")])]),_vm._v(" "),_c('p',[_vm._v("综合税率低至"),_c('span',[_vm._v("0.25%")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buttonBox"},[_c('button',{staticClass:"telBtn"},[_c('a',{attrs:{"target":"_blank","href":"https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"}},[_vm._v("立即咨询")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('a',{attrs:{"href":"/view/99shuiyou","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/asing_img/scheme.png"),"alt":""}}),_vm._v(" "),_c('div',[_c('p',[_vm._v("节税方案")]),_vm._v(" "),_c('p',{staticClass:"explain"},[_vm._v("降低您的税收负担，让您财税无忧")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"119px"}},[_c('span',{staticClass:"round"}),_c('span',[_vm._v("缺少进项发票")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"round"}),_c('span',[_vm._v("企业利润高")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"round"}),_c('span',[_vm._v("增值税和企业所得税太高怎么办？")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"119px"}},[_c('span',{staticClass:"round"}),_c('span',[_vm._v("年底分红缴纳20%个人所得税")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"round"}),_c('span',[_vm._v("税负太高怎么办？")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"119px"}},[_c('span',{staticClass:"round"}),_vm._v(" "),_c('span',[_vm._v("\n              员工个税高，税后工资减少，"),_c('br'),_vm._v(" "),_c('span',{staticClass:"zw"}),_vm._v("企业和个人承办的社保成本太高怎么办？")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"119px"}},[_c('span',{staticClass:"round"}),_c('span',[_vm._v("\n              高收入的兼职或者自由职业者，"),_c('br'),_vm._v(" "),_c('span',{staticClass:"zw"}),_vm._v("劳务个税太高怎么办？\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buttonBox"},[_c('button',{staticClass:"telBtn"},[_c('a',{attrs:{"target":"_blank","href":"https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"}},[_vm._v("免费定制个性化解决方案")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('a',{attrs:{"href":"/view/aboutUs","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/asing_img/scheme.png"),"alt":""}}),_vm._v(" "),_c('div',[_c('p',[_vm._v("我们提供的税收筹划服务")]),_vm._v(" "),_c('p',{staticClass:"explain"},[_vm._v("Vip一对一量身定制")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"li_1"},[_c('p',{staticStyle:{"margin-top":"92px"}},[_vm._v("税收扶持政策咨询")]),_vm._v(" "),_c('p',[_vm._v("专属税收筹划方案定制")]),_vm._v(" "),_c('p',[_vm._v("税收疑难解答")]),_vm._v(" "),_c('p',[_vm._v("高端税筹筹划服务")])]),_vm._v(" "),_c('li',{staticClass:"li_2"},[_c('p',{staticStyle:{"margin-top":"92px"}},[_vm._v("注册地址服务")]),_vm._v(" "),_c('p',[_vm._v("全程税收扶持地企业设立服务")]),_vm._v(" "),_c('p',[_vm._v("银行开户服务")]),_vm._v(" "),_c('p',[_vm._v("税务报到服务")]),_vm._v(" "),_c('p',[_vm._v("税控器申请服务")]),_vm._v(" "),_c('p',[_vm._v("特殊资质申请服务")])]),_vm._v(" "),_c('li',{staticClass:"li_3"},[_c('p',{staticStyle:{"margin-top":"92px"}},[_vm._v("企业建账")]),_vm._v(" "),_c('p',[_vm._v("日常账务处理")]),_vm._v(" "),_c('p',[_vm._v("日常纳税申报")]),_vm._v(" "),_c('p',[_vm._v("年度汇算清缴")]),_vm._v(" "),_c('p',[_vm._v("年度工商年报")]),_vm._v(" "),_c('p',[_vm._v("日常税控器托管")]),_vm._v(" "),_c('p',[_vm._v("日常发票开具及邮寄")]),_vm._v(" "),_c('p',[_vm._v("发票购买申领")])]),_vm._v(" "),_c('li',{staticClass:"li_4"},[_c('p',{staticStyle:{"margin-top":"92px"}},[_vm._v("扶持资金奖励核算")]),_vm._v(" "),_c('p',[_vm._v("扶持资金奖励到位跟踪")]),_vm._v(" "),_c('p',[_vm._v("当地税企协调")]),_vm._v(" "),_c('p',[_vm._v("企业经营中的所有变更")]),_vm._v(" "),_c('p',[_vm._v("公司注销服务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buttonBox"},[_c('button',{staticClass:"telBtn bottom"},[_c('a',{attrs:{"target":"_blank","href":"https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"}},[_vm._v("立即咨询")])])])
}]

export { render, staticRenderFns }