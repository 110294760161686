<template>
  <div class="shuiyou">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <shuiyou></shuiyou>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Shuiyou from "@/views/viewpage/99shuiyou.vue";
// import banner from "@/components/banner.js";

export default {
  metaInfo: {
    title: "财务梳理服务_财税顾问_99税优_让企业财税无忧",
    meta: [
      {
        name: "keywords",
        content:
          "税收优惠，税收奖励，个人独资，个体工商户，有限公司返还，有限公司奖励，核定征收，财税服务，财税顾问，账务梳理，代理记账，账务梳理，股权架构，高新认定，财务外包",
      },
      {
        name: "description",
        content:
          "99税优向企业提供财务梳理服务、常年财务顾问服务、企业财务管理服务、企业并购重组服务、高新技术企业认定服务、高新记账服务、财务岗位外包服务及企业税收优惠政策匹配和政企对接落地辅导等",
      },
    ],
  },
  name: "SHUIYOU",
  components: { DetailNavBar, Foot, Shuiyou },
  data() {
    return {
      // qbAdvertisements: [
      //   { picture: require("@/assets/99shuiyou_img/banner.png") },
      // ],
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
