<template>
  <div id="equity">
    <div class="wrap">
      <div class="top">
        <div class="top-content display-flex">
          <img src="@/assets/equity_img/top1.png" class="top1" alt="" />
          <img src="@/assets/equity_img/top2.png" class="top2" alt="" />
          <div class="top-strength display-flex">
            <img src="@/assets/equity_img/button01.png" alt="" />
            <img src="@/assets/equity_img/button02.png" alt="" />
            <img src="@/assets/equity_img/button03.png" alt="" />
          </div>
          <div class="top-consult display-flex">
            <a
               target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=gqjs"
              
            >
              <img src="@/assets/equity_img/consult.png" alt="" />
            </a>
            <a
               target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=gqjs"
              
            >
              <img src="@/assets/equity_img/policy.png" alt="" />
            </a>
          </div>
        </div>
        <img src="@/assets/equity_img/bj.png" class="top-bj" alt="" />
      </div>
      <div class="why">
        <div class="why-bj">
          <ul class="display-flex">
            <li>
              <img src="@/assets/equity_img/gantanhao.png" alt="" />
              <span>股权转让高溢价税负重</span>
            </li>
            <li>
              <img src="@/assets/equity_img/gantanhao.png" alt="" />
              <span>转股优惠政策不了解</span>
            </li>
            <li>
              <img src="@/assets/equity_img/gantanhao.png" alt="" />
              <span>大量资金流转风险高</span>
            </li>
            <li>
              <img src="@/assets/equity_img/gantanhao.png" alt="" />
              <span>转股事项繁多操作复杂</span>
            </li>
            <li>
              <img src="@/assets/equity_img/gantanhao.png" alt="" />
              <span>转股节税办理周期长</span>
            </li>
            <li>
              <img src="@/assets/equity_img/gantanhao.png" alt="" />
              <span>转让优惠政策享不了</span>
            </li>
          </ul>
          <a
             target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=gqjs"
            class="why-btn"
           
          >
            <img src="@/assets/equity_img/02bj.png" alt="" />
          </a>
        </div>
      </div>
      <div class="case">
        <div class="case-bj">
          <p class="case-top">
            北京某公司，原股份购买成本500万元，现以8000万元的价格进行转让。
          </p>
          <div class="case-content display-flex">
            <div class="case-left">
              <h3>筹划前<img src="@/assets/equity_img/left.png" alt=" " /></h3>

              <p>所得税:1500万</p>
              <p>印花税:4万</p>
              <p>税收总计:1504万</p>
            </div>
            <div class="case-right">
              <h3>筹划后<img src="@/assets/equity_img/left2.png" alt=" " /></h3>
              <div class="display-flex">
                <p>所得税:<span>364万</span></p>
                <p>税收总计:<span>4万</span></p>
                <p>印花税:<span>368万</span></p>
                <p class="case-scale">节税比例:76%</p>
                <p class="case-result">
                  增加可支配收入、最大化税收收益、避免税务 处罚、安心省心节税
                </p>
              </div>
            </div>
            <p class="vs">vs</p>
          </div>
        </div>
      </div>
      <div class="major">
        <img src="@/assets/equity_img/biaoti.png" class="major-top" alt=" " />
        <ul>
          <li>
            <img src="@/assets/equity_img/icon01.png" alt=" " />
            <h3>10</h3>
            <span class="add">多年</span>
            <span class="underline">/</span>
            <span>企业税筹服务经验</span>
          </li>
          <li>
            <img src="@/assets/equity_img/icon01.png" alt=" " />
            <h3>20</h3>
            <span class="add" style="width: 40px">+</span>
            <span class="underline">/</span>
            <span>自营园区、灵活迁移</span>
          </li>
          <li>
            <img src="@/assets/equity_img/icon01.png" alt=" " />
            <h3>76</h3>
            <span class="add" style="width: 64px">%+</span>
            <span class="underline">/</span>
            <span>股转节税比例</span>
          </li>
          <li>
            <img src="@/assets/equity_img/icon01.png" alt=" " />
            <h3>100</h3>
            <span class="add" style="width: 40px">+</span>
            <span class="underline">/</span>
            <span>专业服务团队</span>
          </li>
        </ul>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=gqjs "
          
          class="major-btn"
        >
          <img src="@/assets/equity_img/05bj.png" alt=" " />
        </a>
      </div>
      <div class="strength">
        <div class="strength-content">
          <div class="scroll-content overflow" id="scrollExample">
            <div id="scrollExample1" style="width: 100%">
              <ul id="scrollExample_ul" class="strength-bj">
                <li>
                  <p>
                    <span class="strength-name"
                      >186*****006
                      <img src="@/assets/equity_img/left2.png" alt=" "
                    /></span>
                    <span class="strength-time">1小时前</span>
                  </p>
                  <p>
                    网上查询到的，刚开始操作担心违规，因为我转让的股权交易额比较大，怕被查到，但是经过筹划后税少了不少，重要是合理合法，感谢!
                  </p>
                </li>
                <li>
                  <p>
                    <span class="strength-name"
                      >流苏***帆<img
                        src="@/assets/equity_img/left2.png"
                        alt=" "
                    /></span>
                    <span class="strength-time">2小时前</span>
                  </p>
                  <p>
                    经过朋友介绍来这面做的，整体给我的感觉挺专业的，通过他们的筹划帮我省了好几百
                    万的税，而且后期的服务也很顺利，很满意!
                  </p>
                </li>
                <li>
                  <p>
                    <span class="strength-name"
                      >赵明****
                      <img src="@/assets/equity_img/left2.png" alt=" "
                    /></span>
                    <span class="strength-time">5小时前</span>
                  </p>
                  <p>
                    因为公司的税务上有一些问题，咨询了很多家服务公司，在财税知识和政策上一些问题不甚了解；在网上看了99税优的情况，咨询了他们家的财税顾问，对我们公司的税务问题和行业的总体情况都做了很详细的分析，专业知识很全面，服务态度很满意!
                  </p>
                </li>
                <li>
                  <p>
                    <span class="strength-name"
                      >王立***193<img
                        src="@/assets/equity_img/left2.png"
                        alt=" "
                    /></span>
                    <span class="strength-time">一天前</span>
                  </p>
                  <p>
                    服务人员很专业，今天下午去签的合同。看到挂牌知道了原来是有政府挂牌的招商中心，感觉很靠谱。
                  </p>
                </li>
                <li>
                  <p>
                    <span class="strength-name"
                      >Yang****nice
                      <img src="@/assets/equity_img/left2.png" alt=" "
                    /></span>
                    <span class="strength-time">一天前</span>
                  </p>
                  <p>
                    作为公司财务，看到每年公司个税真的好高，巧合碰到了99企帮的专业顾问，非常热心到位，为我们提供各种符合我们公司情况的解决方案，大热天来回跑了不下5趟。完美的解决了我们的问题，棒棒哒!!!笔芯
                  </p>
                </li>
              </ul>
            </div>
            <div id="scrollExample2"></div>
          </div>
        </div>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=gqjs "
          
          class="strength-btn"
        >
          <img src="@/assets/equity_img/10bj.png" alt=" " />
        </a>
      </div>
      <div class="easy">
        <ul class="easy-bj display-flex">
          <li>
            <img src="@/assets/equity_img/ask.png" alt=" " />
            <span>在线沟通</span>
            <span>确定需求</span>
          </li>
          <li>
            <img src="@/assets/equity_img/toLeft.png" class="toLeft" alt=" " />
          </li>
          <li>
            <img src="@/assets/equity_img/yes.png" alt=" " />
            <span>提交</span>
            <span>资料</span>
          </li>
          <li>
            <img src="@/assets/equity_img/toLeft.png" class="toLeft" alt=" " />
          </li>
          <li>
            <img src="@/assets/equity_img/light.png" alt=" " />
            <span>出具</span>
            <span>节税方案</span>
          </li>
          <li>
            <img src="@/assets/equity_img/toLeft.png" class="toLeft" alt=" " />
          </li>
          <li>
            <img src="@/assets/equity_img/artical.png" alt=" " />
            <span>方案</span>
            <span>落地</span>
          </li>
          <li>
            <img src="@/assets/equity_img/toLeft.png" class="toLeft" alt=" " />
          </li>
          <li>
            <img src="@/assets/equity_img/money.png" alt=" " />
            <span>享受</span>
            <span>节税</span>
          </li>
        </ul>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=gqjs "
          
          class="easy-btn"
        >
          <img src="@/assets/equity_img/easy.png" alt=" " />
        </a>
      </div>
      <div class="question relative">
        <ul class="question-bj">
          <li>
            <p><span>Q:</span>转股要交哪些税？</p>
            <p><span>A:</span>股权转让过程中根据转让方的身份属性会缴纳...</p>
          </li>
          <li>
            <p><span>Q:</span>股转税率是多少？</p>
            <p><span>A:</span>如果转让方是个人，需缴纳20%个人所得税，如果...</p>
          </li>
          <li>
            <p><span>Q:</span>公司股东可以无偿转让公司股权吗？</p>
            <p>
              <span>A:</span>公司发第七十一条规定:有限责任公司的股东之间可以...
            </p>
          </li>
          <li>
            <p><span>Q:</span>股东转让股权，有转让额度的限制吗？</p>
            <p>
              <span>A:</span
              >一般来说，股东之间内部转让或向股东以外的人转让股权是...
            </p>
          </li>
          <li>
            <p><span>Q:</span>股权转让优惠政策？</p>
            <p>
              <span>A:</span
              >99税优全国30+自营园区，精准匹配税收洼地，节税比例高...
            </p>
          </li>
          <li>
            <p><span>Q:</span>股权转让流程？</p>
            <p>
              <span>A:</span>1、领取《公司变更登记申请表》 2、变更营业执照...
            </p>
          </li>
        </ul>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=gqjs "
          
          class="question-btn"
        >
          <img src="@/assets/equity_img/08bj.png" alt=" " />
        </a>
      </div>
    </div>
    <!-- <div id="footer">
      <img src="@/assets/equity_img/footer-bj.png" class="foot-bj" alt=" " />
    </div> -->
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  name: "Equity",
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.onload();
  },
  methods: {
    onload() {
      // roll(50);
      var speed = 80;
      var scrollExample1 = window.document.getElementById("scrollExample1");
      var scrollExample2 = window.document.getElementById("scrollExample2");
      var scrollExample = window.document.getElementById("scrollExample");

      console.log(scrollExample2);
      scrollExample2.innerHTML = scrollExample1.innerHTML;

      function Marquee() {
        if (scrollExample2.offsetHeight - scrollExample.scrollTop <= 0) {
          scrollExample.scrollTop -= scrollExample1.offsetHeight;
        } else {
          scrollExample.scrollTop++;
        }
      }
      var MyMar = setInterval(Marquee, speed);
      scrollExample.onmouseover = function () {
        clearInterval(MyMar);
      };
      scrollExample.onmouseout = function () {
        MyMar = setInterval(Marquee, speed);
      };
    },
  },
};
</script>
<style scoped>
.display-flex {
  display: flex;
}
.relative {
  position: relative;
}
.footer-t,
.footer-b {
  background: #cc272b;
}

.wrap {
  margin-top: 80px;
  background: #cc272b;
  overflow: hidden;
}

.wrap a {
  display: block;
  text-decoration: none;
}

.wrap .top {
  position: relative;
  width: 100%;
  height: 1122px;
}

.wrap .top-content {
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 9;
}

.wrap .top .top1 {
  width: 394px;
  height: 50px;
  margin: 93px 0 106px;
}

.wrap .top .top2 {
  width: 773px;
  height: 284px;
}

.top-strength img {
  width: 243px;
  height: 53px;
  margin: 47px 0 59px;
}

.top-strength img:nth-of-type(2) {
  margin: 47px 80px 59px;
}

.top-consult img {
  height: 74px;
  width: auto;
}

.top-bj {
  width: 100%;
  height: 707px;
  position: absolute;
  top: 415px;
  left: 0;
  opacity: 0.6;
}

.wrap .why {
  margin-top: -300px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.strength {
  margin-top: 97px;
  position: relative;
}

.strength .strength-bj {
  width: 1196px;
  margin: 0 auto;
  padding: 0 80px;
}

.wrap .why .why-bj {
  width: 1196px;
  height: 507px;
  background: url("../../assets/equity_img/01bj.png") center center no-repeat;
  margin: 0 auto;
  position: relative;
  padding-top: 260px;
}

.easy {
  position: relative;
}

.wrap .easy .easy-bj {
  width: 1196px;
  height: 485px;
  background: url("../../assets/equity_img/09bj.png") center center no-repeat;
  margin: 0px auto;
  position: relative;
  padding-top: 261px;
  justify-content: center;
}

.wrap .why .why-btn,
.case .case-btn,
.easy .easy-btn {
  position: absolute;
  bottom: 80px;
  left: 50%;
  margin-left: -308px;
}

.strength-btn {
  position: absolute;
  bottom: 0px;
  left: 50%;
}
.question-btn {
  position: absolute;
  bottom: 0px;
  left: 50%;
}

.strength-btn {
  margin-left: -305px;
}

.question-btn {
  margin-left: -360px;
}

.wrap .why .why-btn img,
.case .case-btn img,
.strength-btn img,
.question-btn img {
  width: 617px;
  height: 74px;
}

.why-bj ul {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 90px;
}

.why-bj ul li {
  width: 458px;
  height: 58px;
  line-height: 58px;
  text-align: left;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #515151;
  background: #ebebeb;
  margin-bottom: 40px;
}

.why-bj ul img {
  width: 30px;
  height: 30px;
  margin: 0 14px 0 44px;
}

/* .case {
  margin-top: 71px;
} */

.case .case-bj {
  width: 1196px;
  height: 686px;
  background: url("../../assets/equity_img/03bj.png") center center no-repeat;
  margin: 0 auto;
  position: relative;
  padding-top: 208px;
}

.case-top {
  width: 1038px;
  text-align: center;
  margin: 0 auto;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #515151;
  padding-bottom: 28px;
  border-bottom: 3px solid #e0e1e3;
}

.case-content {
  margin-top: 55px;
  padding: 0 70px;
  position: relative;
}

.case-left {
  width: 351px;
  height: 326px;
  background: rgba(000, 000, 000, 0.08);
  border-radius: 30px;
  text-align: center;
  padding-top: 40px;
}

.case-left h3,
.case-right h3 {
  font-size: 38px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  margin-bottom: 15px;
}

.case-left h3 {
  color: #515151;
}

.case-right h3 {
  color: #cc272b;
}

.case-left h3 img,
.case-right h3 img {
  width: 17px;
  height: 27px;
  margin-left: 15px;
}

.case-left p {
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #515151;
  line-height: 62px;
  text-align: left;
  margin-left: 50px;
}

.case .vs {
  position: absolute;
  top: 137px;
  left: 360px;
  z-index: 9;
  width: 62px;
  height: 62px;
  line-height: 59px;
  text-align: center;
  background: #fc9e07;
  border-radius: 50%;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  font-size: 26px;
}

.case-right {
  width: 710px;
  height: 326px;
  background: rgba(226, 70, 73, 0.1);
  border-radius: 30px;
  padding: 40px 0 0 94px;
}

.case-right p {
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #515151;
  line-height: 49px;
  width: 50%;
}

.case-right p span {
  color: #e24649;
}

.case-right div {
  flex-wrap: wrap;
  width: 508px;
}

.case-right .case-scale {
  background: #bf2528;
  color: #fff;
}

.case-right .case-result {
  width: 512px;
  height: 67px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #bf2528;
  line-height: 42px;
}

.major {
  width: 100%;
  height: auto;
  background: url("../../assets/equity_img/gaolou.png") center center no-repeat;
  margin: 0 auto;
  text-align: center;
  padding-top: 70px;
}

.major-top {
  margin: 0px 0 100px 0;
  width: 961px;
  height: 224px;
}

.major ul {
  width: 700px;
  margin: 0 auto;
}

.major ul li {
  display: flex;
  margin-bottom: 45px;
  color: #ffffff;
}

.major ul li img {
  width: 110px;
  height: 110px;
  margin-right: 54px;
}

.major ul li h3 {
  font-size: 94px;
}

.major ul li .add {
  text-align: center;
  width: 64px;
  height: 40px;
  border: 4px solid #ffffff;
  border-radius: 15px;
  font-size: 26px;
  line-height: 40px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  border: 2px solid #fff;
  display: block;
  margin-top: 48px;
  margin-right: 14px;
}

.major ul li .underline {
  color: #ffd88c;
  font-size: 66px;
  margin: 30px 44px 0 44px;
}

.major ul li span:last-child {
  font-size: 27px;
  margin-top: 60px;
  display: block;
}

.major .major-btn {
  margin-top: 105px;
}

.major .major-btn img {
  height: 125px;
  width: 955px;
}

.strength-bj li {
  border-bottom: 3px solid #e0e1e3;
  margin: 60px 80px 0px 80px;
  padding-bottom: 30px;
}

.strength-bj img {
  width: 16px;
  height: 26px;
  margin-left: 14px;
}

.strength-bj p {
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #515151;
  line-height: 42px;
}

.strength-bj p:first-child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.strength-bj p .strength-name {
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #cc272b;
}

.strength-bj p .strength-time {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #a2a2a2;
}

.question-btn img {
  margin-top: -30px;
  width: 721px;
}

.easy-bj {
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-top: 61px;
}

.question {
  /* margin: 60px 0; */
  z-index: 20;
  /* margin-bottom: 410px; */
}

.question-bj {
  padding-right: 82px;
  padding-top: 94px;
  margin: 0 auto;
  margin-top: 60px;
  width: 1197px;
  height: 1039px;
  background: url("../../assets/equity_img/07bj.png") center center no-repeat;
}

.question-bj li {
  margin-top: 40px;
  padding-bottom: 28px;
  margin-left: 80px;
  border-bottom: 4px solid #dddee0;
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #515151;
  line-height: 42px;
}

.question-bj li:last-child {
  border-bottom: 0;
}

.question-bj li p:first-child {
  color: #cc272b;
}

.question-bj li p span {
  color: #ffa800;
}

.easy li img {
  display: block;
  width: 106px;
  height: 106px;
  margin-bottom: 20px;
}

.easy li .toLeft {
  width: 46px;
  height: 22px;
  margin: 40px 40px 0;
}

.easy li span {
  display: block;
  font-size: 29px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #cc272b;
  line-height: 35px;
  text-align: center;
}

#footer {
  position: relative;
}

.foot-bj {
  width: 100%;
  height: 707px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 10;
}

.footer-t {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}

.strength-content {
  height: 602px;
  padding: 88px 80px 0;
  background: url("../../assets/equity_img/06bj.png") center center no-repeat;
}

.scroll-content {
  /* width: 1196px; */
  height: 476px;
  margin-top: 60px;
}
.overflow {
  overflow: hidden;
}
</style>
