<template>
  <div class="program">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <program></program>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Program from "@/views/viewpage/program.vue";
// import banner from "@/components/banner.js";

export default {
  name: "PROGRAM",
  components: { DetailNavBar, Foot, Program },
  data() {
    return {
      // qbAdvertisements: [
      //   { picture: require("@/assets/program_img/banner.png") },
      // ],
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
