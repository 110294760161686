<template>
  <div id="shuiyou">
    <div class="top">
      <img src="@/assets/99shuiyou_img/banner.png" alt="" />
    </div>
    <div class="content" style="margin-top: 30px">
      <div>
        <img src="@/assets/99shuiyou_img/sy_title1.png" alt="" />
      </div>
      <div style="margin-top: 29px">
        <img src="@/assets/99shuiyou_img/sy_title1_content.png" alt="" />
      </div>
      <div class="flex" style="margin-top: 60px">
        <div
          v-for="(item, index) in syList"
          :key="index"
          style="margin: 0 27px 27px 0"
        >
          <div>
            <img :src="item.src" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div style="margin-bottom: 96px">
        <img src="@/assets/99shuiyou_img/sy_title2.png" alt="" />
      </div>
      <div class="serverPro">
        <div style="flex: 1"></div>
        <div style="width: 1231px">
          <el-tabs v-model="serverPro" stretch>
            <el-tab-pane label="中小企业财税优化类服务" name="first">
              <div class="server_pro">
                <img
                  src="@/assets/99shuiyou_img/sy_csgood.png"
                  alt=""
                  style="margin-right: 12px"
                  @click="garden()"
                />
                <img
                  src="@/assets/99shuiyou_img/sy_gongsi.png"
                  alt=""
                  style="margin-right: 12px"
                  @click="garden()"
                />
                <img
                  src="@/assets/99shuiyou_img/sy_enter.png"
                  @click="garden()"
                  alt=""
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="高新认定类服务" name="second">
              <div class="server_pro">
                <img
                  src="@/assets/99shuiyou_img/sy_gxrd.png"
                  alt=""
                  style="margin-right: 12px"
                  @click="serverDetail()"
                />
                <div class="server_pro_content">
                  <div style="margin-right: 12px">
                    <div style="margin-right: 12px">
                      <div class="server_pro_items_content">
                        <span>
                          <img src="@/assets/99shuiyou_img/point.png" alt="" />
                        </span>
                        <span class="text">国家高新专项审计</span>
                      </div>
                    </div>
                    <div style="margin-right: 12px">
                      <div class="server_pro_items_content">
                        <span>
                          <img src="@/assets/99shuiyou_img/point.png" alt="" />
                        </span>
                        <span class="text">国家高新托管</span>
                      </div>
                    </div>
                    <div style="margin-right: 12px">
                      <div class="server_pro_items_content">
                        <span>
                          <img src="@/assets/99shuiyou_img/point.png" alt="" />
                        </span>
                        <span class="text">中关村高新申请</span>
                      </div>
                    </div>
                    <div style="margin-right: 12px">
                      <div class="server_pro_items_content">
                        <span>
                          <img src="@/assets/99shuiyou_img/point.png" alt="" />
                        </span>
                        <span class="text">基金申请</span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div style="margin-right: 12px">
                      <div class="server_pro_items_content">
                        <span>
                          <img src="@/assets/99shuiyou_img/point.png" alt="" />
                        </span>
                        <span class="text">其他高新类代理服务</span>
                      </div>
                    </div>
                    <div style="margin-right: 12px">
                      <div class="server_pro_items_content">
                        <span>
                          <img src="@/assets/99shuiyou_img/point.png" alt="" />
                        </span>
                        <span class="text">高新稽查应对代理</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="财税外包服务" name="thired">
              <div class="flex">
                <!-- <div style="flex: 1"></div> -->
                <div
                  class="server_pro"
                  style="
                    display: flex;
                    flex: 8;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div v-for="(item, index) in csout_serverList" :key="index">
                    <div
                      class="server_pro_items_content"
                      style="margin-right: 12px"
                    >
                      <span>
                        <img src="@/assets/99shuiyou_img/point.png" alt="" />
                      </span>
                      <span class="text">{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div style="flex: 1"></div> -->
              </div>
            </el-tab-pane>
            <el-tab-pane label="财税顾问服务" name="fourth">
              <div class="flex">
                <!-- <div style="flex: 1"></div> -->
                <div
                  style="
                    display: flex;
                    flex: 8;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-top: 40px;
                  "
                >
                  <div v-for="(item, index) in csgw_serverList" :key="index">
                    <div
                      class="server_pro_items_content"
                      style="margin-right: 12px"
                    >
                      <span>
                        <img
                          src="@/assets/99shuiyou_img/point.png"
                          style="margin-left: 60px"
                          alt=""
                        />
                      </span>
                      <span class="text">{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div style="flex: 1"></div> -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
    <div class="content">
      <div>
        <img src="@/assets/99shuiyou_img/sy_title3.png" alt="" />
      </div>
      <div class="flex">
        <div style="flex: 1"></div>
        <div class="flex" style="margin-top: 60px; width: 1231px">
          <div style="margin: 0 133px 80px 0">
            <div>
              <img src="@/assets/99shuiyou_img/server1.png" alt="" />
            </div>
          </div>
          <div style="margin: 0 133px 80px 0">
            <div>
              <img src="@/assets/99shuiyou_img/server2.png" alt="" />
            </div>
          </div>
          <div>
            <div style="margin: 0 0 80px 0">
              <img src="@/assets/99shuiyou_img/server3.png" alt="" />
            </div>
          </div>
          <div style="margin: 0 133px 80px 0">
            <div>
              <img src="@/assets/99shuiyou_img/server6.png" alt="" />
            </div>
          </div>
          <div style="margin: 0 133px 80px 0">
            <div>
              <img src="@/assets/99shuiyou_img/server5.png" alt="" />
            </div>
          </div>
          <div>
            <div style="margin: 0 0 80px 0">
              <img src="@/assets/99shuiyou_img/server4.png" alt="" />
            </div>
          </div>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
    <div id="zhezhao" @click="guan" style="">
      <div class="tanchaung">
        <div class="cha" @click="guan">x</div>
        <div>
          <img
            src="@/assets/99caishui_img/qrcode_99qibang.png"
            alt=""
            style="width: 400px"
            @click="buguan"
          />
        </div>
      </div>
    </div>
    <div class="content">
      <div style="margin-bottom: 60px">
        <img src="@/assets/99shuiyou_img/sy_title4.png" alt="" />
      </div>
      <div style="padding-bottom: 183px" class="success">
        <div style="width: 1201px">
          <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
            <el-tab-pane label="互联网科技" name="first">
              <div class="success_content">
                <div
                  v-for="(item, index) in ITList"
                  :key="index"
                  class="success_img"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="现代服务" name="second">
              <div class="success_content">
                <div
                  v-for="(item, index) in ITList2"
                  :key="index"
                  class="success_img"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="商贸+新零售" name="third">
              <div class="success_content">
                <div
                  v-for="(item, index) in ITList3"
                  :key="index"
                  class="success_img"
                >
                  <img :src="item.src" alt="" />
                </div></div
            ></el-tab-pane>
            <el-tab-pane label="教育+餐饮+广告" name="fourth">
              <div class="success_content">
                <div
                  v-for="(item, index) in ITList4"
                  :key="index"
                  class="success_img"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="制造+医疗+运输" name="fiver">
              <div class="success_content">
                <div
                  v-for="(item, index) in ITList5"
                  :key="index"
                  class="success_img"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Shuiyou",
  data() {
    return {
      serverPro: "first", // 服务项目
      activeName: "first", // 成功案例
      syList: [
        { src: require("@/assets/99shuiyou_img/sy_teacher.png") },
        { src: require("@/assets/99shuiyou_img/park_pp.png") },
        { src: require("@/assets/99shuiyou_img/sy_fxbk.png") },
        { src: require("@/assets/99shuiyou_img/sy_jbzx.png") },
      ],
      ITList: [
        { src: require("@/assets/99shuiyou_img/example/internet/logo1.jpg") },
        { src: require("@/assets/99shuiyou_img/example/internet/logo2.jpg") },
        { src: require("@/assets/99shuiyou_img/example/internet/logo3.jpg") },
        { src: require("@/assets/99shuiyou_img/example/internet/logo4.jpg") },
        { src: require("@/assets/99shuiyou_img/example/internet/logo5.jpg") },
        { src: require("@/assets/99shuiyou_img/example/internet/logo6.jpg") },
        // { src: require("@/assets/99shuiyou_img/example/internet/logo7.jpg") },
        { src: require("@/assets/99shuiyou_img/example/internet/logo8.jpg") },
        { src: require("@/assets/99shuiyou_img/example/internet/logo9.jpg") },
      ],
      ITList2: [
        // { src: require("@/assets/99shuiyou_img/example/service/logo1.jpg") },
        { src: require("@/assets/99shuiyou_img/example/service/logo2.jpg") },
        { src: require("@/assets/99shuiyou_img/example/service/logo3.jpg") },
        { src: require("@/assets/99shuiyou_img/example/service/logo4.jpg") },
        // { src: require("@/assets/99shuiyou_img/example/service/logo5.jpg") },
        { src: require("@/assets/99shuiyou_img/example/service/logo6.jpg") },
        { src: require("@/assets/99shuiyou_img/example/service/logo7.jpg") },
        { src: require("@/assets/99shuiyou_img/example/service/logo9.jpg") },
      ],
      ITList3: [
        { src: require("@/assets/99shuiyou_img/example/business/logo1.jpg") },
        { src: require("@/assets/99shuiyou_img/example/business/logo2.jpg") },
        { src: require("@/assets/99shuiyou_img/example/business/logo3.jpg") },
        { src: require("@/assets/99shuiyou_img/example/business/logo4.jpg") },
        { src: require("@/assets/99shuiyou_img/example/business/logo5.jpg") },
        { src: require("@/assets/99shuiyou_img/example/business/logo6.jpg") },
        { src: require("@/assets/99shuiyou_img/example/business/logo7.jpg") },
        { src: require("@/assets/99shuiyou_img/example/business/logo8.jpg") },
      ],
      ITList4: [
        { src: require("@/assets/99shuiyou_img/example/ad/logo1.jpg") },
        { src: require("@/assets/99shuiyou_img/example/ad/logo2.jpg") },
        { src: require("@/assets/99shuiyou_img/example/ad/logo4.jpg") },
        { src: require("@/assets/99shuiyou_img/example/ad/logo5.jpg") },
        { src: require("@/assets/99shuiyou_img/example/ad/logo7.jpg") },
      ],
      ITList5: [
        { src: require("@/assets/99shuiyou_img/example/transport/logo1.jpg") },
        { src: require("@/assets/99shuiyou_img/example/transport/logo2.jpg") },
        { src: require("@/assets/99shuiyou_img/example/transport/logo3.jpg") },
        // { src: require("@/assets/99shuiyou_img/example/transport/logo4.jpg") },
        { src: require("@/assets/99shuiyou_img/example/transport/logo5.jpg") },
        { src: require("@/assets/99shuiyou_img/example/transport/logo6.jpg") },
        { src: require("@/assets/99shuiyou_img/example/transport/logo7.jpg") },
      ],
      csout_serverList: [
        // 财税外包服务
        {
          name: "代理记账服务",
        },
        {
          name: "高新记账服务",
        },
        {
          name: "财务岗位外包服务",
        },
        {
          name: "财务梳理服务",
        },
        {
          name: "归集研发费用服务账服务",
        },
        {
          name: "研发费用科目设计服务",
        },
      ],
      csgw_serverList: [
        // 财税顾问服务
        {
          name: "常年财务顾问服务",
        },
        {
          name: "企业财务管理服务",
        },
        {
          name: "企业并购重组服务",
        },
        {
          name: "财务内部审计服务",
        },
        {
          name: "制度搭建服务",
        },
        {
          name: "财务内训服务",
        },
        {
          name: "投资融财务指导",
        },
        {
          name: "新三板/IPO咨询",
        },
      ],
    };
  },
  created() {},
  methods: {
    // 点击tab页
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 点击跳转高新认定服务
    serverDetail() {
      location.href = "/serviceDetail?id=71";
      // this.$router.push({ path: "/serviceDetail", query: { id: "71" } });
    },
    // 点击跳转企园宝
    kai() {
      console.log(1111);
      document.getElementById("zhezhao").style.display = "block";
    },
    guan() {
      console.log(2222);
      document.getElementById("zhezhao").style.display = "none";
    },
    buguan(event) {
      event.stopPropagation();
    },
    garden() {
      console.log(1111);
      // document.getElementById("zhezhao").style.display = "block";
      // location.href = "/garden?id=e2e0a033805643eba5d2e193e9ed33eb"; // 测试环境企园宝
      // location.href = "/garden?id=50cc14ea52664621a9aa08e60c915050"; // 线上环境企园宝
      // this.$router.push({
      //   path: "/garden",
      //   query: { id: "e2e0a033805643eba5d2e193e9ed33eb" },
      // });
      // location.href = "/garden?id=e2e0a033805643eba5d2e193e9ed33eb"; // 测试环境企园宝
      location.href = "/garden?id=50cc14ea52664621a9aa08e60c915050"; // 线上环境企园宝
      // this.$router.push({
      //   path: "/garden",
      //   query: { id: "e2e0a033805643eba5d2e193e9ed33eb" },
      // });
    },
  },
};
</script>
<style>
#shuiyou .top {
  margin-top: 60px;
}
#shuiyou .top img {
  width: 100%;
  height: auto;
}
#zhezhao {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.tanchaung {
  width: 417px;
  margin: 0 auto;
  margin-top: 140px;
}
.cha {
  width: 18px;
  text-align: center;
  background: white;
  border-radius: 50%;
  float: right;
  cursor: pointer;
}
.content {
  text-align: center;
  margin-top: 30px;
}
.serverPro {
  display: flex;
  justify-content: center;
  align-items: center;
}
.server_pro_content {
  display: flex;
}
.serverPro .el-tabs__item.is-active {
  color: #fff !important;
  background-color: #3d93f9 !important;
}
.serverPro .el-tabs__active-bar {
  display: none !important;
}
.serverPro .el-tabs__item {
  height: 46px !important;
  line-height: 46px !important;
  font-size: 17px !important;
  color: #5b5e66 !important;
}
.el-tabs__item:hover {
  color: #3d93f9 !important;
  cursor: pointer;
}
/* .serverPro .el-tabs__item:hover {
  color: #3d93f9 !important ;
} */
.server_pro {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.server_pro img {
  cursor: pointer;
  margin-left: 60px;
}
.success {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success .el-tabs__item {
  padding-right: 20px !important;
  height: 72px !important;
  line-height: 72px !important;
  font-size: 20px !important;
  color: #5b5e66 !important;
}
.success .el-tabs__item.is-active {
  color: #3d93f9 !important;
}
.success_content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.success_img {
  margin: 0 4px 14px 0;
  background-color: #fff;
}
::v-deep .el-tabs_nav-scroll {
  width: 50% !important;
  margin: 0 auto !important;
}
.success .el-tabs__active-bar {
  display: none !important;
}
.success .el-tabs__item:hover {
  color: #3d93f9 !important ;
}
.server_pro_items_content {
  width: 392px;
  height: 80px;
  line-height: 80px;
  text-align: left;
  background-color: #fff;
  margin-bottom: 12px;
}
.text {
  color: #5b5e66;
  font-size: 20px;
  margin-left: 20px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>
