<template>
  <div class="aSing">
    <detail-nav-bar></detail-nav-bar>
    <asingle></asingle>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Foot from "@/components/foot/foot.vue";
import Asingle from "@/views/viewpage/aSing.vue";
// import banner from "@/components/banner.js";

export default {
  name: "ASINGLE",
  components: { DetailNavBar, Foot, Asingle },
  data() {
    return {
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
