<template>
  <div id="planning">
    <div class="wrap">
      <el-carousel
        trigger="click"
        height="410px"
        width="100%"
        :autoplay="autoplay"
      >
        <el-carousel-item>
          <h3 class="small">
            <a
               target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti&r=&rf1=&rf2=&cid=99909869ba3f4d3885caa6f20343578b&sid=f2bc530897214f9c96c340ad393856ac"
            >
              <img
                src="@/assets/Planning_img/banner1.png"
                alt=""
                style="width: 100%; height: auto"
              />
            </a>
          </h3>
        </el-carousel-item>
        <el-carousel-item>
          <h3 class="small">
            <div class="banner2">
              <div class="left">
                <div class="text_">
                  <b>企业所得税全免</b>
                  <p>
                    综合税率低至
                    <span>0.25%</span>
                  </p>
                </div>
                <img src="@/assets/Planning_img/yellowRight.png" alt="" />
              </div>
              <div class="right">
                <button class="title">抢占入驻名额</button>
                <div>
                  <p>
                    <img src="@/assets/Planning_img/allIndu.png" alt="" />
                    <span>行业 :</span>
                    <input
                      type="text"
                      id="allIndu"
                      placeholder="请输入行业"
                      v-model="name"
                    />
                  </p>
                  <p>
                    <img src="@/assets/Planning_img/allPhone.png" alt="" />
                    <span>电话 :</span>
                    <input
                      type="text"
                      id="allPhone"
                      placeholder="请输入电话"
                      v-model="phone"
                    />
                  </p>
                </div>
                <button class="submit" @click="submitMsg()">立即提交</button>
              </div>
            </div>
          </h3>
        </el-carousel-item>
      </el-carousel>
      <div class="planContent">
        <div class="title" style="margin-top: 30px">
          <a>
            <img src="@/assets/Planning_img/scheme.png" alt="" />
            <p>为什么要做税收筹划?</p>
          </a>
        </div>
        <div class="why">
          <img src="@/assets/Planning_img/why1.png" alt="" />
          <img src="@/assets/Planning_img/why2.png" alt="" />
          <img src="@/assets/Planning_img/why3.png" alt="" />
          <img
            src="@/assets/Planning_img/why4.png"
            alt=""
            style="margin-right: 0"
          />
        </div>
        <a
          href="http://www.99qibang.com/service.html"
          target="_blank"
          class="button more"
          >查看更多税收筹划类型</a
        >
        <div class="title" style="margin-top: 60px">
          <a>
            <img src="@/assets/Planning_img/light.png" alt="" />
            <div>
              <p>税收筹划的原则</p>
              <p class="explain">利用国家税收优惠政策，实现合理合法节税</p>
            </div>
          </a>
        </div>
        <div class="principles">
          <img
            src="@/assets/Planning_img/pl3.jpg"
            alt=""
            style="margin-right: 28px"
          />
          <img src="@/assets/Planning_img/principles2.png" alt="" />
        </div>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
          
          class="button free"
        >
          <p style="margin-top: 24px">免费咨询 您是否可以申请</p>
          <p>400-099-3699</p>
        </a>
        <div class="title" style="margin-top: 60px">
          <a>
            <img src="@/assets/Planning_img/industry.png" alt="" />
            <p>税收筹划适用行业</p>
          </a>
        </div>
        <ul class="industry wow animated">
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry1.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                  
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry2.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <!-- <span>0000</span> -->
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                  
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry3.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <!-- <span>0000</span> -->
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                  
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry4.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <!-- <span>0000</span> -->
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                  
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry5.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <!-- <span>0000</span> -->
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                  
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry6.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <!-- <span>0000</span> -->
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                 
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry7.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <!-- <span>0000</span> -->
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                  
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
          <li class="scheme-content wow animated">
            <div
              class="scheme-img-small-box scheme-img-box mrb20 pointer shadow overflow inline-block"
            >
              <img
                src="@/assets/Planning_img/industry8.png"
                alt=""
                class="scheme-img-small scheme-img"
              />
              <!-- <span>0000</span> -->
              <div class="scheme-item-modal">
                <a
                   target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
                  
                  class="scheme-item-btn button"
                  >咨询专属节税方案</a
                >
              </div>
            </div>
          </li>
        </ul>
        <a
          href="http://www.99qibang.com/scheme.html"
          target="_blank"
          class="button look"
          >查看更多适用行业</a
        >
        <div class="title" style="margin-top: 60px">
          <a>
            <img src="@/assets/Planning_img/flow.png" alt="" />
            <p>99企帮一站式服务流程</p>
          </a>
        </div>
        <div class="flow">
          <div>
            <img
              src="@/assets/Planning_img/phone.png"
              alt=""
              style="width: 76px"
            />
            <p>
              <span>电话或者</span>
              <span>在线咨询</span>
            </p>
          </div>
          <img src="@/assets/Planning_img/right.png" alt="" />
          <div>
            <img
              src="@/assets/Planning_img/people.png"
              alt=""
              style="width: 74px"
            />
            <p>
              <span>了解企业&</span>
              <span>个人基本信息</span>
            </p>
          </div>
          <img src="@/assets/Planning_img/right.png" alt="" />
          <div>
            <img
              src="@/assets/Planning_img/precept.png"
              alt=""
              style="width: 63px"
            />
            <p>
              <span>定制税收</span>
              <span>筹划方案</span>
            </p>
          </div>
          <img src="@/assets/Planning_img/right.png" alt="" />
          <div>
            <img
              src="@/assets/Planning_img/execute.png"
              alt=""
              style="width: 72px"
            />
            <p>
              <span>税收筹划方案</span>
              <span>落地执行</span>
            </p>
          </div>
          <img src="@/assets/Planning_img/right.png" alt="" />
          <div>
            <img
              src="@/assets/Planning_img/server.png"
              alt=""
              style="width: 74px"
            />
            <p>
              <span>享1对1售</span>
              <span>后服务</span>
            </p>
          </div>
        </div>
        <div class="title" style="margin-top: 67px">
          <a>
            <img src="@/assets/Planning_img/choose.png" alt="" />
            <p>为什么选择我们</p>
          </a>
        </div>
        <div class="choose">
          <div>
            <img
              src="@/assets/Planning_img/choose1.png"
              alt=""
              style="margin-right: 50px; margin-bottom: 50px"
            />
            <img
              src="@/assets/Planning_img/choose2.png"
              alt=""
              style="margin-right: 50px; margin-bottom: 50px"
            />
            <img
              src="@/assets/Planning_img/choose3.png"
              alt=""
              style="margin-bottom: 50px"
            />
          </div>
          <div>
            <img
              src="@/assets/Planning_img/choose4.png"
              alt=""
              style="margin-right: 50px"
            />
            <img src="@/assets/Planning_img/choose5.png" alt="" />
          </div>
        </div>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=xinzhuanti"
          
          class="button server"
          >服务办理热线: 400-099-3699</a
        >
        <div class="title" style="margin-top: 67px">
          <a>
            <img src="@/assets/Planning_img/planning.png" alt="" />
            <p>税收筹划成功案例</p>
          </a>
        </div>
        <div class="success_">
          <div>
            <img src="@/assets/Planning_img/mt.png" alt="" />
            <img src="@/assets/Planning_img/szsm.png" alt="" />
            <img src="@/assets/Planning_img/wanj.png" alt="" />
            <img src="@/assets/Planning_img/gjys.png" alt="" />
          </div>
          <div>
            <img src="@/assets/Planning_img/blf.png" alt="" />
            <img src="@/assets/Planning_img/wsg.png" alt="" />
            <img src="@/assets/Planning_img/yhzn.png" alt="" />
            <img src="@/assets/Planning_img/yfzs.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/request/api";
import $ from "jquery";
export default {
  name: "Planning",
  data() {
    return {
      autoplay: false,
      name: "",
      phone: "",
    };
  },
  created() {},
  mounted() {
    this.animate();
  },
  methods: {
    // 鼠标悬浮税筹行业
    animate() {
      var hostdiv = $(".scheme-content .scheme-img-box");
      hostdiv.each(function () {
        $(this).hover(
          function () {
            $(this).find(".scheme-item-modal").animate(
              {
                top: "0px",
              },
              200
            );
            // $(this).find(".scheme-item-modal").css("display", "block");
          },
          function () {
            var hosth1 = $(this).height();
            hosth1 = hosth1 + "px";
            $(this).find(".scheme-item-modal").show();
            if (!$(this).find(".scheme-item-modal").is(":animated")) {
              $(this).find(".scheme-item-modal").animate(
                {
                  top: hosth1,
                },
                200
              );
              // $(this).find(".scheme-item-modal").css("display", "none");
            } else {
              $(this).find(".scheme-item-modal").stop();
              $(this).find(".scheme-item-modal").animate(
                {
                  top: hosth1,
                },
                200
              );
              // $(this).find(".scheme-item-modal").css("display", "none");
            }
          }
        );
      });
    },
    // 立即提交事件
    submitMsg() {
      if (!this.name) {
        return this.$message.error("请填写您的行业");
      }
      if (!this.phone) {
        return this.$message.error("请填写您的手机号");
      }
      if (!/^1[3|4|5|6|7|8][0-9]\d{8}$/.test(this.phone)) {
        return this.$message.error("手机号格式不符");
      }
      var formDatas = new FormData();
      formDatas.append("industry", this.name);
      formDatas.append("tel", this.phone);
      formDatas.append("guestType", 1);
      formDatas.append("position", "落地页获客");
      postData("/qbguest/qbGuest/saveGuest", formDatas).then((resultData) => {
        if (resultData && resultData.data === "success") {
          this.$message({
            message: resultData.msg,
            type: "success",
          });
          this.name = "";
          this.phone = "";
        }
      });
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.banner2 {
  position: relative;
  background-image: url("../../assets/Planning_img/banner2.png");
  width: 100%;
  height: 460px;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner2 .left {
  position: absolute;
  top: 120px;
  left: 200px;
  display: flex;
  align-items: center;
}
.banner2 .right {
  position: absolute;
  top: 120px;
  right: 200px;
  text-align: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}
.banner2 .left b {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 44px;
  margin-top: 25px;
}

.banner2 .left p {
  margin-top: 52px;
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 44px;
}
.banner2 .left p span {
  color: #ff7023;
}
.banner2 .left img {
  margin-left: 100px;
}
.banner2 .right .title,
.banner2 .right .submit {
  width: 270px;
  height: 40px;
  margin-bottom: 20px;
  background: rgba(0, 144, 255, 1);
  border-radius: 6px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  border: 0;
}
.banner2 .right .submit {
  cursor: pointer;
}
.banner2 .right div p {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.banner2 .right div p img {
  margin-right: 5px;
}
.banner2 .right input {
  width: 170px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  border: 0;
  font-weight: normal;
  outline: none;
  font-size: 16px;
  text-indent: 10px;
  margin-left: 20px;
}
a:hover {
  color: rgba(51, 51, 51, 1);
}

.wrap {
  margin-top: 80px;
}

.wrap a {
  text-decoration: none;
}

.swiper-content {
  width: 1200px;
  margin: 0 auto;
  /* display: flex;
    justify-content: space-between; */
  margin-top: 107px;
}

.swiper-content .left {
  display: flex;
  align-items: center;
  float: left;
}

.swiper-content .left b {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 44px;
  margin-top: 25px;
}

.swiper-content .left .text span {
  color: #ff7023;
}

.swiper-content .left p {
  margin-top: 52px;
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 44px;
}

.swiper-content .left img {
  width: 107px;
  height: auto;
  margin-left: 100px;
}

.swiper-content .right {
  float: right;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.swiper-content .right input {
  width: 170px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  border: 0;
  font-weight: normal;
  font-size: 16px;
  text-indent: 10px;
}

.swiper-content .right .title,
.swiper-content .right .submit {
  background: rgba(0, 144, 255, 1);
  border-radius: 6px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  border: 0;
}

.swiper-content .right .title {
  width: 270px;
  height: 40px;
  margin-bottom: 20px;
}

.swiper-content .right .submit {
  width: 200px;
  height: 40px;
}

.swiper-content .right div p {
  margin-bottom: 20px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  text-indent: 10px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  text-indent: 10px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  text-indent: 10px;
}

.planContent {
  width: 1200px;
  margin: 0 auto;
}

.planContent .title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
}

.planContent .title a {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.planContent .title img {
  width: 80px;
  height: 80px;
  margin-right: 30px;
}

.why {
  margin-top: 4px;
}

.why img {
  width: 276px;
  height: auto;
  margin-right: 10px;
  margin-bottom: 29px;
}
.wow {
  margin-bottom: 30px;
}

.planContent .button {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: 0;
  background: linear-gradient(
    270deg,
    rgba(51, 169, 255, 1),
    rgba(88, 185, 255, 1)
  );
  border: 2px solid rgba(0, 0, 0, 1);
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.more {
  width: 534px;
  height: 80px;
  line-height: 80px;
}

.title .explain {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-top: 10px;
}

.principles {
  display: flex;
  margin: 20px 0 30px;
}

.principles img {
  display: flex;
  width: 586px;
  height: auto;
}

.free {
  width: 536px;
  height: 120px;
}

.result {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
}

.result .coverage43 {
  width: 162px;
  height: auto;
  margin: 0 8px;
}

.result img {
  display: flex;
  width: 520px;
  height: auto;
}

.industry li {
  margin-top: 30px;
}

.industry .scheme-item-modal {
  display: flex;
  align-items: center;
  background: rgba(51, 51, 51, 0.3);
  border-radius: 10px;
  top: 276px;
}

.industry .button {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 43px;
  border: 0;
  width: 200px;
  height: 43px;
  background: rgba(85, 183, 255, 1);
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.industry .scheme-item-btn {
  margin-top: 117px;
}

.industry .scheme-img-small-box,
.industry .scheme-img-small {
  width: 276px;
  height: 276px;
}

.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.look {
  width: 536px;
  height: 80px;
  line-height: 80px;
}

.flow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
}

.flow img {
  width: 80px;
  height: 20px;
}

.flow div {
  width: 150px;
  height: 150px;
  background: rgba(85, 184, 255, 1);
  border-radius: 8px;
  text-align: center;
}

.flow div p {
  margin-top: 9px;
}

.flow div span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  display: block;
}

.flow div > img {
  margin-top: 9px;
  height: auto;
}

.server {
  width: 536px;
  height: auto;
  line-height: 80px;
}

.choose {
  margin-bottom: 30px;
}

.choose img {
  width: 368px;
  height: 386px;
}

.choose div {
  display: flex;
  margin-top: 30px;
  flex-wrap: nowrap;
  justify-content: center;
}

.success_ {
  margin-top: 22px;
  margin-bottom: 25px;
}

.success_ div {
  display: flex;
  /* flex-wrap: wrap; */
}

.success_ img {
  width: 306px;
  height: auto;
}
.scheme-content .scheme-img-box:hover .scheme-item-modal {
  display: inline-block;
}
.scheme-img-small-box {
  border-radius: 4px;
  position: relative;
}
.scheme-item-modal {
  text-align: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 19px;
  position: absolute;
  cursor: pointer;
  left: 0;
  /* top: 0px; */
}

.overflow {
  overflow: hidden;
}
</style>
