<template>
  <div id="contactus">
    <div class="contactus_content">
      <div class="fir">
        <div style="display: flex; background: white; width: 30%">
          <!-- <img src="@/assets/aboutus_img/contactus/img1.png" alt="" /> -->
          <div style="display: flex; align-items: center">
            <img
              src="@/assets/aboutus_img/contactus/add.png"
              alt=""
              style="width: 50px; height: 50px; margin-left: 30px"
            />
          </div>
          <div style="margin-left: 23px">
            <div class="seo">公司地址</div>
            <div class="thr">北京市朝阳区光华路7号汉威大厦东塔10层1017室</div>
          </div>
        </div>

        <div style="display: flex; background: white; width: 30%">
          <!-- <img src="@/assets/aboutus_img/contactus/img1.png" alt="" /> -->
          <div style="display: flex; align-items: center">
            <img
              src="@/assets/aboutus_img/contactus/dianhua3.png"
              alt=""
              style="width: 50px; height: 50px; margin-left: 30px"
            />
          </div>
          <div style="margin-left: 23px">
            <div class="seo">服务热线</div>
            <div class="thr">400-099-3699</div>
          </div>
        </div>

        <div style="display: flex; background: white; width: 30%">
          <!-- <img src="@/assets/aboutus_img/contactus/img1.png" alt="" /> -->
          <div style="display: flex; align-items: center">
            <img
              src="@/assets/aboutus_img/contactus/coyoujian.png"
              alt=""
              style="width: 50px; height: 50px; margin-left: 30px"
            />
          </div>
          <div style="margin-left: 23px">
            <div class="seo">邮箱</div>
            <div class="thr">99qbfw@99qibang.com</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 117px">
        <div class="map_adress">
          <div style="flex: 1"></div>
          <div class="map">
            <!-- <baidu-map class="baiduMap" id="allmap"></baidu-map> -->
            <div class="baiduMap" id="allmap"></div>
            <div style="margin-top: 30px">
              <div class="adress_title">路线1</div>
              <div class="adress">
                地铁10号线内环到金台夕照地铁站后，A西北口出，步行748米，到达汉威大厦东塔10层1017室北京优企帮帮科技有限公司
              </div>
            </div>
            <div style="margin-top: 60px">
              <div class="adress_title">路线2</div>
              <div class="adress">
                行驶到金桐东路，右转到景华南街行驶244米，左转进入内部道路，直行到达汉威大厦东塔10层1017室北京优企帮帮科技有限公司
              </div>
            </div>
          </div>
          <div style="flex: 1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://api.map.baidu.com/api?v=2.0&ak=YvuaGQU1cXDyHZN27YHCMiwjvGA9Yuhn&s=1"></script>
<script>
// import { map } from "@/components/map.js";
// import BaiduMap from "vue-baidu-map";

// import BMap from "BMap";
export default {
  name: "Contactus",
  // components: { BaiduMap },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.map();
    // this.$nextTick(function () {
    //   map().then((BMap) => {
    //     console.log(BMap);

    //   });
    // });
  },
  methods: {
    map() {
      // 业务代码
      var map = new BMap.Map("allmap");

      var centerPoint = new BMap.Point(116.092462, 40.072268);
      var markerPoint = new BMap.Point(116.461308, 39.919688);
      map.centerAndZoom(centerPoint, 12);
      map.enableScrollWheelZoom();
      map.enableInertialDragging();
      map.enableContinuousZoom();
      // 添加标注点
      var marker = new BMap.Marker(markerPoint); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中

      // 左上角缩放控件和比例尺
      var bottom_left_control = new BMap.ScaleControl({
        anchor: BMAP_ANCHOR_BOTTOM_LEFT,
      }); // 左下角，添加比例尺
      var top_left_navigation = new BMap.NavigationControl(); //左上角，添加默认缩放平移控件
      map.addControl(bottom_left_control);
      map.addControl(top_left_navigation);

      // 右下角添加缩略图
      var overView = new BMap.OverviewMapControl();
      var overViewOpen = new BMap.OverviewMapControl({
        isOpen: true,
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
      });
      map.addControl(overView); // 添加默认缩略地图控件
      map.addControl(overViewOpen); // 右下角，打开
      // map().then((BMap) => {

      // });
    },
  },
};
</script>
<style scoped>
.contactus_content {
  text-align: center;
  margin: 140px 0 188px 0;
}
.fir {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 230px;
}
.seo {
  text-align: left;
  font-size: 21px;
  margin-top: 73px;
  font-weight: 800;
}
.thr {
  width: 231px;
  text-align: left;
  margin-top: 23px;
  line-height: 28px;
  color: gray;
  font-size: 17px;
}
.baiduMap {
  width: 1100px;
  height: 503px;
}
.map_adress {
  display: flex;
}
.map {
  background-color: #fff;
  padding: 50px;
}
.adress_title {
  text-align: left;
  font-size: 18px;
  padding-left: 10px;
  color: #333;
  height: 18px;
  line-height: 18px;
  margin-bottom: 30px;
}
.adress {
  text-align: left;
  font-size: 14px;
  color: #5b5e66;
  padding-left: 10px;
  line-height: 24px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
