<template>
  <div id="ChanneJoin">
    <div class="channel_content">
      <div>
        <img src="@/assets/ChannelJoin_img/title1.png" alt="" />
      </div>
      <div style="margin-top: 28px">
        <img src="@/assets/ChannelJoin_img/title1_text.png" alt="" />
      </div>
      <div class="flex">
        <div style="flex: 1"></div>
        <div class="flex" style="flex: 8; flex-wrap: nowrap">
          <div v-for="(item, index) in oneList" :key="index">
            <div style="margin: 0px 20px 20px 0">
              <img :src="item.src" alt="" />
            </div>
          </div>
        </div>

        <div style="flex: 1; margin-left: -20px"></div>
      </div>
    </div>
    <div class="channel_content">
      <div>
        <img src="@/assets/ChannelJoin_img/title2.png" alt="" />
      </div>
      <div class="flex">
        <div style="flex: 1"></div>
        <div class="flex" style="flex: 8; flex-wrap: nowrap">
          <div v-for="(item, index) in twoList" :key="index">
            <div style="margin: 0px 152px 20px 0">
              <img :src="item.src" alt="" />
            </div>
          </div>
        </div>

        <div style="flex: 1; margin-left: -152px"></div>
      </div>
    </div>
    <div class="channel_content">
      <div>
        <img src="@/assets/ChannelJoin_img/title3.png" alt="" />
      </div>
      <div style="margin-top: 60px">
        <img src="@/assets/ChannelJoin_img/title3_img.png" alt="" />
      </div>
      <div class="flex" style="margin-top: 200px">
        <div style="flex: 3" class="title3_img2">
          <!-- <img
            src="@/assets/ChannelJoin_img/title3_img2.png"
            style="width: 100%; height: 650px"
            alt=""
          /> -->
        </div>
        <div
          class="title3_img4_5"
          :style="{
            backgroundImage: 'url(' + title5_bgImg.src + ')',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        >
          <div>
            <img src="@/assets/ChannelJoin_img/title3_img4.png" alt="" />
          </div>
          <div>
            <img
              src="@/assets/ChannelJoin_img/title3_img5.png"
              style="margin-top: 189px"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="channel_content">
      <div>
        <img src="@/assets/ChannelJoin_img/title4.png" alt="" />
      </div>
      <div style="margin-top: 28px">
        <img src="@/assets/ChannelJoin_img/title4_text.png" alt="" />
      </div>
      <div class="flex">
        <div style="flex: 1"></div>
        <div class="flex" style="flex: 8; flex-wrap: nowrap">
          <div style="margin: 0px 272px 20px 0">
            <img src="@/assets/ChannelJoin_img/title4_img1.png" alt="" />
          </div>
          <div style="margin: 0px 272px 20px 0">
            <img src="@/assets/ChannelJoin_img/title4_img2.png" alt="" />
          </div>
          <div style="margin: 0px 0px 20px 0">
            <img src="@/assets/ChannelJoin_img/title4_img3.png" alt="" />
          </div>
          <!-- <div v-for="(item, index) in threeList" :key="index">
            <div style="margin: 0px 272px 20px 0">
              <img :src="item.src" alt="" />
            </div>
          </div> -->
        </div>

        <div style="flex: 1"></div>
      </div>
    </div>
    <div class="channel_content" style="margin-top: 120px">
      <div style="margin-top: 28px">
        <img src="@/assets/ChannelJoin_img/title5_text.png" alt="" />
      </div>
      <div class="flex">
        <div style="flex: 1"></div>
        <div class="flex" style="flex: 8; flex-wrap: nowrap">
          <div v-for="(item, index) in fiveList" :key="index">
            <div style="margin: 0px 27px 20px 0">
              <img :src="item.src" alt="" />
            </div>
          </div>
        </div>

        <div style="flex: 1; margin-left: -27px"></div>
      </div>
    </div>
    <div
      class="channel_content flex title6"
      :style="{
        backgroundImage: 'url(' + title6_bgImg.src + ')',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
    >
      <div style="flex: 2"></div>
      <div style="text-align: left; flex: 8">
        <div class="join_item">
          <span class="join_text join_text_right">
            <span class="join_left_text">&nbsp;&nbsp; 姓名：</span>
            <span>
              <input
                type="text"
                placeholder="请输入您的姓名"
                id="username"
                autocomplete="off"
                v-model="params.name"
              />
            </span>
            <span class="icon">*</span>
          </span>
          <span class="join_text" style="margin-left: 64px">
            <span>手机号：</span>
            <span>
              <input
                type="text"
                placeholder="请输入您的有效联系方式"
                id="tel"
                autocomplete="off"
                v-model="params.tel"
              />
            </span>
            <span class="icon">*</span>
          </span>
        </div>
        <div class="join_item">
          <span class="join_text join_text_right">
            <span class="join_left_text">&nbsp;&nbsp; 地址：</span>
            <span>
              <input
                type="text"
                placeholder="请输入您的地址(精确到区)"
                id="address "
                autocomplete="off"
                v-model="params.address"
              />
            </span>
            <span class="icon">*</span>
          </span>
          <span class="join_text" style="margin-left: 45px">
            <span>公司名称：</span>
            <span>
              <input
                type="text"
                placeholder="请输入您的公司名称"
                id="company"
                autocomplete="off"
                v-model="params.company"
              />
            </span>
          </span>
        </div>
        <div class="join_item">
          <span class="join_text join_text_right">
            <span class="join_left_text">&nbsp;&nbsp; 职位：</span>
            <span>
              <input
                type="text"
                placeholder="请输入您的职位"
                id="job"
                autocomplete="off"
                v-model="params.job"
              />
            </span>
          </span>
          <span class="join_text" style="margin-left: 97px">
            <span>邮箱：</span>
            <span>
              <input
                type="text"
                placeholder="请输入您的邮箱地址"
                id="email"
                autocomplete="off"
                v-model="params.email"
              />
            </span>
          </span>
        </div>
        <div class="join_item">
          <span class="join_text join_text_right">
            <span class="join_left_text">微信号：</span>
            <span>
              <input
                type="text"
                placeholder="请输入您的微信号"
                id="wechat"
                autocomplete="off"
                v-model="params.wechat"
              />
            </span>
          </span>
        </div>
        <div class="summit">
          <div class="btn" @click="summits()" :class="{ btns: summit == true }">
            确认提交
          </div>
        </div>
      </div>
      <!-- <div style="flex: 1"></div> -->
    </div>
  </div>
</template>
<script>
import { postData } from "@/request/api";
export default {
  name: "ChanneJoin",
  props: {
    title5_bgImg: {
      type: Object,
      required: true,
    },
    title6_bgImg: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      summit: false,
      params: {
        name: "",
        tel: "",
        address: "",
        company: "",
        job: "",
        email: "",
        wechat: "",
      }, //提交数据
      oneList: [
        {
          src: require("@/assets/ChannelJoin_img/title1_img1.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title1_img2.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title1_img3.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title_img4.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title1_img4.png"),
        },
      ],
      twoList: [
        {
          src: require("@/assets/ChannelJoin_img/title2_img1.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title2_img2.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title2_img3.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title2_img4.png"),
        },
      ],
      threeList: [
        {
          src: require("@/assets/ChannelJoin_img/title4_img1.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title4_img2.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title4_img3.png"),
        },
      ],
      fiveList: [
        {
          src: require("@/assets/ChannelJoin_img/title5_img1.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title5_img2.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title5_img3.png"),
        },
        {
          src: require("@/assets/ChannelJoin_img/title5_img4.png"),
        },
      ],
    };
  },
  created() {
    console.log(this.title5_bgImg);
  },
  methods: {
    // 确认提交
    summits() {
      if (!this.params.name) {
        this.$alert("请输入您的姓名", "提示", {
          confirmButtonText: "确定",
        });
        return;
        // return this.$message.error("请输入您的姓名");
      }
      if (!this.params.tel) {
        this.$alert("请输入您的手机号", "提示", {
          confirmButtonText: "确定",
        });
        return;
        // return this.$message.error("请输入您的手机号");
      }
      if (!/^1[3|4|5|6|7|8][0-9]\d{8}$/.test(this.params.tel)) {
        this.$alert("手机号格式不符", "提示", {
          confirmButtonText: "确定",
        });
        return;
        // return this.$message.error("手机号格式不符");
      }
      if (!this.params.address) {
        this.$alert("请输入您的地址", "提示", {
          confirmButtonText: "确定",
        });
        return;
        // return this.$message.error("请输入您的地址");
      }
      var formDatas = new FormData();
      formDatas.append("name", this.params.name);
      formDatas.append("tel", this.params.tel);
      formDatas.append("address", this.params.address);
      formDatas.append("corporate", this.params.company);
      formDatas.append("job", this.params.job);
      formDatas.append("email", this.params.email);
      formDatas.append("wxNo", this.params.wechat);
      formDatas.append("guestType", "2");
      postData("/qbguest/qbGuest/saveChannel", formDatas).then((res) => {
        console.log(res);
        if (res.data == "success") {
          if (res.remind) {
            this.$alert(res.remind, "提示", {
              confirmButtonText: "确定",
            });
            // this.$message({
            //   message: res.remind,
            //   type: "warning",
            // });
          } else {
            this.$alert("恭喜您提交成功", "提示", {
              confirmButtonText: "确定",
            });
            // this.$message({
            //   message: "恭喜您提交成功",
            //   type: "success",
            // });
          }
        } else {
          this.$alert("提交失败，请稍后重试", "提示", {
            confirmButtonText: "确定",
          });
          // this.$message.error("提交失败，请稍后重试");
        }
      });
      this.summit = true;
    },
  },
};
</script>
<style scoped>
#ChanneJoin {
  text-align: center;
}
.channel_content {
  margin-top: 30px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
.title3_img4_5 {
  flex: 7;
  text-align: left;
  padding: 86px 0px 83px 80px;
}
.title6 {
  margin-top: 175px;
  padding-top: 142px;
  padding-bottom: 120px;
}
.join_item {
  margin-bottom: 30px;
}
.join_text {
  font-size: 18px;
  color: #5b5e66;
}
.join_text input {
  margin-left: 10px;
  width: 263px;
  height: 48px;
  line-height: 48px;
  padding-left: 26px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 24px;
  outline: none;
  font-size: 14px;
  color: #333333;
}

.icon {
  color: red;
}

.summit {
  margin-top: 13px;
  display: flex;
  align-content: center;
  /* justify-content: center; */
}
.btn {
  text-align: center;
  width: 230px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  background-color: #5b5e66;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20%;
}
.btn:hover {
  background-color: #3d93f9;
}
.btns {
  background-color: #3d93f9;
}
.title3_img2 {
  background: url("../../assets/ChannelJoin_img/title3_img2.png");
  width: 100%;
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
