<template>
  <div class="newsdatail">
    <detail-nav-bar></detail-nav-bar>
    <banner :qbAdvertisements="qbAdvertisements"></banner>
    <div id="ServerDetail">
      <!--产品介绍-->
      <div class="product-introduction body-section">
        <!--面包屑导航-->
        <div class="container">
          <div class="guide">
            <a href="/">首页</a>&nbsp;>&nbsp;<a href="/view/taxation/case"
              >财税资讯-案例实务</a
            >&nbsp;>&nbsp;<a href="javascript:void(0)">案例实务详情</a>
          </div>
        </div>
        <div class="content_box">
          <div style="flex: 1"></div>
          <div>
            <img src="@/assets/news_img/title2.png" alt="" />
            <div class="content_content">
              <div class="data_title">{{ news.title }}</div>
              <div class="data_flex">
                <div class="data_tagNames">
                  {{ news.tagNames }}
                </div>
                <div class="data_tagNames">
                  {{ news.browse ? news.browse : 0 }}阅读
                </div>
              </div>
              <div class="data_flex">
                <div class="data_source">发布人: {{ news.source }}</div>
                <div class="data_source">发布时间: {{ news.createDate }}</div>
              </div>
              <div class="data_content" v-html="news.content"></div>
              <!-- <div>
                <img src="@/assets/news_img/aboutUs.png" alt="" />
              </div> -->
              <div class="data_pagList">
                <div v-for="(item, index) in pagList" :key="index">
                  <div
                    style="margin-bottom: 20px"
                    class="pagitem"
                    @click="pushDetail(item.id)"
                  >
                    {{ item.paging }}：
                    <span class="text_page">{{ item.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="flex: 1"></div>
        </div>
      </div>
    </div>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
// import Casedatail from "@/views/viewpage/casedetail.vue";
// import banner from "@/components/banner.js";
import { postData } from "@/request/api";
// import $ from "jquery";
export default {
  // async asyncData({ route, store }) {
  //   console.log(route.query.id);
  //   // var formdatas = new FormData();
  //   const params = {
  //     id: route.query.id,
  //     positionType: "1",
  //     original: "4",
  //     pcStatus: "2",
  //   };
  //   // formdatas.set("id", route.query.id);
  //   // formdatas.set("positionType", "1");
  //   // formdatas.set("original", "4");
  //   // formdatas.set("pcStatus", "2");
  //   const newsList = await postData("/qbnews/qbNews/newDetail", params);
  //   // console.log(newsList);
  //   // const newsList = {};
  //   console.log(newsList);
  //   store.commit("newsList", newsList);
  // },
  name: "CASEDETAIL",
  components: {
    DetailNavBar,
    Banner,
    Foot,
  },
  data() {
    return {
      qbAdvertisements: [
        { picture: require("@/assets/taxation_img/caishui111.png") },
      ],
      footbg: {},
      news: {},
      pagList: [],
    };
  },
  created() {
    this.getDeatil();
  },
  mounted() {},
  methods: {
    async getDeatil() {
      var FormData = require("form-data");
      var formdatas = new FormData();
      formdatas.append("id", this.$route.query.id);
      formdatas.append("positionType", "1");
      formdatas.append("original", "4");
      formdatas.append("pcStatus", "2");
      const newsList = await postData("/qbnews/qbNews/newDetail", formdatas);
      this.$store.commit("newsList", newsList);
      this.load();
    },
    load() {
      this.news = this.$store.state.newsList.news;
      this.pagList = this.$store.state.newsList.pagList;
      // $(".data_content").html(this.news.content);
      // this.pagList.forEach((item) => {
      //   item.src = "/casedetail?id=" + item.id;
      // });
      console.log(this.pagList);
    },
    pushDetail(id) {
      location.href = "/casedetail?id=" + id;
      // this.$router.push({
      //   path: "/casedetail",
      //   query: { id: id },
      // });
    },
  },
};
</script>
<style>
.aboutUs .el-tabs__item.is-active {
  color: #3d93f9 !important;
}
.aboutUs .el-tabs__active-bar {
  display: none !important;
}
.aboutUs .el-tabs__item {
  height: 60px !important;
  line-height: 60px !important;
  font-size: 17px !important;
  color: #5b5e66 !important;
}
.aboutUs .el-tabs__item:hover {
  color: #3d93f9 !important;
  cursor: pointer;
}
.aboutUs .el-tabs__nav-scroll {
  padding: 0 30% !important;
}
.body-section {
  margin-top: 80px;
}

.product-introduction {
  width: 100%;
  margin-bottom: 20px;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.guide {
  line-height: 56px;
  height: 56px;
  color: #4a4a4a;
  background-color: #fcfcfc;
}
.guide a {
  color: #4a4a4a;
}
.content_box {
  /* width: 1201px; */
  margin-top: 67px;
  text-align: center;
  display: flex;
}
.content_content {
  width: 1201px;
  /* padding: 0 360px; */
  margin-top: 41px;
  text-align: left;
  overflow: hidden;
}
.data_title {
  color: #5b5e66;
  font-size: 36px;
}
.data_tagNames {
  margin-top: 30px;
  color: #5b5e66;
  font-size: 16px;
}
.data_flex {
  color: #999999;
  font-size: 14px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data_pagList {
  margin-top: 30px;
  font-size: 14px;
  color: #5b5e66;
}
.data_content {
  margin-top: 53px;
}
.data_pagList .text_page:hover {
  color: #3d93f9;
}
.pagitem {
  cursor: pointer;
}
</style>
