<template>
  <div id="taxPlann">
    <div class="wrap">
      <div class="top">
          <img src="@/assets/bookkeeping/bookkeepingbanner.png" alt="" />
      </div>
      <div class="puzz-content">
        <span class="word4">为什么选择代理记账服务？</span>
      <div class="outer3 flex-row justify-between">
        <span class="info4">1、</span>
        <span class="word5">避免出现漏报、误报、迟报问题</span>
      </div>
      <div class="outer4 flex-row justify-between">
        <span class="word6">2、</span>
        <span class="info5">节省财务人员工资支出</span>
      </div>
      <div class="outer5 flex-row justify-between">
        <span class="info6">3、</span>
        <span class="infoBox1">
            <!-- 同行业经验多，熟知税收优惠政策方寸天地不含糊，精打细算功夫用我们的专业，助您走向成功 -->
            同行业经验多，熟知税收优惠政策方寸天地不含糊，精打<br />细算显功夫用我们的专业，助您走向成功 
            </span
        >
      </div>
      </div>
      <div class="question">
        <div class="outer6 flex-col">
        <div class="main4 flex-col justify-between">
          <span class="info7">我们的服务内容</span>
          <div class="outer7 flex-row">
            <div class="mod1 flex-col">
              <span class="info8">代理记账服务</span>
            </div>
            <div class="mod2 flex-col">
              <span class="word7">财务梳理服务</span>
            </div>
            <div class="mod3 flex-col">
              <span class="info9">财务岗位外包</span>
            </div>
            <div class="mod4 flex-col">
              <span class="txt4">企业财务管理服务</span>
            </div>
            <div class="mod5 flex-col">
              <span class="txt5">高端财税咨询服务</span>
            </div>
            <div class="mod6 flex-col">
              <span class="info10">财税优化服务</span>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="difference" style="position:relative;">
         <span class="info11">代理记账服务流程</span>
      <div class="outer8 flex-row justify-between">
        <div class="block1 flex-col justify-between">
          <div class="box1 flex-col">
            <div class="bd1 flex-row">
              <div class="TextGroup1 flex-col">
                <div class="outer9 flex-col justify-between">
                  <span class="info12">月初第一个工作日：</span>
                  <span class="infoBox2"
                    >通知客户：操作税控、<br />邮寄发票、核对工资</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="box2 flex-col">
            <div class="wrap1 flex-row">
              <div class="TextGroup2 flex-col">
                <div class="layer1 flex-col justify-between">
                  <span class="word8">每月20日前：</span>
                  <span class="txt6">社保及公积金情况检查完毕</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block2 flex-col justify-between">
          <div class="section2 flex-col"></div>
          <div class="section3 flex-col"></div>
        </div>
        <div class="block3 flex-col justify-between">
          <div class="outer10 flex-col">
            <div class="outer11 flex-row">
              <div class="TextGroup3 flex-col">
                <div class="main5 flex-col justify-between">
                  <span class="infoBox3"
                    >月度申报完成后，服务群<br />内同步消息：</span
                  >
                  <span class="paragraph1"
                    >税控清卡反写情况申报完成<br />情况一般纳税人进行税额留<br />抵情况截止上月盈利情况或<br />特殊账务沟通</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="outer12 flex-col"></div>
        </div>
      </div>
      <div class="outer13 flex-row">
        <div class="outer14 flex-col"></div>
        <div class="outer15 flex-col"></div>
        <div class="outer16 flex-col">
          <div class="mod7 flex-row">
            <div class="TextGroup4 flex-col">
              <div class="section4 flex-col justify-between">
                <span class="info13">每月25日：</span>
                <span class="infoBox4"
                  >提醒一般纳税人客户准备进<br />项发票<br /><br />特别注意：<br />临时突发性事项：<br />完成后服务群反馈完成消息</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
         <div class="main8 flex-col">
      <div class="layer7 flex-row">
        <div class="TextGroup5 flex-col">
          <div class="wrap6 flex-col justify-between">
            <span class="txt9">税期倒数第三个工作日：</span>
            <span class="paragraph8">小组互查，检查客户税控清<br />卡情况</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main9 flex-col">
      <div class="section7 flex-row">
        <div class="TextGroup6 flex-col">
          <div class="main10 flex-col justify-between">
            <span class="txt10">每月21日开始：</span>
            <span class="infoBox6">税务审核，确认无误装订<br />凭证</span>
          </div>
        </div>
      </div>
    </div>
      </div>
      <div class="light">
        <div class="outer17 flex-col">
        <div class="group1 flex-col">
          <span class="info14">我们服务优势</span>
          <div class="mod8 flex-row justify-between" style="display:flex;justify-content: space-between;">
            <span class="info15">其他代账</span>
            <span class="txt7">99企帮代理记账</span>
          </div>
          <div class="mod9 flex-row justify-between" style="display:flex;justify-content: space-between;">
            <div class="wrap2 flex-col">
              <span class="word9">实习会计。不专业</span>
            </div>
            <div class="wrap3 flex-col">
              <span class="paragraph2">服务会计要求不低<br />于从业3年</span>
            </div>
          </div>
          <div class="mod10 flex-row justify-between" style="display:flex;justify-content: space-between;">
            <div class="layer2 flex-col justify-between">
              <div class="block4 flex-col">
                <span class="infoBox5">见票入账，不考虑<br />合理性</span>
              </div>
              <div class="block5 flex-col">
                <span class="paragraph3">售后保障低，税<br />务问题多</span>
              </div>
            </div>
            <div class="layer3 flex-col">
              <div class="box3 flex-row">
                <div class="section5 flex-col">
                  <div class="section6 flex-col">
                   
                  </div>
                </div>
              
              </div>
            </div>
            <div class="layer4 flex-col justify-between">
              <div class="wrap4 flex-col">
                <span class="paragraph4">账目清楚：银行<br />账，每月核对</span>
              </div>
              <div class="wrap5 flex-col">
                <span class="paragraph5"
                  >每月按照节点提<br />供对应服务，<br />保障财务安全</span
                >
              </div>
            </div>
          </div>
          <div class="mod11 flex-row justify-between" style="display:flex;justify-content: space-between;">
            <div class="layer5 flex-col">
              <span class="paragraph6"
                >收费不透明，<br />低价进入，<br />后期涨价</span
              >
            </div>
            <div class="layer6 flex-col">
              <span class="paragraph7">收费透明，无隐<br />形消费</span>
            </div>
          </div>
      </div>
      </div>
    </div>
   
  </div>
  </div>
</template>
<script>
export default {
  name: "TaxPlann",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wrap {
  margin-top: 60px;
  background: #F3F7FA;
}

.wrap a {
  text-decoration: none;
}

.wrap .puzz-content,
.question-content,
.difference,
.light-content,
.faster {
  width: 1168px;
  margin: 0 auto;
  padding-bottom: 120px;
}

.wrap .top img {
  width: 100%;
  height: auto;
}
.word4 {
  width: 520px;
  height: 58px;
  overflow-wrap: break-word;
  color: rgba(13, 115, 235, 1);
  font-size: 42px;
  letter-spacing: 1.75px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 58px;
  display: block;
  margin: 77px auto;
}

.outer3 {
  width: 550px;
  height: 67px;
  margin-left: 10%;
  display: flex;
}

.info4 {
  width: 83px;
  height: 67px;
  overflow-wrap: break-word;
  color: rgba(13, 115, 235, 1);
  font-size: 49px;
  letter-spacing: 2.0416667461395264px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 67px;
  display: block;
}

.word5 {
  width: 467px;
  height: 42px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei;
  text-align: left;
  white-space: nowrap;
  line-height: 42px;
  margin-top: 11px;
  display: block;
}

.outer4 {
  width: 417px;
  height: 67px;
  margin-left: 10%;
  margin-top: 28px;
  display: flex;
}

.word6 {
  width: 83px;
  height: 67px;
  overflow-wrap: break-word;
  color: rgba(13, 115, 235, 1);
  font-size: 49px;
  letter-spacing: 2.0416667461395264px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 67px;
  display: block;
}

.info5 {
  width: 334px;
  height: 42px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei;
  text-align: left;
  white-space: nowrap;
  line-height: 42px;
  margin-top: 11px;
  display: block;
}

.outer5 {
  width: 917px;
  height: 84px;
  margin-left: 10%;
  margin-top: 28px;
  display: flex;
}

.info6 {
  width: 83px;
  height: 67px;
  overflow-wrap: break-word;
  color: rgba(13, 115, 235, 1);
  font-size: 49px;
  letter-spacing: 2.0416667461395264px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 67px;
  margin-top: 11px;
  display: block;
}

.infoBox1 {
  width: 834px;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei;
  text-align: left;
  line-height: 42px;
  white-space: nowrap;
  display: block;
}


.outer6 {
  background-color: rgba(255, 255, 255, 1);
  height: 682px;
  /* margin-top: 100px; */
  /* width: 1920px; */
}

.main4 {
  width: 917px;
  height: 511px;
  margin: 23px auto;
}

.info7 {
  width: 304px;
  height: 58px;
  overflow-wrap: break-word;
  color: rgba(13, 115, 235, 1);
  font-size: 42px;
  letter-spacing: 1.75px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 58px;
  margin-left: 306px;
  display: block;
  padding-top: 56px;
}

.outer7 {
  width: 917px;
  height: 388px;
  margin-top: 65px;
  flex-wrap: wrap;
  display: flex;
}

.mod1 {
  background-color: rgba(98, 167, 247, 1);
  border-radius: 6px;
  height: 104px;
  width: 420px;
  margin: 0 77px 38px 0;
}

.info8 {
  width: 225px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  letter-spacing: 1.5px;
  font-family: PingFang-SC-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
  display: block;
  margin: 27px 0 0 98px;
}

.mod2 {
  background-color: rgba(98, 167, 247, 1);
  border-radius: 6px;
  height: 104px;
  margin-bottom: 38px;
  width: 420px;
}

.word7 {
  width: 225px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  letter-spacing: 1.5px;
  font-family: PingFang-SC-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
  display: block;
  margin: 27px 0 0 98px;
}

.mod3 {
  background-color: rgba(98, 167, 247, 1);
  border-radius: 6px;
  height: 104px;
  width: 420px;
  margin: 0 77px 38px 0;
}

.info9 {
  width: 225px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  letter-spacing: 1.5px;
  font-family: PingFang-SC-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
  display: block;
  margin: 27px 0 0 98px;
}

.mod4 {
  background-color: rgba(98, 167, 247, 1);
  border-radius: 6px;
  height: 104px;
  margin-bottom: 38px;
  width: 420px;
}

.txt4 {
  width: 300px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  letter-spacing: 1.5px;
  font-family: PingFang-SC-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
  display: block;
  margin: 27px 0 0 60px;
}

.mod5 {
  background-color: rgba(98, 167, 247, 1);
  border-radius: 6px;
  height: 104px;
  width: 420px;
  margin: 0 77px 38px 0;
}

.txt5 {
  width: 300px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  letter-spacing: 1.5px;
  font-family: PingFang-SC-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
  display: block;
  margin: 27px 0 0 60px;
}

.mod6 {
  background-color: rgba(98, 167, 247, 1);
  border-radius: 6px;
  height: 104px;
  margin-bottom: 38px;
  width: 420px;
}

.info10 {
  width: 225px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  letter-spacing: 1.5px;
  font-family: PingFang-SC-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
  display: block;
  margin: 27px 0 0 98px;
}
.info11 {
  width: 347px;
  height: 58px;
  overflow-wrap: break-word;
  color: rgba(13, 115, 235, 1);
  font-size: 42px;
  letter-spacing: 1.75px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 58px;
  display: block;
  margin: 94px 0 0 430px;
}

.outer8 {
  width: 920px;
  height: 753px;
  /* margin: 94px 0 0 500px; */
  display: flex;
  margin-left: 146px;
}

.block1 {
  width: 404px;
  height: 753px;
}

.box1 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  height: 340px;
  width: 404px;
}

.bd1 {
  width: 263px;
  height: 122px;
  margin: 107px 0 0 71px;
}

.TextGroup1 {
  height: 122px;
  width: 263px;
}

.outer9 {
  width: 263px;
  height: 122px;
  padding-top: 100px;
}

.info12 {
  width: 263px;
  height: 37px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  letter-spacing: 1.1666666269302368px;
  font-family: MicrosoftYaHei-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  display: block;
}

.infoBox2 {
  width: 250px;
  height: 66px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  letter-spacing: 1px;
  text-align: left;
  line-height: 33px;
  white-space: nowrap;
  margin-top: 19px;
  display: block;
}

.box2 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  height: 340px;
  margin-top: -44px;
  width: 404px;
}

.wrap1 {
  width: 300px;
  height: 87px;
  margin: 119px 0 0 55px;
}

.TextGroup2 {
  height: 87px;
  width: 300px;
}

.layer1 {
  width: 300px;
  height: 87px;
  padding-top: 100px;
}

.word8 {
  width: 183px;
  height: 37px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  letter-spacing: 1.1666666269302368px;
  font-family: MicrosoftYaHei-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  display: block;
}

.txt6 {
  width: 300px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  letter-spacing: 1px;
  font-family: MicrosoftYaHei;
  text-align: left;
  white-space: nowrap;
  line-height: 31px;
  margin-top: 19px;
  display: block;
}

.block2 {
  width: 104px;
  height: 417px;
  margin-top: 271px;
}

.section2 {
  width: 103px;
  height: 4px;
  background: url('../../assets/bookkeeping/book5.png')
    100% no-repeat;
}

.section3 {
  width: 103px;
  height: 4px;
  background: url('../../assets/bookkeeping/book5.png')
    100% no-repeat;
  margin: 409px 0 0 1px;
}

.block3 {
  width: 404px;
  height: 436px;
  margin-top: 62px;
}

.outer10 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  height: 340px;
  width: 404px;
}

.outer11 {
  width: 321px;
  height: 220px;
  margin: 48px 0 0 54px;
}

.TextGroup3 {
  height: 220px;
  width: 321px;
}

.main5 {
  width: 321px;
  height: 220px;
  padding-top: 75px;
}

.infoBox3 {
  width: 321px;
  height: 74px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  letter-spacing: 1.1666666269302368px;
  font-family: MicrosoftYaHei-Bold;
  text-align: left;
  line-height: 37px;
  white-space: nowrap;
  display: block;
}

.paragraph1 {
  width: 300px;
  height: 124px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  letter-spacing: 1px;
  font-family: MicrosoftYaHei;
  text-align: left;
  line-height: 31px;
  white-space: nowrap;
  margin-top: 22px;
  display: block;
}

.outer12 {
  width: 4px;
  height: 103px;
  background: url('../../assets/bookkeeping/book4.png') -1px
    0px no-repeat;
  margin: -7px 0 0 200px;
}

.outer13 {
  width: 720px;
  height: 418px;
  display: flex;
  margin: 105px 0 0 338px;
}

.outer14 {
  width: 4px;
  height: 103px;
  background: url('../../assets/bookkeeping/book4.png')
    0px -1px no-repeat;
}

.outer15 {
  width: 103px;
  height: 4px;
  background: url('../../assets/bookkeeping/book5.png')
    100% no-repeat;
  margin: 284px 0 0 205px;
}

.outer16 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  height: 340px;
  width: 404px;
  margin: 78px 0 0 4px;
}

.mod7 {
  width: 300px;
  height: 242px;
  margin: 43px 0 0 54px;
}

.TextGroup4 {
  height: 242px;
  width: 300px;
}

.section4 {
  width: 300px;
  height: 242px;
}

.info13 {
  width: 154px;
  height: 37px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  letter-spacing: 1.1666666269302368px;
  font-family: MicrosoftYaHei-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  display: block;
}

.infoBox4 {
  width: 300px;
  height: 186px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  letter-spacing: 1px;
  font-family: MicrosoftYaHei;
  text-align: left;
  line-height: 31px;
  white-space: nowrap;
  margin-top: 19px;
  display: block;
}
.main8 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  
  height: 340px;
  width: 404px;
  position: absolute;
  left: 56%;
  top: 40%;
}

.layer7 {
  width: 321px;
  height: 118px;
  margin: 109px 0 0 54px;
}

.TextGroup5 {
  height: 118px;
  width: 321px;
}

.wrap6 {
  width: 321px;
  height: 118px;
}

.txt9 {
  width: 321px;
  height: 37px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  letter-spacing: 1.1666666269302368px;
  font-family: MicrosoftYaHei-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  display: block;
}

.paragraph8 {
  width: 300px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  letter-spacing: 1px;
  font-family: MicrosoftYaHei;
  text-align: left;
  line-height: 31px;
  white-space: nowrap;
  margin-top: 19px;
  display: block;
}

.main9 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  
  height: 340px;
  width: 404px;
  position: absolute;
  left: 13%;
  top: 68.5%;
}

.section7 {
  width: 275px;
  height: 122px;
  margin: 109px 0 0 55px;
}

.TextGroup6 {
  height: 122px;
  width: 275px;
}

.main10 {
  width: 275px;
  height: 122px;
}

.txt10 {
  width: 211px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  letter-spacing: 1.1666666269302368px;
  font-family: PingFang-SC-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
  display: block;
}

.infoBox6 {
  width: 275px;
  height: 66px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  letter-spacing: 1px;
  font-family: PingFang-SC-Regular;
  text-align: left;
  line-height: 33px;
  white-space: nowrap;
  margin-top: 16px;
  display: block;
}


.outer17 {
  background-color: rgba(255, 255, 255, 1);
  /* height: 2552px; */
  margin-top: 91px;
  /* width: 1920px; */
}

.group1 {
  /* width: 1920px; */
  height: 2009px;
  margin-top: 83px;
}

.info14 {
  width: 260px;
  height: 58px;
  overflow-wrap: break-word;
  color: rgba(13, 115, 235, 1);
  font-size: 42px;
  letter-spacing: 1.75px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 58px;
  margin:0 auto;
  display: block;
  padding-top: 50px;
}

.mod8 {
  width: 821px;
  height: 52px;
  margin: 41px auto;
}

.info15 {
  width: 157px;
  height: 52px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 38px;
  letter-spacing: 1.5833333730697632px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 52px;
  display: block;
}

.txt7 {
  width: 285px;
  height: 52px;
  overflow-wrap: break-word;
  color: rgba(38, 70, 247, 1);
  font-size: 38px;
  letter-spacing: 1.5833333730697632px;
  font-family: Alibaba-PuHuiTi-H;
  text-align: left;
  white-space: nowrap;
  line-height: 52px;
  display: block;
}

.mod9 {
  width: 920px;
  height: 320px;
  margin: 58px auto;
}

.wrap2 {
  height: 320px;
  background: url('../../assets/bookkeeping/book1.png')
    100% no-repeat;
  width: 320px;
}

.word9 {
  width: 267px;
  height: 42px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 42px;
  display: block;
  margin: 138px 0 0 27px;
}

.wrap3 {
  height: 320px;
  background: url('../../assets/bookkeeping/book2.png')
    0px 0px no-repeat;
  width: 320px;
}

.paragraph2 {
  width: 267px;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 42px;
  white-space: nowrap;
  display: block;
  margin: 115px 0 0 26px;
}

.mod10 {
  width: 920px;
  height: 668px;
  margin: 28px auto;
}

.layer2 {
  width: 320px;
  height: 668px;
}

.block4 {
  height: 320px;
  background: url('../../assets/bookkeeping/book1.png')
    100% no-repeat;
  width: 320px;
}

.infoBox5 {
  width: 267px;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 42px;
  white-space: nowrap;
  display: block;
  margin: 115px 0 0 27px;
  padding-top: 100px
}

.block5 {
  height: 320px;
  background: url('../../assets/bookkeeping/book1.png')
    100% no-repeat;
  margin-top: 28px;
  width: 320px;
}

.paragraph3 {
  width: 234px;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 42px;
  white-space: nowrap;
  display: block;
  margin: 115px 0 0 43px;
  padding-top: 100px
}

.layer3 {
  height: 198px;
  
  margin-top: 393px;
  width: 198px;
}

.box3 {
  width: 171px;
  height: 196px;
}

.section5 {
  /* background-color: rgba(163, 163, 163, 1); */
  border-radius: 50%;
  /* z-index: 109; */
  position: relative;
  width: 192px;
  height: 196px;
}

.section6 {
  /* z-index: 110; */
  position: absolute;
  
  width: 192px;
  height: 192px;
  background: url('../../assets/bookkeeping/book3.png')
    0px 0px no-repeat;
  background-size: cover;
}

.icon1 {
  z-index: 112;
  position: absolute;
  left: 57px;
  top: 174px;
  width: 18px;
  height: 20px;
}

.info16 {
  width: 144px;
  height: 101px;
  -webkit-text-stroke: 5px rgba(108, 77, 255, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 82px;
  letter-spacing: 3.4166667461395264px;
  font-family: FZZDHJW--GB1-0;
  text-align: right;
  white-space: nowrap;
  line-height: 101px;
  display: block;
  margin: 56px 0 0 -95px;
}

.layer4 {
  width: 320px;
  height: 668px;
}

.wrap4 {
  height: 320px;
  background: url('../../assets/bookkeeping/book2.png')
    0px 0px no-repeat;
  width: 320px;
}

.paragraph4 {
  width: 234px;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 42px;
  white-space: nowrap;
  display: block;
  margin: 129px 0 0 44px;
  padding-top: 100px
}

.wrap5 {
  height: 320px;
  background: url('../../assets/bookkeeping/book2.png')
    0px 0px no-repeat;
  margin-top: 28px;
  width: 320px;
}

.paragraph5 {
  width: 234px;
  height: 126px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 42px;
  white-space: nowrap;
  display: block;
  margin: 105px 0 0 44px;
  padding-top: 100px;
}


.mod11 {
  width: 920px;
  height: 320px;
  margin: 292px auto;
}

.layer5 {
  height: 320px;
  background: url('../../assets/bookkeeping/book1.png')
    100% no-repeat;
  width: 320px;
}

.paragraph6 {
  width: 200px;
  height: 126px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: left;
  line-height: 42px;
  white-space: nowrap;
  display: block;
  margin: 93px 0 0 82px;
}

.layer6 {
  height: 320px;
  background: url('../../assets/bookkeeping/book2.png')
    0px 0px no-repeat;
  width: 320px;
}

.paragraph7 {
  width: 234px;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  letter-spacing: 1.3333333730697632px;
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  line-height: 42px;
  white-space: nowrap;
  display: block;
  margin: 115px 0 0 44px;
}
</style>
