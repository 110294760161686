<template>
  <div>
    <detail-nav-bar></detail-nav-bar>
    <!-- banner -->
    <div class="top">
      <img src="@/assets/programme/bg.png" alt="" />
      <div class="topPosition">
        <h1>为您定制合理的税收筹划方案</h1>
        <div class="topBox">
          <p>提升经营利润</p>
          <p>降低财税风险</p>
          <p>保障财富传承</p>
        </div>
        <a
          target="_blank"
          href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuic"
          ><div class="topBtn">现在咨询免费定制</div></a
        >
      </div>
    </div>
    <div class="Box">
      <!-- title1 -->
      <div class="model">
        <div class="m_top">
          <h3>目前企业普遍存在的财税难题</h3>
          <p>Title 1</p>
          <img src="@/assets/programme/tit_icon.png" alt="" />
        </div>
        <ul class="model_ul">
          <li>公司两套账，偷税漏 税，税务风险极高。</li>
          <li>老板不敢从公司分红 ，分红税较高，账面 净利润余额较高。</li>
          <li>虚开增值税发票：票 流、货流、现金流不</li>
          <li>增值税、企业所得税 高，企业面临较大生</li>
          <li>买卖发票，伪造单据 ，虚增成本，做低利</li>
          <li>存货账实不符，未开 发票、资金入个人卡。</li>
          <li>社保入税，企业经营 成本持续增长。</li>
          <li>上游采购无法开发票 ，抵扣链条缺失。</li>
        </ul>
      </div>
      <!-- title2 -->
      <div class="model2">
        <div class="m_top">
          <h3>如何合理合法节税</h3>
          <p>Title 2</p>
          <img src="@/assets/programme/tit_icon.png" alt="" />
        </div>
        <div class="videoBox">
          <video
            class="video"
            controls
            src="https://qb1.99qibang.com/1-1.mp4"
          ></video>
          <div class="vRight">
            <h3>如何合理合法节税</h3>
            <p>
              国家税收政策，层层递进,老板与企业信息越来越透明；<br />
              金税四期，大数据算税,老板需要提升财税管控；<br />
              学会规避风险、做好防范，才能更长久发展。
            </p>
            <a
              target="_blank"
              href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuic"
            >
              <div>立即领取方案</div>
            </a>
          </div>
        </div>
      </div>
      <!-- title3 -->
      <div class="model2">
        <div class="m_top">
          <h3>节税不能只靠会计</h3>
          <p>Title 3</p>
          <img src="@/assets/programme/tit_icon.png" alt="" />
        </div>
        <div class="videoBox">
          <video
            class="video"
            controls
            src="https://qb1.99qibang.com/2-1.mp4"
          ></video>
          <div class="vRight">
            <h3>节税不能只靠会计</h3>
            <p>
              金税四期别大意，这些问题很危险： <br />规模小流水千万？<br />
              公私频繁互转？ <br />资金溯源不明？ <br />转入转出异常？<br />
              闲户大量交易？ <br />频繁开销户？
            </p>
            <a
              target="_blank"
              href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuic"
            >
              <div>立即领取方案</div>
            </a>
          </div>
        </div>
      </div>
      <!-- title4 -->
      <div class="model4">
        <div class="m_top">
          <h3>我们为您提供这些服务</h3>
          <p>Title 4</p>
          <img src="@/assets/programme/tit_icon.png" alt="" />
        </div>
        <div class="m4Tit">该交的税一分不少交,不该交的税一分不多交</div>
        <ul class="m4Box">
          <li>
            <div class="m4_txt">税务筹划咨询服务</div>
            <div class="m4_Box2">
              <p>
                返税政策咨询<br />
                税收方案定制<br />疑难杂症解答
              </p>
              <a
                target="_blank"
                href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuic"
              >
                <div>免费咨询</div>
              </a>
            </div>
          </li>
          <li>
            <div class="m4_txt">财税咨询服务</div>
            <div class="m4_Box2">
              <p>
                财务梳理 财税顾问<br />财务内控体系搭建<br />日常财税咨询服务<br />
                股权架构及组织结构优化
              </p>
              <a
                target="_blank"
                href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuic"
              >
                <div>免费咨询</div>
              </a>
            </div>
          </li>
          <li>
            <div class="m4_txt">托管类服务</div>
            <div class="m4_Box2">
              <p>记账报税 纳税申报<br />发票代开 税务核算</p>
              <a
                target="_blank"
                href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuic"
              >
                <div>免费咨询</div>
              </a>
            </div>
          </li>
          <li>
            <div class="m4_txt">财税咨询服务</div>
            <div class="m4_Box2">
              <p>
                返税申报 返税额度核算<br />税款返还到位跟踪<br />优惠备案服务
              </p>
              <a
                target="_blank"
                href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuic"
              >
                <div>免费咨询</div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- title5 -->
    <div class="model5">
      <div class="m_top">
        <h3>服务流程</h3>
        <p>Title 5</p>
        <img src="@/assets/programme/tit_icon.png" alt="" />
      </div>
      <div class="m4Tit">流程化操作，安全放心，便捷省心</div>
      <div class="m5Box">
        <div class="m5Box2">
          <ul>
            <li>
              <img src="@/assets/programme/icon.png" alt="" />
              <p>需求分析产品选择</p>
            </li>
            <li>
              <img src="@/assets/programme/icon2.png" alt="" />
              <p>产品选择专家方案</p>
            </li>
            <li>
              <img src="@/assets/programme/icon3.png" alt="" />
              <p>专家方案签订协议</p>
            </li>
            <li>
              <img src="@/assets/programme/icon4.png" alt="" />
              <p>签订协议服务落地</p>
            </li>
            <li>
              <img src="@/assets/programme/icon5.png" alt="" />
              <p>服务落地售后服务</p>
            </li>
            <li>
              <img src="@/assets/programme/icon6.png" alt="" />
              <p>售后服务</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Foot from "@/components/foot/foot.vue";
export default {
  components: { DetailNavBar, Foot },
  data() {
    return {
      footbg: {},
    };
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.moreText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.top {
  position: relative;
  box-sizing: border-box;
  margin-top: 60px;
}
.top img {
  width: 100%;
}
.topPosition {
  position: absolute;
  top: 102px;
  left: 258px;
}
.top h1 {
  font-size: 70px;
  font-weight: bold;
  color: #ffffff;
}
.topBox {
  display: flex;
  margin-top: 18px;
}
.topBox p {
  font-size: 40px;
  color: #ffffff;
  font-weight: 300;
  margin-right: 55px;
}
.topBtn {
  width: 52%;
  height: 80px;
  background-color: #ffc33f;
  border-radius: 40px;
  font-size: 42px;
  line-height: 80px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 52px;
  cursor: pointer;
}
.Box {
}
.model {
  margin-top: 42px;
}
.m_top {
  text-align: center;
}
.m_top h3 {
  font-size: 32px;
  font-weight: bold;
  color: #224da6;
  margin-bottom: 10px;
}
.m_top p {
  font-size: 18px;
  color: #a4a4a4;
  margin-bottom: 10px;
}
.model_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 52px auto;
  width: 75%;
}
.model_ul li {
  width: 22.6%;
  height: 154px;
  margin-bottom: 24px;
  box-shadow: 0px 0px 20px 0px rgba(218, 218, 218, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
  box-sizing: border-box;
  font-size: 22px;
  color: #1a46a1;
  overflow: hidden;
}
.model2 {
  margin-top: 100px;
}
.videoBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 68%;
  margin: 52px auto;
  justify-content: space-between;
}
.video {
  width: 51%;
  height: 374px;
  background-color: black;
}
.vRight {
  width: 45%;
}
.vRight h3 {
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 12px;
}
.vRight p {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
  margin-bottom: 30px;
}
.vRight div {
  width: 204px;
  height: 56px;
  background-color: #8db2ff;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 56px;
  cursor: pointer;
  border-radius: 6px;
}
.model4 {
  margin-top: 100px;
}
.m4Tit {
  margin-top: 64px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 1px;
}
.m4Box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 64px auto;
  width: 75%;
}
.m4Box li {
  width: 24%;
  margin-bottom: 20px;
}
.m4_txt {
  width: 100%;
  height: 64px;
  background-color: #c4d7ff;
  font-size: 24px;
  font-weight: bold;
  color: #4576dd;
  letter-spacing: 1px;
  text-align: center;
  line-height: 64px;
}
.m4_Box2 {
  width: 100%;
  height: 390px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(218, 218, 218, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 92px 0px 28px;
  box-sizing: border-box;
  text-align: center;
}
.m4_Box2 p {
  font-size: 22px;
  color: #666666;
}
.m4_Box2 a {
  width: 100%;
}
.m4_Box2 div {
  width: 70%;
  height: 56px;
  margin: 0 auto;
  border-radius: 6px;
  box-sizing: border-box;
  border: 2px solid #999999;
  font-size: 18px;
  font-weight: bold;
  color: #666666;
  text-align: center;
  line-height: 56px;
}
.model5 {
  margin-top: 100px;
}

.m5Box {
  width: 100%;
  height: 784px;
  background-color: #e9f0ff;
  margin-top: 32px;
  padding: 82px 0px;
  box-sizing: border-box;
}
.m5Box2 {
  width: 1089px;
  height: 622px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(172, 172, 172, 0.5);
  border-radius: 22px;
  margin: 0 auto;
  padding: 108px 0px;
  box-sizing: border-box;
}
.m5Box2 ul {
  display: flex;
  flex-wrap: wrap;
}
.m5Box2 ul li {
  width: 33.3%;
  text-align: center;
  margin-bottom: 104px;
}
.m5Box2 ul li p {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin-top: 20px;
}

@media (max-width: 1680px) {
  .topPosition {
    top: 86px;
    left: 224px;
  }
  .top h1 {
    font-size: 56px;
  }
  .topBox p {
    font-size: 26px;
  }
  .topBtn {
    height: 60px;
    line-height: 60px;
    font-size: 30px;
  }
  .model_ul li {
    font-size: 20px;
  }
}
/*>=1440的设备*/
@media (max-width: 1440px) {
  .top h1 {
    font-size: 46px;
  }
  .topBox p {
    font-size: 24px;
  }
  .topBtn {
    height: 60px;
    line-height: 60px;
    font-size: 26px;
  }
  .topBtn {
    margin-top: 30px;
  }
  .model_ul li {
    font-size: 18px;
  }
}

/*>=1280的设备*/
@media (max-width: 1280px) {
  .topPosition {
    top: 68px;
  }
  .top h1 {
    font-size: 40px;
  }
  .topBox p {
    font-size: 20px;
    margin-right: 24px;
  }
  .topBtn {
    height: 60px;
    line-height: 60px;
    font-size: 24px;
  }
  .topBtn {
    margin-top: 30px;
  }
}
</style>
