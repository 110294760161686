var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"aboutus"}},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"aboutus_content"},[_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"flex",staticStyle:{"width":"1231px"}},_vm._l((_vm.qbzzList),function(item,index){return _c('div',{key:index},[_c('img',{staticStyle:{"margin":"0 27px 60px 0"},attrs:{"src":item.src,"alt":""}})])}),0)])]),_vm._v(" "),_c('div',{staticClass:"aboutus_content"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"flex",staticStyle:{"width":"1231px","align-items":"flex-end"}},_vm._l((_vm.qbrkList),function(item,index){return _c('div',{key:index},[_c('img',{staticStyle:{"margin":"0 27px 60px 0"},attrs:{"src":item.src,"alt":""}})])}),0)])]),_vm._v(" "),_c('div',{staticClass:"aboutus_content",staticStyle:{"padding-bottom":"200px"}},[_vm._m(5),_vm._v(" "),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"flex",staticStyle:{"flex-wrap":"nowrap","width":"1231px","align-items":"flex-end"}},_vm._l((_vm.qbjzList),function(item,index){return _c('div',{key:index},[_c('img',{staticStyle:{"margin":"0 55px 0px 0"},attrs:{"src":item.src,"alt":""}})])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutus_content",staticStyle:{"margin-top":"30px"}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/title.png"),"alt":""}})]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"74px"}},[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/about_qb.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutus_content"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/title2.png"),"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"60px"}},[_c('div',{staticStyle:{"flex":"1"}}),_vm._v(" "),_c('div',{staticClass:"flex"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/plumBlossom.png"),"alt":""}})]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"63px"}},[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/rz_content.png"),"alt":""}})])]),_vm._v(" "),_c('div',{staticStyle:{"flex":"1"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/title3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"30px"}},[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/zz_content.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/title4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/aboutus_img/aboutUs/title5.png"),"alt":""}})])
}]

export { render, staticRenderFns }