// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { getDatas } from "@/request/api";
export default () => {
  const store = new Vuex.Store({
    state: {},
    // getters: {
    //   parkList: (state) => state.parkList,
    // },
    mutations: {
      parkList(state, list) {
        Vue.set(state, "parkList", list);
        // state.parkList = list;
      },
      proList(state, list) {
        Vue.set(state, "proList", list);
        // state.parkList = list;
      },
      heightnewList(state, list) {
        Vue.set(state, "heightnewList", list);
        // state.parkList = list;
      },
      newsList(state, list) {
        Vue.set(state, "newsList", list);
        // state.parkList = list;
      },
      newsDetail(state, list) {
        Vue.set(state, "newsDetail", list);
        // state.parkList = list;
      },
      qbSku(state, list) {
        Vue.set(state, "qbSku", list);
        // state.parkList = list;
      },

      qbAdvertisements(state, list) {
        Vue.set(state, "qbAdvertisements", list);
      },
      qblink(state, list) {
        Vue.set(state, "qblink", list);
      },
      policy(state, list) {
        Vue.set(state, "policy", list);
      },
      case(state, list) {
        Vue.set(state, "case", list);
      },
    },
    actions: {
      parkList({ commit }) {
        return getDatas("/park/list").then((res) => {
          console.log(res.data);
          commit("gotData", res.data);
        });
        // getDatas("/pro/list").then((res) => {
        //   console.log(res.data);
        //   commit("gotProData", res.data);
        // });
      },
      porList({ commit }) {
        return getDatas("/pro/list").then((res) => {
          console.log(res.data);
          commit("gotProData", res.data);
        });
      },

      // parkList({ commit }) {
      //   return new Promise((resolve) => {
      //     setTimeout(() => {
      //       commit("SET_USER_NAME", "Jason");
      //       resolve();
      //     }, 1000);
      //   });
      // },
    },
  });
  return store;
};
