<template>
  <div class="taxcut">
    <detail-nav-bar></detail-nav-bar>
    <spiritwork></spiritwork>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Spiritwork from "@/views/viewpage/spiritWork.vue";
// import banner from "@/components/banner.js";

export default {
  name: "SPIRITWORK",
  components: { DetailNavBar, Foot, Spiritwork },
  data() {
    return {
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
