<template>
  <div id="taxcut">
    <div class="top">
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo"
       
      >
        <img src="@/assets/taxcut_img/banner.png" alt="" />
      </a>
    </div>
    <img src="@/assets/taxcut_img/painSpot.png" class="painSpot" alt="" />
    <img src="@/assets/taxcut_img/3.jpg" class="flexibile" alt="" />
    <img src="@/assets/taxcut_img/server.png" class="server" alt="" />
  </div>
</template>
<script>
export default {
  name: "Taxcut",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
#taxcut {
  text-align: center;
}
#taxcut .top {
  margin-top: 60px;
}
#taxcut .top img {
  width: 100%;
  height: auto;
}
.painSpot {
  width: 1164px;
  height: auto;
  margin: 113px 0 111px;
}
.flexibile {
  width: 100%;
  height: auto;
}
.server {
  width: 1200px;
  height: auto;
  margin: 110px 0;
}
</style>
