<template>
  <div id="asingle">
    <div class="wrap">
      <div class="top">
        <img src="@/assets/asing_img/top.png" alt="" />
        <button class="topbutton">
          <a
             target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
            
            >免费咨询</a
          >
        </button>
      </div>
      <div class="content">
        <div class="title oneTit">
          <a href="/view/99shuiyou" target="_blank">
            <img src="@/assets/asing_img/faster.png" alt="" />
            <div>
              <p>节税效果</p>
              <p class="explain">安全专业的为企业合理合法的降低税收负担</p>
            </div>
          </a>
        </div>
        <img class="case" src="@/assets/asing_img/case.png" alt="" />
        <div class="buttonBox" href="tel:400-099-3699">
          <button class="telBtn">
            <a
               target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
             
              >免费定制节税方案</a
            >
          </button>
        </div>
        <div class="title">
          <a href="/view/taxation" target="_blank">
            <img src="@/assets/asing_img/light.png" alt="" />
            <div>
              <p>税收优惠政策</p>
              <p class="explain">高效对接优惠园区，精确匹配适合您的税收洼地</p>
            </div>
          </a>
        </div>
        <img class="map" src="@/assets/asing_img/map.png" alt="" />
        <div class="lower">
          <p>税负率降低<span>80%</span></p>
          <p>综合税率低至<span>0.25%</span></p>
        </div>
        <div class="buttonBox">
          <button class="telBtn">
            <a
               target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
             
              >立即咨询</a
            >
          </button>
        </div>
        <div class="title">
          <a href="/view/99shuiyou" target="_blank">
            <img src="@/assets/asing_img/scheme.png" alt="" />
            <div>
              <p>节税方案</p>
              <p class="explain">降低您的税收负担，让您财税无忧</p>
            </div>
          </a>
        </div>
        <ol>
          <li
            @click="
              toDetali(
                'http://www.99qibang.com/schemeDetail.html?id=10ddd3835b574df999ed9770b6ec44fd'
              )
            "
          >
            <p style="margin-top: 119px">
              <span class="round"></span><span>缺少进项发票</span>
            </p>
            <p><span class="round"></span><span>企业利润高</span></p>
            <p>
              <span class="round"></span
              ><span>增值税和企业所得税太高怎么办？</span>
            </p>
          </li>
          <li
            @click="
              toDetali('http://www.99qibang.com/serviceDetail.html?id=68')
            "
          >
            <p style="margin-top: 119px">
              <span class="round"></span><span>年底分红缴纳20%个人所得税</span>
            </p>
            <p><span class="round"></span><span>税负太高怎么办？</span></p>
          </li>
          <li
            @click="
              toDetali(
                'http://www.99qibang.com/schemeDetail.html?id=b3a7e4b523474a5cb09c395542e3b926'
              )
            "
          >
            <p style="margin-top: 119px">
              <span class="round"></span>
              <span>
                员工个税高，税后工资减少，<br />
                <span class="zw"></span
                >企业和个人承办的社保成本太高怎么办？</span
              >
            </p>
          </li>
          <li
            @click="
              toDetali(
                'http://www.99qibang.com/serviceDetail.html?id=648a5af025a241d7aedd08880466bbf6'
              )
            "
          >
            <p style="margin-top: 119px">
              <span class="round"></span
              ><span>
                高收入的兼职或者自由职业者，<br />
                <span class="zw"></span>劳务个税太高怎么办？
              </span>
            </p>
          </li>
        </ol>
        <div class="buttonBox">
          <button class="telBtn">
            <a
               target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
             
              >免费定制个性化解决方案</a
            >
          </button>
        </div>

        <div class="title">
          <a href="/view/aboutUs" target="_blank">
            <img src="@/assets/asing_img/scheme.png" alt="" />
            <div>
              <p>我们提供的税收筹划服务</p>
              <p class="explain">Vip一对一量身定制</p>
            </div>
          </a>
        </div>
        <ul>
          <li class="li_1">
            <p style="margin-top: 92px">税收扶持政策咨询</p>
            <p>专属税收筹划方案定制</p>
            <p>税收疑难解答</p>
            <p>高端税筹筹划服务</p>
          </li>
          <li class="li_2">
            <p style="margin-top: 92px">注册地址服务</p>
            <p>全程税收扶持地企业设立服务</p>
            <p>银行开户服务</p>
            <p>税务报到服务</p>
            <p>税控器申请服务</p>
            <p>特殊资质申请服务</p>
          </li>
          <li class="li_3">
            <p style="margin-top: 92px">企业建账</p>
            <p>日常账务处理</p>
            <p>日常纳税申报</p>
            <p>年度汇算清缴</p>
            <p>年度工商年报</p>
            <p>日常税控器托管</p>
            <p>日常发票开具及邮寄</p>
            <p>发票购买申领</p>
          </li>
          <li class="li_4">
            <p style="margin-top: 92px">扶持资金奖励核算</p>
            <p>扶持资金奖励到位跟踪</p>
            <p>当地税企协调</p>
            <p>企业经营中的所有变更</p>
            <p>公司注销服务</p>
          </li>
        </ul>
        <div class="buttonBox">
          <button class="telBtn bottom">
            <a
               target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
              
              >立即咨询</a
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ASingle",
  data() {
    return {};
  },
  methods: {
    toDetali(dis) {
      window.open(`${dis}`);
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wrap {
  margin-top: 60px;
}

.wrap img {
  width: 100%;
  height: auto;
}

.wrap .topbutton {
  position: absolute;
  background: url("../../assets/asing_img/smButton.png") center center no-repeat;
  width: 232px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  border: 0;
  top: 55%;
  left: 20%;
}

.wrap .topbutton:hover {
  background: url("../../assets/asing_img/smButton-act.png") center center
    no-repeat;
  color: #fff;
}

.wrap .content {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

.wrap .oneTit {
  margin-top: -42px;
}

.wrap .title a {
  display: block;
  margin-left: 38px;
  font-size: 36px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  display: flex;
  margin-bottom: 50px;
}

.wrap .title img {
  width: 84px;
  height: 84px;
  margin-right: 18px;
}

.wrap .explain {
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #999999;
  margin-top: 3px;
}

.wrap .buttonBox {
  width: 100%;
  margin: 0 auto 158px;
  text-align: center;
}

.wrap .telBtn {
  width: 535px;
  height: 80px;
  text-align: center;
  font-size: 30px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  background: url("../../assets/asing_img/button.png") center center no-repeat;
  border: 0;
}

.wrap .telBtn:hover {
  background: url("../../assets/asing_img/button-act.png") center center
    no-repeat;
  color: #fff;
}

.wrap .case {
  margin-bottom: 37px;
}

.wrap .map {
  margin-bottom: 17px;
}

.wrap .lower {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #000;
}

.wrap .lower p {
  margin-bottom: 20px;
}

.wrap .lower span {
  font-size: 30px;
  font-family: MF LiHei (Noncommercial);
  font-weight: 400;
  color: #33aaff;
  line-height: 24px;
  margin-left: 8px;
}

.wrap .lower p:last-child {
  margin-bottom: 40px;
}

.wrap ul {
  display: flex;
  margin-bottom: 80px;
}

.wrap ul li {
  width: 250px;
  height: 410px;
  text-align: center;
}

.wrap ul li p {
  font-size: 18px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #000;
  margin-bottom: 14px;
}

.wrap .round {
  display: inline-block;
  background: #33aaff;
  width: 15px;
  height: 15px;
  margin: 0 20px 0 40px;
  border-radius: 100%;
}

.wrap ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 50px 40px;
}

.wrap ol li {
  cursor: pointer;
  font-size: 20px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #000;
  line-height: 28px;
  width: 540px;
  margin-bottom: 30px;
}

.wrap ol li a {
  width: 100%;
  height: 100%;
  display: block;
}

.wrap ol li p {
  margin-bottom: 20px;
}

.wrap ol li:nth-of-type(1) {
  height: 320px;
  background: url("../../assets/asing_img/revenue.png") center center no-repeat;
  margin-right: 40px;
}

.wrap ol li:nth-of-type(1):hover {
  background: url("../../assets/asing_img/revenue-act.png") center center
    no-repeat;
}

.wrap ol li:nth-of-type(2) {
  height: 320px;
  background: url("../../assets/asing_img/partner.png") center center no-repeat;
  margin-right: 40px;
}

.wrap ol li:nth-of-type(2):hover {
  background: url("../../assets/asing_img/partner-act.png") center center
    no-repeat;
}

.wrap ol li:nth-of-type(3) {
  background: url("../../assets/asing_img/wage.png") center center no-repeat;
  margin-right: 40px;
  height: 240px;
}

.wrap ol li:nth-of-type(3):hover {
  background: url("../../assets/asing_img/wage-act.png") center center no-repeat;
}

.wrap ol li:nth-of-type(4) {
  height: 240px;
  background: url("../../assets/asing_img/freedom.png") center center no-repeat;
  margin-right: 40px;
}

.wrap ol li:nth-of-type(4):hover {
  background: url("../../assets/asing_img/freedom-act.png") center center
    no-repeat;
}

.wrap .bottom {
  margin-bottom: 34px;
}

.wrap .zw {
  margin-left: 76px;
}

.wrap .top {
  position: relative;
}

.wrap button {
  line-height: 80px;
}

.wrap button a {
  display: block;
  width: 100%;
  height: 100%;
}

.wrap button:hover a {
  color: #fff;
}
.li_1 {
  background: url("../../assets/asing_img/previous.png") center center no-repeat;
  margin-right: 40px;
}
.li_2 {
  background: url("../../assets/asing_img/manage.png") center center no-repeat;
  margin-right: 40px;
}
.li_3 {
  background: url("../../assets/asing_img/firm.png") center center no-repeat;
  margin-right: 40px;
}
.li_4 {
  background: url("../../assets/asing_img/elseSer.png") center center no-repeat;
}
</style>
