<template>
  <div id="history">
    <div class="history_content">
      <div>
        <img src="@/assets/aboutus_img/history/title.png" alt="" />
      </div>
      <div style="margin-top: 60px">
        <img src="@/assets/aboutus_img/history/history.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Hostory",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.history_content {
  text-align: center;
  margin: 140px 0 200px 0;
}
</style>
