<template>
  <div class="serverdetail">
    <detail-nav-bar></detail-nav-bar>
    <div id="ServerDetail">
      <!--产品介绍-->
      <div class="product-introduction body-section">
        <!--面包屑导航-->
        <div class="container">
          <div class="guide">
            <a href="/">首页</a>&nbsp;>&nbsp;<a href="/view/99shuiyou">99税优</a
            >&nbsp;>&nbsp;<a href="javascript:void(0)"
              >国家高新技术企业认定服务(普通)</a
            >
          </div>
        </div>
        <!-- 高新服务数据 -->
        <div class="content_box">
          <div>
            <img :src="merchandise.pcPic" alt="" />
          </div>
          <div class="box_left">
            <div class="name">{{ merchandise.name }}</div>
            <div class="description" style="margin-top: 17px">
              产品说明:
              <span class="data_list">{{ merchandise.description }}</span>
            </div>
            <div class="description" style="margin-top: 17px">
              市&nbsp;&nbsp;场&nbsp;&nbsp;价:
              <span class="data_list">{{ merchandise.marketPrice }}</span>
            </div>
            <div class="description" style="margin-top: 17px">
              价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格:
              <span class="data_list price"
                >￥{{ merchandise.price
                }}<span style="font-size: 16px">元/年</span></span
              >
            </div>
            <div class="description" style="margin-top: 17px">
              <div class="flex">
                <div>类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别:</div>
                <div class="data_list ul">
                  <div v-for="(item, index) in qbSku" :key="index">
                    <div
                      v-show="item.province"
                      class="cityList"
                      :class="chose ? 'chosrcitylist' : 'cityList'"
                      @click="chosecity(index)"
                    >
                      {{ item.province }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 30px" class="btn">
              <a
                 target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=www.99qibang.com"
              >
                <img src="@/assets/serverDetail_img/btn.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <!-- 商品详情 -->
        <div class="content_detail">
          <div class="detail_title">商品详情</div>
          <div>
            <img src="@/assets/serverDetail_img/img1.png" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/img2.png" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/img3.png" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/img4.png" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/img5.png" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/img6.png" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/img7.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- <server-detail></server-detail> -->
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Foot from "@/components/foot/foot.vue";
// import ServerDetail from "@/views/viewpage/serviceDetail.vue";
// import banner from "@/components/banner.js";
import { postData } from "@/request/api";
export default {
  // async asyncData({ route, store }) {
  //   console.log(route.query.id);
  //   var formdatas = new FormData();
  //   // const params = {
  //   //   id: route.query.id,
  //   //   positionType: "1",
  //   // };
  //   formdatas.set("id", route.query.id);
  //   formdatas.set("positionType", "1");
  //   const heightnewList = await postData(
  //     "/qbmerchandise/qbMerchandise/findMerDetail",
  //     formdatas
  //   );

  //   store.commit("heightnewList", heightnewList);
  // },
  name: "SERVERDETAIL",
  components: { DetailNavBar, Foot },
  data() {
    return {
      footbg: {},
      merchandise: {},
      qbSku: [],
      chose: false,
    };
  },
  created() {
    this.getDeatil();
  },
  methods: {
    async getDeatil() {
      var FormData = require("form-data");
      var formdatas = new FormData();
      // const params = {
      //   id: route.query.id,
      //   positionType: "1",
      // };
      formdatas.append("id", this.$route.query.id);
      formdatas.append("positionType", "1");
      const heightnewList = await postData(
        "/qbmerchandise/qbMerchandise/findMerDetail",
        formdatas
      );
      this.$store.commit("heightnewList", heightnewList);
      this.merchandise = this.$store.state.heightnewList.merchandise;
      this.qbSku = this.$store.state.heightnewList.qbSku;
    },
    chosecity(index) {
      this.merchandise.name = this.qbSku[index].merName;
      this.merchandise.price = this.qbSku[index].price;
      this.chose = true;
    },
  },
};
</script>
<style scoped>
.body-section {
  margin-top: 80px;
}

.product-introduction {
  width: 100%;
  margin-bottom: 20px;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.guide {
  line-height: 56px;
  height: 56px;
  color: #4a4a4a;
  background-color: #fcfcfc;
}
.guide a {
  color: #4a4a4a;
}
.content_box {
  margin-top: 66px;
  display: flex;
  justify-content: center;
}
.box_left {
  margin-left: 32px;
}
.name {
  font-size: 36px;
  color: #5b5e66;
}
.description {
  font-size: 20px;
  color: #999999;
  text-align: left;
}
.data_list {
  margin-left: 35px;
  font-size: 20px;
  color: #5b5e66;
}
.price {
  font-size: 40px;
  color: #da0000;
}
.content_detail {
  text-align: center;
  margin-top: 200px;
}
.detail_title {
  height: 60px;
  line-height: 60px;
  color: #999999;
}
.btn {
  cursor: pointer;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: left;
}
.ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}
.cityList {
  padding: 10px 88px;
  font-size: 16px;
  border: 1px solid #999999;
  color: #5b5e66;
  margin-right: 17px;
}
.chosrcitylist {
  padding: 10px 88px;
  font-size: 16px;
  border: 1px solid #3d93f9;
  color: #3d93f9;
  margin-right: 17px;
}
</style>
