<template>
  <div class="youyuan">
    <detail-nav-bar></detail-nav-bar>
    <div class="top">
      <img src="@/assets/ChannelJoin_img/banner.png" alt="" />
    </div>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <channel-join
      :title6_bgImg="title6_bgImg"
      :title5_bgImg="title5_bgImg"
    ></channel-join>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import ChannelJoin from "@/views/viewpage/ChannelJoin.vue";
// import banner from "@/components/banner.js";

export default {
  metaInfo: {
    title: "渠道加盟_值得信赖的企业服务平台_99企帮",
    meta: [
      {
        name: "keywords",
        content:
          "资源共享，合作共赢，个人独资，个体工商户，有限公司返还，有限公司奖励，加盟，99企帮，服务闭环，政策扶持",
      },
      {
        name: "description",
        content:
          "您可以借助99企帮的平台，实现以下目标：打破经营瓶颈、获取更多本地资源、将业务拓展倒全国各地、打造企业服务闭环、高端高价值利润产品",
      },
    ],
  },
  name: "CHANNELJOIN",
  components: { DetailNavBar, Foot, ChannelJoin },
  data() {
    return {
      // qbAdvertisements: [
      //   { picture: require("@/assets/ChannelJoin_img/banner.png") },
      // ],
      title5_bgImg: {
        src: require("@/assets/ChannelJoin_img/title3_img3.png"),
      },

      title6_bgImg: {
        src: require("@/assets/ChannelJoin_img/title6_bgImg.png"),
      },
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.youyuan .top {
  margin-top: 60px;
}
.youyuan .top img {
  width: 100%;
  height: auto;
}
</style>
