var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"linggong"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"content",staticStyle:{"margin-top":"30px"}},[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"one_content"},[_c('div',{staticStyle:{"flex":"1"}}),_vm._v(" "),_vm._l((_vm.oneList),function(item,index){return _c('div',{key:index,staticClass:"one_content"},[_c('div',{staticStyle:{"margin":"0 27px 27px 0"}},[_c('img',{attrs:{"src":item.src,"alt":""}})])])}),_vm._v(" "),_c('div',{staticStyle:{"flex":"1"}})],2)]),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"content three_content",style:({
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    })},[_vm._m(4)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("@/assets/99linggong_img/linggong111.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/99linggong_img/one/title1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"28px","margin-bottom":"59px"}},[_c('img',{attrs:{"src":require("@/assets/99linggong_img/one/content.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/99linggong_img/two/title.png"),"alt":""}})]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"56px"}},[_c('img',{attrs:{"src":require("@/assets/99linggong_img/two/content.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin-bottom":"59px"}},[_c('img',{attrs:{"src":require("@/assets/99linggong_img/three/title.png"),"alt":""}})]),_vm._v(" "),_c('div',[_c('span',{staticClass:"three_item"},[_c('img',{attrs:{"src":require("@/assets/99linggong_img/three/img1.png"),"alt":""}})]),_vm._v(" "),_c('span',{staticClass:"three_item"},[_c('img',{attrs:{"src":require("@/assets/99linggong_img/three/img3.png"),"alt":""}})]),_vm._v(" "),_c('span',[_c('img',{attrs:{"src":require("@/assets/99linggong_img/three/img5.png"),"alt":""}})])]),_vm._v(" "),_c('div',{staticStyle:{"margin":"30px 0 30px 0"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/99linggong_img/three/fengeline.png"),"alt":""}})]),_vm._v(" "),_c('div',[_c('span',{staticClass:"three_item"},[_c('img',{attrs:{"src":require("@/assets/99linggong_img/three/img2.png"),"alt":""}})]),_vm._v(" "),_c('span',[_c('img',{attrs:{"src":require("@/assets/99linggong_img/three/img4.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }