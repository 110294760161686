<template>
  <div id="stratrgy">
    <!-- 战略合作 -->
    <div class="stratrgy_content" style="margin-top: 140px">
      <div>
        <img src="@/assets/aboutus_img/stratrgy/title.png" alt="" />
      </div>
      <div style="margin-top: 64px" class="flex">
        <div style="flex: 1"></div>
        <div class="flex">
          <div>
            <img src="@/assets/aboutus_img/stratrgy/zl_img.png" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div style="text-align: left">
              <img src="@/assets/aboutus_img/stratrgy/zl_content1.png" alt="" />
            </div>
            <div style="margin-top: 29px">
              <img src="@/assets/aboutus_img/stratrgy/zl_content2.png" alt="" />
            </div>
            <div style="margin-top: 29px">
              <img src="@/assets/aboutus_img/stratrgy/zl_content3.png" alt="" />
            </div>
          </div>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
    <!-- 俱乐部 -->
    <div class="stratrgy_content">
      <div>
        <img src="@/assets/aboutus_img/stratrgy/title2.png" alt="" />
      </div>
      <div style="margin-top: 60px">
        <img src="@/assets/aboutus_img/stratrgy/zlb_content.png" alt="" />
      </div>
    </div>
    <!-- 顾问 -->
    <div
      class="stratrgy_content"
      style="margin-top: 60px; padding-bottom: 151px"
    >
      <div>
        <img src="@/assets/aboutus_img/stratrgy/title3.png" alt="" />
      </div>
      <div style="margin-top: 60px" class="flex">
        <div style="flex: 1"></div>
        <div class="flex">
          <div v-for="(item, index) in guwenList" :key="index">
            <div style="margin-right: 27px">
              <img :src="item.src" alt="" />
            </div>
          </div>
        </div>
        <div style="flex: 1; margin-left: -27px"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Stratrgy",
  data() {
    return {
      // 顾问数据
      guwenList: [
        {
          src: require("@/assets/aboutus_img/stratrgy/guwen_img1.png"),
        },
        {
          src: require("@/assets/aboutus_img/stratrgy/guwen_img2.png"),
        },
        {
          src: require("@/assets/aboutus_img/stratrgy/guwen_img3.png"),
        },
        {
          src: require("@/assets/aboutus_img/stratrgy/guwen_img4.png"),
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.stratrgy_content {
  text-align: center;
  margin-top: 200px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
