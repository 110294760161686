<template>
  <div class="caishui">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <caishui></caishui>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Caishui from "@/views/viewpage/99caishui.vue";
// import banner from "@/components/banner.js";

export default {
  metaInfo: {
    title: "99财税_互联网财税平台",
    meta: [
      {
        name: "keywords",
        content:
          "财税服务，财税顾问，财税咨询，财税政策，财税平台，政策解读，股权架构，股权结构，税收规划",
      },
      {
        name: "description",
        content:
          "通过技术+大数据为2000万会计搭建线上财税资讯、咨询、交流及培训赋能等互联网财税平台,让财务学习更简单。",
      },
    ],
  },
  name: "CAISHUI",
  components: { DetailNavBar, Foot, Caishui },
  data() {
    return {
      // qbAdvertisements: [
      //   { picture: require("@/assets/99caishui_img/banner.png") },
      // ],
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
