<template>
  <div id="police">
    <div class="police_search">
      <div class="input">
        <input type="text" placeholder="请输入您要搜索的内容" v-model="vlaue" />
        <input type="button" value="搜索" class="search" @click="search()" />
      </div>
    </div>
    <div class="police_content">
      <div style="flex: 1"></div>
      <div style="flex: 8">
        <div
          v-for="(item, index) in caseList"
          :key="index"
          class="flex policybox"
          @click="policyDetail(item.id)"
        >
          <div class="left_img" style="display: none">
            <img v-if="item.titlePic" :src="item.titlePic" alt="" />
          </div>
          <div class="right_content">
            <div class="title">{{ item.title }}</div>
            <div class="contents">{{ item.firstName }}</div>
            <div class="foot">
              <div class="right_content_name" v-if="item.tagNames">
                {{ item.tagNames }}
              </div>
              <div v-else></div>
              <div>
                <span>发表于{{ item.releaseTime }}</span>
                <span style="margin-left: 30px">{{ item.browse }}阅读</span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px; margin-bottom: 60px">
          <el-pagination
            layout="prev, pager, next"
            :total="count"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div style="flex: 1"></div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/request/api";
export default {
  props: {
    caseList: {
      type: Array,
      required: true,
    },
  },
  name: "Case",
  data() {
    return {
      vlaue: "", // 搜索输入值
      pageSize: 10,
      pageNo: 1,
      original: 4,
      positionTyps: 1,
      count: 0,
      // caseList: [],
    };
  },
  created() {
    console.log(this.caseList);
  },
  mounted() {
    this.getpolicyList();
  },
  methods: {
    getpolicyList() {
      // var params = {
      //   pageSize: this.pageSize,
      //   pageNo: this.pageNo,
      //   original: this.original,
      //   positionTyps: this.positionTyps,
      //   title: this.vlaue,
      // };
      var formDatas = new FormData();
      formDatas.append("pageSize", this.pageSize);
      formDatas.append("pageNo", this.pageNo);
      formDatas.append("original", this.original);
      formDatas.append("positionTyps", this.positionTyps);
      formDatas.append("title", this.vlaue);
      postData("/qbnews/qbNews/articlelist", formDatas).then((res) => {
        if (res.data == "success") {
          this.value = "";
          this.count = res.count;
          this.caseList = res.qbNews;
          console.log(this.caseList);
        }
      });
    },
    // 页面发生变化
    currentChange(e) {
      this.pageNo = e;
      this.getpolicyList();
    },
    // 点击搜索
    search() {
      this.getpolicyList();
    },
    policyDetail(id) {
      location.href = "/casedetail?id=" + id;
      // this.$router.push({
      //   path: "/casedetail",
      //   query: { id: id },
      // });
    },
  },
};
</script>
<style>
#police {
  text-align: center;
}
.police_search {
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
}
.police_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.police_search .search {
  text-align: center;
  padding: 0;
  width: 140px;
  color: #fff;
  font-size: 16px;
  background-color: #5b5e66;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input {
  border-radius: 24px;
  background-color: #fff;
}
.input input {
  width: 263px;
  height: 48px;
  line-height: 48px;
  padding-left: 26px;
  border: none;
  background-color: #fff;
  border-radius: 24px;
  outline: none;
  font-size: 14px;
  color: #5b5e6f;
}
.policybox {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeee;
  cursor: pointer;
}
.flex {
  display: flex;
  align-items: center;
}
.left_img {
  flex: 2;
}
.left_img img {
  width: 280px;
  height: 187px;
}
.right_content {
  text-align: left;
  flex: 8;
  margin-left: 39px;
}
.right_content .title {
  font-size: 20px;
  color: #5b5e6f;
  margin-bottom: 19px;
}
.right_content .contents {
  font-size: 16px;
  color: #5b5e6f;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 30px;
}
.right_content .foot {
  color: #5b5e6f;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right_content_name {
  padding: 6px 25px;
  color: #999999;
  background-color: #ededed;
  border-radius: 25px;
}
#police .el-pager li {
  background-color: #fafafa !important;
  height: 40px !important;
  line-height: 40px !important;
}
#police .el-pagination button {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}
</style>
