<template>
  <div class="youyuan">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <!-- <youyuan></youyuan> -->
    <div id="youyuan">
      <div class="top">
        <img src="@/assets/99youyuan_img/banner.png" alt="" />
      </div>
      <div class="youyuan_content">
        <div>
          <img src="@/assets/99youyuan_img/99youyuan_jieshao.png" alt="" />
        </div>
        <div style="margin: 28px 0 73px 0">
          <img src="@/assets/99youyuan_img/youyuan_content.png" alt="" />
        </div>
        <div class="youyuan_center flex">
          <div style="flex: 1"></div>
          <div class="youyuan_center_item" style="flex: 7">
            <div>
              <img src="@/assets/99youyuan_img/99youyuan.png" alt="" />
            </div>
            <div style="margin-left: 124px; padding-top: 46px">
              <img src="@/assets/99youyuan_img/youyuan2.png" alt="" />
            </div>
          </div>
          <div style="flex: 1"></div>
        </div>
      </div>
      <div class="park_pro flex">
        <div style="flex: 1"></div>
        <div style="width: 80%">
          <el-tabs v-model="activeName" @click="handleClick">
            <el-tab-pane label="找园区" name="first">
              <div
                class="park_item"
                v-for="(item, index) in parkList"
                :key="index"
              >
                <div class="park_item_part">
                  <div class="park_item_img">
                    <img
                      :src="item.coverImg"
                      v-show="item.coverImg"
                      style="width: 360px; height: 241px"
                    />
                  </div>
                  <div class="park_item-content">
                    <div class="park_item-content_title">
                      {{ item.name }}
                    </div>
                    <div class="park_item-content_level">
                      <span style="margin-right: 103px" v-show="item.level"
                        >园区级别:{{
                          item.level == 1
                            ? "国家级"
                            : item.level == 2
                            ? "省级"
                            : item.level == 3
                            ? "市级"
                            : item.level == 4
                            ? "区县级"
                            : item.level == 5
                            ? "其他"
                            : ""
                        }}</span
                      >
                      <span v-show="item.parkArea">{{ item.parkArea }}</span>
                    </div>
                    <div class="park_item-content_light">
                      <div
                        class="park_item-content_light_item"
                        v-for="(ele, i) in item.parkSparkle"
                        :key="i"
                      >
                        {{
                          ele == 1
                            ? "政策稳定"
                            : ele == 2
                            ? "服务对接好"
                            : ele == 3
                            ? "税收奖励快"
                            : ele == 4
                            ? "扶持力度大"
                            : ele == 5
                            ? "区位优势明显"
                            : ele == 6
                            ? "人力资源丰富"
                            : ele == 7
                            ? "投资潜力大"
                            : ele == 8
                            ? "产业集群度高"
                            : ele
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div style="flex: 1">
                  <div style="text-align: center; padding: 21px 17px">
                    <img :src="item.xcxQRCodePath" alt="" class="qr_logo" />
                    <!-- <div class="qrCode" :id="item.id"></div> -->
                    <!-- <vue-qr
                    :size="130"
                    :margin="0"
                    :autoColor="true"
                    text="https://xcxst.99youyuan.com/parkdetali?id=135"
                    :logoSrc="logo"
                    :logoScale="13"
                  ></vue-qr> -->
                    <p style="font-size: 12px; margin-top: 10px">
                      扫码了解详情
                    </p>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="找项目" name="second">
              <div
                class="pro_item"
                v-for="(item, index) in proList"
                :key="index"
              >
                <div class="pro_item_pro">
                  <div class="pro_item_content" style="padding-bottom: 26px">
                    <div class="pro_item_title" style="flex: 3; color: #5b5e66">
                      {{ item.purpose }}
                    </div>
                    <div
                      class="pro_item_proneed"
                      style="flex: 4; font-size: 14px; color: #5b5e66"
                    >
                      {{ item.mainTitle }}
                    </div>
                  </div>
                  <div class="pro_item_content" style="padding-bottom: 59px">
                    <div
                      class="pro_item_city"
                      style="flex: 3; font-size: 14px; color: #5b5e66"
                    >
                      <div v-show="item.firstExpectLocation">
                        首选区域:{{ item.firstExpectLocation }}
                      </div>
                    </div>
                    <div
                      class="pro_item_proNeeds"
                      style="flex: 4; font-size: 16px; color: #999999"
                    >
                      {{ item.content }}
                    </div>
                  </div>
                  <div class="pro_item_content">
                    <div
                      class="pro_item_enter"
                      style="flex: 3; font-size: 14px; color: #5b5e66"
                    >
                      {{ item.title }}
                    </div>
                    <div
                      class="pro_item_time"
                      style="flex: 4; font-size: 14px; color: #999999"
                    >
                      {{ item.createTime }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1; padding-left: 99px">
                  <div style="text-align: center; padding: 21px 17px">
                    <img :src="item.xcxQRCodePath" alt="" class="qr_logo" />
                    <!-- <img :src="logo" alt="" class="qr_logo" /> -->
                    <!-- <div class="qrCode" :id="item.id"></div> -->
                    <!-- <vue-qr
                    :size="130"
                    :margin="0"
                    :autoColor="true"
                    :logoSrc="logo"
                    :logoScale="13"
                    text="https://xcxst.99youyuan.com/prodetali?id=179"
                  ></vue-qr> -->
                    <p style="font-size: 12px; margin-top: 10px">
                      扫码了解详情
                    </p>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <div style="flex: 1"></div>
      </div>
      <!-- <div class="qrCode" id="123"></div> -->
      <router-view></router-view>
      <div class="lookmore">
        <div class="btn" @click="lookmore()" :class="{ btns: more == true }">
          查看更多
        </div>
        <div class="xcx_ewm">
          <img
            v-show="xcx_ewm"
            src="@/assets/99youyuan_img/xcx_ewm.png"
            alt=""
          />
        </div>
      </div>
      <div class="mosdel" v-show="xcx_ewm" @click="page()"></div>
    </div>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
// import Youyuan from "@/views/viewpage/99youyuan.vue";
// import banner from "@/components/banner.js";
import { getDatas } from "@/request/api";
export default {
  async asyncData({ store }) {
    const parklist = await getDatas("/park/list");
    const parkList_ = parklist.data.splice(0, 3);
    const proList = await getDatas("/pro/list");
    const prokList_ = proList.data.splice(0, 3);
    // console.log(parkList_[0], prokList_[0]);
    store.commit("parkList", parkList_);
    store.commit("proList", prokList_);
    return {
      parkList_,
      prokList_,
    };
  },
  metaInfo: {
    title: "厂房_园区_99优园_数字化云招商平台",
    meta: [
      {
        name: "keywords",
        content:
          "厂房，园区，招商政策，政策补贴，房租补贴，厂房补贴，医疗园区，物流园区，互联网园区，高科技园区，实体招商，厂房租赁，仓库租赁，厂房买卖，土地买卖",
      },
      {
        name: "description",
        content:
          "99优园隶属于北京优企帮帮科技有限公司,基于产业互联网+大数据的数字化云招商平台,为园区招商提供更高效的整体解决方案。联结全国园区丰富的载体场景、多样的招商政策,围绕企业发展中多元的需求,对园区和企业间实现精准匹配和交易撮合",
      },
    ],
  },
  name: "YOUYUAN",
  components: { DetailNavBar, Foot },
  data() {
    return {
      parkList_: [],
      prokList_: [],
      logo: require("@/assets/img/logo2.png"),
      activeName: "first",
      more: false, // 查看更多按钮的变化
      xcx_ewm: false, // 小程序二维码变化
      parkList: [],
      proList: [],
      // qbAdvertisements: [
      //   { picture: require("@/assets/99youyuan_img/banner.png") },
      // ],
      footbg: {},
    };
  },
  created() {
    if (this.$store.state.parkList.length > 0) {
      var list = this.$store.state.parkList;
      // list.forEach((el) => {
      //   if (el.parkSparkle) {
      //     el.parkSparkle = el.parkSparkle.split(",");
      //   }
      //   if (el.parkPic) {
      //     if (el.parkPic.length > 1) {
      //       el.parkPic = el.parkPic.split(",")[0];
      //     }
      //   }
      // });
      // list[0].src = "https://xcxst.99youyuan.com/parkdetali?id=" + list[0].id;
      // list[0].src = "https://xcxs.99youyuan.com/parkdetali?id=" + list[0].id;

      // // list[1].src = "https://xcxst.99youyuan.com/parkdetali?id=" + list[1].id;
      // list[1].src = "https://xcxs.99youyuan.com/parkdetali?id=" + list[1].id;
      // // list[2].src = "https://xcxst.99youyuan.com/parkdetali?id=" + list[2].id;
      // list[2].src = "https://xcxs.99youyuan.com/parkdetali?id=" + list[2].id;
      this.parkList = list;
      // console.log(this.parkList);
    }
    if (this.$store.state.proList.length > 0) {
      this.proList = this.$store.state.proList;
      // this.proList[0].src =
      //   // "https://xcxst.99youyuan.com/prodetali?id=" + this.proList[0].id;
      //   "https://xcxs.99youyuan.com/prodetali?id=" + this.proList[0].id;
      // this.proList[1].src =
      //   // "https://xcxst.99youyuan.com/prodetali?id=" + this.proList[1].id;
      //   "https://xcxs.99youyuan.com/prodetali?id=" + this.proList[1].id;
      // this.proList[2].src =
      //   // "https://xcxst.99youyuan.com/prodetali?id=" + this.proList[2].id;
      //   "https://xcxs.99youyuan.com/prodetali?id=" + this.proList[2].id;
      // console.log(this.proList);
    }
  },
  mounted() {
    this.getparkList();
    this.getproList();
  },
  methods: {
    // 获取 园区数据
    getparkList() {
      // const list = await getDatas("/park/list");
      this.parkList = this.$store.state.parkList;
      console.log(this.$store.state.parkList);
      if (this.$store.state.parkList.length > 0) {
        var list = this.$store.state.parkList;
        console.log(list);
        console.log("999");
        // list.forEach((el) => {
        //   if (el.parkSparkle) {
        //     el.parkSparkle = el.parkSparkle.split(",");
        //   }
        //   if (el.parkPic) {
        //     if (el.parkPic.length > 1) {
        //       el.parkPic = el.parkPic.split(",")[0];
        //     }
        //   }
        // });
        // // list[0].src = "https://xcxst.99youyuan.com/parkdetali?id=" + list[0].id;
        // list[0].src = "https://xcxs.99youyuan.com/parkdetali?id=" + list[0].id;

        // // list[1].src = "https://xcxst.99youyuan.com/parkdetali?id=" + list[1].id;
        // list[1].src = "https://xcxs.99youyuan.com/parkdetali?id=" + list[1].id;
        // // list[2].src = "https://xcxst.99youyuan.com/parkdetali?id=" + list[2].id;
        // list[2].src = "https://xcxs.99youyuan.com/parkdetali?id=" + list[2].id;
        this.parkList = list;
        console.log(this.parkList);
        // this.mutationCallBack();
      }
    },
    // 获取 项目数据
    getproList() {
      if (this.$store.state.proList.length > 0) {
        this.proList = this.$store.state.proList;
        // this.proList[0].src =
        //   // "https://xcxst.99youyuan.com/prodetali?id=" + this.proList[0].id;
        //   "https://xcxs.99youyuan.com/prodetali?id=" + this.proList[0].id;
        // this.proList[1].src =
        //   // "https://xcxst.99youyuan.com/prodetali?id=" + this.proList[1].id;
        //   "https://xcxs.99youyuan.com/prodetali?id=" + this.proList[1].id;
        // this.proList[2].src =
        //   // "https://xcxst.99youyuan.com/prodetali?id=" + this.proList[2].id;
        //   "https://xcxs.99youyuan.com/prodetali?id=" + this.proList[2].id;
        // console.log(this.proList);
        // this.mutationCallBack2();
      }
    },
    // 获取园区二维码
    // mutationCallBack() {
    //   let _this = this;
    //   //nextTick函数将回调延迟到下次 DOM 更新循环之后执行
    //   _this.$nextTick(() => {
    //     for (let i = 0; i < _this.parkList.length; i++) {
    //       _this.createQrCode(_this.parkList[i].id, _this.parkList[i].src);
    //     }
    //   });
    // },
    // 获取项目二维码
    // mutationCallBack2() {
    //   let _this = this;
    //   //nextTick函数将回调延迟到下次 DOM 更新循环之后执行
    //   _this.$nextTick(() => {
    //     for (let i = 0; i < _this.proList.length; i++) {
    //       _this.createQrCode(_this.proList[i].id, _this.proList[i].src);
    //     }
    //   });
    // },
    // createQrCode(qrstr, src) {
    //   var QRCode = require("qrcodejs2");
    //   // 创建二维码
    //   // let qrcodeElementArr = document.getElementsByClassName(qrstr);
    //   // qrcodeElementArr.innerHtml = "";
    //   new QRCode(qrstr.toString(), {
    //     width: 130,
    //     height: 130,
    //     text: src,
    //   });
    // },
    // 点击查看更多
    lookmore() {
      this.more = true;
      this.xcx_ewm = true;
    },
    // 点击空白页面
    page() {
      this.more = false;
      this.xcx_ewm = false;
    },
    // 点击tab页
    handleClick(tab) {
      console.log(tab.index);
      if (tab.index == 0) {
        this.getparkList();
      } else if (tab.index == 1) {
        this.getproList();
      }
    },
  },
};
</script>
<style>
.youyuan_content {
  text-align: center;
  padding-top: 30px;
}
/* .youyuan_center {
  padding: 14px 373px 24px 371px;
} */
.youyuan_center_item {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-content: center;
}
#youyuan .top {
  margin-top: 60px;
}
#youyuan .top img {
  width: 100%;
  height: auto;
}
.lookmore {
  margin-bottom: 199px;
  margin-top: 80px;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}
.btn {
  text-align: center;
  width: 230px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  background-color: #5b5e66;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.btn:hover {
  background-color: #3d93f9;
}
.btns {
  background-color: #3d93f9;
}
.xcx_ewm {
  position: absolute;
  top: -300px;
  z-index: 999999999;
}
.mosdel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.1;
  z-index: 99999;
}
/* 项目 、、 园区 */
.park_pro {
  padding: 62px 0px 29px 0px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.park_pro .el-tabs__item {
  padding-right: 30px !important;
  height: 72px !important;
  line-height: 72px !important;
  font-size: 30px !important;
  color: #5b5e66 !important;
}
.park_pro .el-tabs__item.is-active {
  color: #3d93f9 !important;
}
.park_item {
  border-bottom: 1px solid #ededed;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.pro_item {
  border-bottom: 1px solid #ededed;
  padding-bottom: 58px;
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.park_item_part {
  flex: 8;
  display: flex;
  align-content: center;
  /* justify-content: center; */
}
.park_item_img {
  margin-right: 40px;
}
.park_item-content_title {
  padding-top: 37px;
  font-size: 28px;
}
.park_item-content_level {
  padding-top: 27px;
  padding-bottom: 59px;
  font-size: 14px;
}
.park_item-content_light {
  display: flex;
  flex-wrap: wrap;
}
.park_item-content_light_item {
  text-align: center;
  border-bottom: 1px solid #ededed;
  border-radius: 18px;
  font-size: 14px;
  padding: 13px 26px 10px 25px;
  margin: 0 20px 20px 0;
  color: #999999;
}
.pro_item_pro {
  flex: 6;
}
.pro_item_content {
  display: flex;
  align-content: center;
}
.pro_item_title {
  font-size: 28px;
}
.qr_logo {
  width: 130px;
  /* position: absolute;
  top: 80px;
  left: 65px; */
}
</style>
