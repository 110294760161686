<template>
  <div class="linggong">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <linggong :three_img="three_img"></linggong>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Linggong from "@/views/viewpage/99linggong.vue";
// import banner from "@/components/banner.js";

export default {
  metaInfo: {
    title: "99灵工_一站式灵活用工服务平台",
    meta: [
      {
        name: "keywords",
        content: "灵活用工，灵工，灵活就业，代发工资",
      },
      {
        name: "description",
        content:
          "专业的灵活用工平台，通过数据和技术支持，为企业提供社会化用工人员，一站式完成任务派发、佣金发放、开票和完税",
      },
    ],
  },
  name: "LINGGONG",
  components: { DetailNavBar, Foot, Linggong },
  data() {
    return {
      // qbAdvertisements: [
      //   { picture: require("@/assets/99linggong_img/banner.png") },
      // ],
      three_img: {
        src: require("@/assets/99linggong_img/three/linggong111.png"),
      },
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
