<template>
  <div id="taxPlann">
    <div class="wrap">
      <div class="top">
        <a href="/view/99shuiyou" target="_blank">
          <img src="@/assets/taxPlan_img/banner.png" alt="" />
        </a>
      </div>
      <div class="puzz-content">
        <div class="title">
          <a>
            <img src="@/assets/taxPlan_img/puzzle.png" alt="" />
            <p>您可能遇到的财税难题?</p>
          </a>
        </div>
        <ul class="puzzle">
          <li>
            <img src="@/assets/taxPlan_img/revenue.png" alt="" />
            <p>企业所得税负担过重</p>
            <p class="explain">企业利润虚高，缴纳企业所得税25%</p>
          </li>
          <li>
            <img src="@/assets/taxPlan_img/personalTax.png" alt="" />
            <p>个人所得税高</p>
            <p class="explain">想要高工资，但高工资就意味着高税负</p>
          </li>
          <li>
            <img src="@/assets/taxPlan_img/cost.png" alt="" />
            <p>成本票缺失</p>
            <p class="explain">
              公司采购商品对方无法提供发票、隐形成本、佣金支出等
            </p>
          </li>
          <li>
            <img src="@/assets/taxPlan_img/staff.png" alt="" />
            <p>股东不敢分红</p>
            <p class="explain">股东分红税20%，按股东借款不是办法，分红难提现</p>
          </li>
        </ul>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
         
          class="button"
          >免费咨询</a
        >
      </div>
      <div class="question">
        <div class="question-content">
          <div class="title">
            <a href="http://www.99qibang.com/service.html" target="_blank">
              <img src="@/assets/taxPlan_img/question.png" alt="" />
              <p>为什么要做税收筹划?</p>
            </a>
          </div>
          <img src="@/assets/taxPlan_img/case.png" class="img" alt="" />
          <a
             target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
            
            class="button"
            >点击咨询税筹老师</a
          >
        </div>
      </div>
      <div class="difference">
        <div class="title">
          <a>
            <img src="@/assets/taxPlan_img/difference.png" alt="" />
            <p>偷税与合规节税的区别?</p>
          </a>
        </div>
        <img src="@/assets/taxPlan_img/contrast.png" class="img" alt="" />
      </div>
      <div class="light">
        <div class="light-content">
          <div class="title">
            <a href="http://www.99qibang.com/scheme.html" target="_blank">
              <img src="@/assets/taxPlan_img/light.png" alt="" />
              <div>
                <p>如何正规节税?</p>
                <p class="explain">节税需谨慎，选择合理合法节税方案</p>
              </div>
            </a>
          </div>
          <ul class="reply">
            <li
              onclick='toDetali("http://www.99qibang.com/schemeDetail.html?id=10ddd3835b574df999ed9770b6ec44fd")'
            >
              <a>
                <img src="@/assets/taxPlan_img/enterprise.png" alt="" />
                <p>企业税收解决办法</p>
              </a>
              <p>
                <img
                  class="rectangle"
                  src="@/assets/taxPlan_img/rectangle.png"
                  alt=""
                />
                <span>缺少进项发票</span>
              </p>
              <p>
                <img
                  class="rectangle"
                  src="@/assets/taxPlan_img/rectangle.png"
                  alt=""
                />
                <span>企业利润高</span>
              </p>
              <p>
                <img
                  class="rectangle"
                  src="@/assets/taxPlan_img/rectangle.png"
                  alt=""
                />
                <span>增值税和企业所得税太高怎么办？</span>
              </p>
            </li>
            <li
              onclick='toDetali("http://www.99qibang.com/serviceDetail.html?id=68")'
            >
              <a>
                <img src="@/assets/taxPlan_img/people.png" alt="" />
                <p>股东分红解决办法</p>
              </a>
              <p>
                <img
                  class="rectangle"
                  src="@/assets/taxPlan_img/rectangle.png"
                  alt=""
                />
                <span>年底分红缴纳20%个人所得税</span>
              </p>
              <p>
                <img
                  class="rectangle"
                  src="@/assets/taxPlan_img/rectangle.png"
                  alt=""
                />
                <span>税负太高怎么办？</span>
              </p>
            </li>
            <li
              onclick='toDetali("http://www.99qibang.com/schemeDetail.html?id=b3a7e4b523474a5cb09c395542e3b926")'
            >
              <a>
                <img src="@/assets/taxPlan_img/revenue.png" alt="" />
                <p>员工工资个税+社保解决办法</p>
              </a>
              <p>
                <img
                  class="rectangle"
                  src="@/assets/taxPlan_img/rectangle.png"
                  alt=""
                />
                <span>员工个税高，税后工资减少，</span>
                <span style="margin-left: 80px"
                  >企业和个人承办的社保成本太高怎么办？</span
                >
              </p>
            </li>
            <li
              onclick='toDetali("http://www.99qibang.com/serviceDetail.html?id=648a5af025a241d7aedd08880466bbf6")'
            >
              <a>
                <img src="@/assets/taxPlan_img/cap.png" alt="" />
                <p>兼职+自由职业者个税解决办法</p>
              </a>
              <p>
                <img
                  class="rectangle"
                  src="@/assets/taxPlan_img/rectangle.png"
                  alt=""
                />
                <span>高收入的兼职或者自由职业者，</span>
                <span style="margin-left: 80px">劳务个税太高怎么办？</span>
              </p>
            </li>
          </ul>
          <a
             target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
           
            class="button"
            >点击定制个性化节税方法</a
          >
        </div>
      </div>
      <div class="faster">
        <div class="title">
          <a href="http://www.99qibang.com/aboutUs.html" target="_blank">
            <img src="@/assets/taxPlan_img/faster.png" alt="" />
            <p>我们的优势?</p>
          </a>
        </div>
        <ul>
          <li>
            <img src="@/assets/taxPlan_img/vast.png" alt="" />
            <p>市场前景广阔</p>
          </li>
          <li>
            <img src="@/assets/taxPlan_img/more.png" alt="" />
            <p>产品多种多样</p>
          </li>
          <li>
            <img src="@/assets/taxPlan_img/support.png" alt="" />
            <p>提供品牌支持</p>
          </li>
          <li>
            <img src="@/assets/taxPlan_img/vast.png" alt="" />
            <p>政策扶持</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TaxPlann",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wrap {
  margin-top: 60px;
}

.wrap a {
  text-decoration: none;
}

.wrap .puzz-content,
.question-content,
.difference,
.light-content,
.faster {
  width: 1168px;
  margin: 0 auto;
  padding-bottom: 120px;
}

.wrap .top img {
  width: 100%;
  height: auto;
}

.wrap .title {
  font-size: 18px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  padding: 80px 0;
}

.wrap .title a {
  color: #333;
  display: flex;
  align-items: center;
  width: 500px;
  cursor: pointer;
}

.wrap .title img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
.wrap .title a:hover {
  color: rgba(51, 51, 51, 1);
}
.wrap .puzzle {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: end; */
  margin: 0 0 40px 100px;
}

.wrap .puzzle img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.wrap .puzzle li {
  width: 50%;
  margin-bottom: 80px;
}

.wrap .puzzle p {
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  line-height: 24px;
}

.wrap .puzzle .explain,
.title .explain {
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  margin-top: 10px;
}

.wrap .button {
  display: block;
  width: 535px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 30px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  margin: 0 auto;
  background: url("../../assets/taxPlan_img/btn.png") center center no-repeat;
}

.wrap .button:hover {
  background: url("../../assets/taxPlan_img/btn-act.png") center center
    no-repeat;
  color: #fff;
}

.wrap .question {
  width: 100%;
  background: #f4f0ff;
}

.question-content .img {
  width: 1135px;
  height: auto;
  margin: 0 0 68px 33px;
}

.difference {
  text-align: center;
  margin: 0 auto;
}

.difference .img {
  width: 774px;
  height: auto;
}

.light {
  width: 100%;
  background: #f4f0ff;
}

.faster ul {
  display: flex;
  padding-left: 30px;
}

.faster ul li {
  flex: 1;
  text-align: center;
}

.faster ul img {
  width: 80px;
  height: 80px;
}

.faster ul p {
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  line-height: 24px;
  margin-top: 19px;
}

.light ul a {
  display: block;
}

.rectangle {
  width: 15px;
  height: 12px;
  margin: 0 20px 0 40px;
}

.reply {
  display: flex;
  flex-wrap: wrap;
}

.reply li {
  cursor: pointer;
  margin-left: 40px;
  position: relative;
  width: 540px;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 70px;
  padding: 40px 0 63px;
}

.reply li a {
  position: absolute;
  left: 40px;
  top: -22px;
  display: flex;
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #000;
  line-height: 24px;
}

.reply li > p {
  margin-top: 40px;
  font-size: 20px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #000;
}

.reply li a p {
  margin-top: 40px;
}

.reply li span {
  display: inline-block;
  line-height: 28px;
}

.reply a img {
  width: 62px;
  height: 62px;
  margin-right: 10px;
}

.reply li:hover {
  margin-top: -12px;
  box-shadow: 0 4px 8px rgba(118, 106, 152, 0.5);
}
</style>
