// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/ChannelJoin_img/title3_img2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#ChanneJoin[data-v-6a002c2c]{text-align:center}.channel_content[data-v-6a002c2c]{margin-top:30px}.flex[data-v-6a002c2c]{display:flex;justify-content:center;align-items:center;margin-top:60px}.title3_img4_5[data-v-6a002c2c]{flex:7;text-align:left;padding:86px 0 83px 80px}.title6[data-v-6a002c2c]{margin-top:175px;padding-top:142px;padding-bottom:120px}.join_item[data-v-6a002c2c]{margin-bottom:30px}.join_text[data-v-6a002c2c]{font-size:18px;color:#5b5e66}.join_text input[data-v-6a002c2c]{margin-left:10px;width:263px;height:48px;line-height:48px;padding-left:26px;border:1px solid #e5e5e5;background-color:#fff;border-radius:24px;outline:none;font-size:14px;color:#333}.icon[data-v-6a002c2c]{color:red}.summit[data-v-6a002c2c]{margin-top:13px;display:flex;align-content:center}.btn[data-v-6a002c2c]{text-align:center;width:230px;height:48px;line-height:48px;border-radius:24px;background-color:#5b5e66;color:#fff;font-size:16px;cursor:pointer;margin-left:20%}.btn[data-v-6a002c2c]:hover,.btns[data-v-6a002c2c]{background-color:#3d93f9}.title3_img2[data-v-6a002c2c]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:650px;background-repeat:no-repeat;background-size:cover}", ""]);
// Exports
module.exports = exports;
