<template>
  <div id="Spiritwork">
    <div class="wrap">
      <div class="top">
        <img src="@/assets/spiritwork_img/banner.png" alt="" />
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
          
          >立即咨询</a
        >
      </div>
      <div class="WorkTitle">99灵工解决企业什么痛点</div>
      <div class="painSpot">
        <img src="@/assets/spiritwork_img/1.png" alt="" />
        <img src="@/assets/spiritwork_img/5.png" alt="" />
        <img src="@/assets/spiritwork_img/3.png" alt="" />
        <img src="@/assets/spiritwork_img/4.png" alt="" />
        <img src="@/assets/spiritwork_img/2.png" alt="" />
        <img src="@/assets/spiritwork_img/6.png" alt="" />
      </div>
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
       
        class="button"
        >咨询解决方案</a
      >
      <div class="WorkTitle value">99灵工为企业带来的价值</div>
      <img src="@/assets/spiritwork_img/value.png" class="valueImg" alt="" />
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
       
        class="button"
        >99灵工为您在线咨询指导</a
      >
      <div class="flow">
        <div class="flowTit">99灵工服务流程</div>
        <!-- <img src="@/assets/spiritwork_img/flow.png" alt=""> -->
        <ul>
          <li style="margin-right: 80px">
            <img src="@/assets/spiritwork_img/1flow.png" class="img" alt="" />
            <p>注册签约</p>
            <span>企业和个人分别与99灵工</span>
            <span>平台签订合作协议</span>
            <img src="@/assets/spiritwork_img/line.png" class="line" alt="" />
          </li>
          <li style="margin-right: 80px">
            <img src="@/assets/spiritwork_img/2flow.png" class="img" alt="" />
            <p>任务发布/领取</p>
            <span>企业和个人分别在99灵工</span>
            <span>平台发布和领取任务</span>
            <img src="@/assets/spiritwork_img/line.png" class="line" alt="" />
          </li>
          <li style="margin-right: 80px">
            <img src="@/assets/spiritwork_img/3flow.png" class="img" alt="" />
            <p>佣金结算</p>
            <span>企业将费用通过99灵工结</span>
            <span>算支付给个人99灵工平</span>
            <span>台收取相应服务费</span>
            <img src="@/assets/spiritwork_img/line.png" class="line" alt="" />
          </li>
          <li>
            <img src="@/assets/spiritwork_img/4flow.png" class="img" alt="" />
            <p>开票报税</p>
            <span>99灵工平台为企业开</span>
            <span>具全额增值税专票并将代</span>
            <span>征个税申报税务局开</span>
            <span>具相应完税证明</span>
          </li>
        </ul>
      </div>
      <div class="WorkTitle">99灵工案例分析</div>
      <ul class="analyze wow animated">
        <li class="wow animated li_1">
          <div class="analyze-foot">直播平台面临的问题</div>
          <div class="analyze-item">
            <div v-show="li_1_con">
              <p class="analyze-item-tit">直播平台面临的问题</p>
              <p class="analyze-contnet">
                部分主播非经纪公司签 约主播，直播收入存在公转
                私而无法获取发票的情况
              </p>
              <button @click="li_1_btn()">
                99灵工方案<img src="@/assets/spiritwork_img/right.png" alt="" />
              </button>
            </div>

            <div v-show="li_1_con == false">
              <span
                >通过99灵工平台，可实现企业获得发票降低运营风险，主播通过合理税收筹划，增加收入，实现多赢</span
              >
              <a
                 target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
                
                class="tosolve"
                >去解决<img src="@/assets/spiritwork_img/right.png" alt=""
              /></a>
            </div>
          </div>
        </li>
        <li class="wow animated li_2">
          <div class="analyze-foot">电商/微商行业面临的问题</div>
          <div class="analyze-item">
            <div v-show="li_2_con">
              <p class="analyze-item-tit">电商/微商行业面临的问题</p>
              <p class="analyze-contnet">
                《电商法》颁布后，个人佣金结算无法提供合规发票入账，财务合规风险高。
              </p>
              <button @click="li_2_btn()">
                99灵工方案<img src="@/assets/spiritwork_img/right.png" alt="" />
              </button>
            </div>

            <div v-show="li_2_con == false">
              <span
                >设立主体企业，法律主体合规化，合理取得发票，完成入账，财务合规化。</span
              >
              <a
                 target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
                
                class="tosolve"
                >去解决<img src="@/assets/spiritwork_img/right.png" alt=""
              /></a>
            </div>
          </div>
        </li>
        <li class="wow animated li_3">
          <div class="analyze-foot">教育行业面临的问题</div>
          <div class="analyze-item">
            <div v-show="li_3_con">
              <p class="analyze-item-tit">教育行业面临的问题</p>
              <p class="analyze-contnet">
                外聘教师的报酬结算,支出合理但无法获得发票,渠道招生的返佣结算也存在合规隐患
              </p>
              <button @click="li_3_btn()">
                99灵工方案<img src="@/assets/spiritwork_img/right.png" alt="" />
              </button>
            </div>

            <div v-show="li_3_con == false">
              <span
                >通过99灵工，将临时需要的员工通过劳务派遣外包到企业，可获得全额增值税发票，全程安全无隐患。</span
              >
              <a
                 target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
            
                class="tosolve"
                >去解决<img src="@/assets/spiritwork_img/right.png" alt=""
              /></a>
            </div>
          </div>
        </li>
        <li class="wow animated li_4">
          <div class="analyze-foot">直销经纪行业面临的问题</div>
          <div class="analyze-item">
            <div v-show="li_4_con">
              <p class="analyze-item-tit">直销经纪行业面临的问题</p>
              <p class="analyze-contnet">
                企业未确认收入，没有按照规定纳税，个人供货商，生产商无发票或未按照规定完税
              </p>
              <button @click="li_4_btn()">
                99灵工方案<img src="@/assets/spiritwork_img/right.png" alt="" />
              </button>
            </div>

            <div v-show="li_4_con == false">
              <span
                >通过99灵工平台，享受税收优惠政策，用人成本降低，提供完税证明，降低企业管理成本。</span
              >
              <a
                 target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
                
                class="tosolve"
                >去解决<img src="@/assets/spiritwork_img/right.png" alt=""
              /></a>
            </div>
          </div>
        </li>
      </ul>
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=pc99linggong"
        
        class="button free"
        >免费定制灵活用工方案</a
      >
      <div class="WorkTitle">99灵工服务优势</div>
      <ul class="advantage wow animated">
        <li class="wow animated li_5">
          <div class="advantage-item">
            <div class="advantage-tit">专业服务支持业务落地</div>
            <p>
              服务团队专业有保障适应多元 化需求，持续研究行业典型模
              式提供专业行业解决方案探索 新合作模式适应政策变化
            </p>
          </div>
          <div class="advantage-foot">专业服务支持业务落地</div>
        </li>
        <li class="wow animated li_6">
          <div class="advantage-item">
            <div class="advantage-tit">资质完备合规性强</div>
            <p>
              服务团队专业有保障适应多元 化需求，持续研究行业典型模
              式提供专业行业解决方案探索 新合作模式适应政策变化
            </p>
          </div>
          <div class="advantage-foot">资质完备合规性强</div>
        </li>
        <li class="wow animated li_7">
          <div class="advantage-item">
            <div class="advantage-tit">安全便捷的交付保障</div>
            <p>
              服务团队专业有保障适应多元 化需求，持续研究行业典型模
              式提供专业行业解决方案探索 新合作模式适应政策变化
            </p>
          </div>
          <div class="advantage-foot">安全便捷的交付保障</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "Spiritwork",
  data() {
    return {
      li_1_con: true,
      li_2_con: true,
      li_3_con: true,
      li_4_con: true,
    };
  },
  created() {},
  mounted() {
    this.animate();
    this.advantage();
  },
  methods: {
    // 99灵工案例分析_1 点击
    li_1_btn() {
      this.li_1_con = false;
    },
    // 99灵工案例分析_2 点击
    li_2_btn() {
      this.li_2_con = false;
    },
    // 99灵工案例分析_3 点击
    li_3_btn() {
      this.li_3_con = false;
    },
    // 99灵工案例分析_4 点击
    li_4_btn() {
      this.li_4_con = false;
    },
    // 鼠标悬浮99灵工案例分析
    animate() {
      var that = this;
      var hostdiv = $(".animated li");
      hostdiv.each(function () {
        $(this).hover(
          function () {
            $(this).find(".analyze-item").animate(
              {
                top: "0px",
              },
              200
            );
            $(this).find(".analyze-foot").css("display", "none");
            that.li_1_con = true;
            that.li_2_con = true;
            that.li_3_con = true;
            that.li_4_con = true;
          },
          function () {
            var hosth1 = $(this).height();
            hosth1 = hosth1 + "px";
            $(this).find(".analyze-item").show();
            if (!$(this).find(".analyze-item").is(":animated")) {
              $(this).find(".analyze-item").animate(
                {
                  top: hosth1,
                },
                200
              );
              $(this).find(".analyze-foot").css("display", "block");
            } else {
              $(this).find(".analyze-item").stop();
              $(this).find(".analyze-item").animate(
                {
                  top: hosth1,
                },
                200
              );
              $(this).find(".analyze-foot").css("display", "block");
            }
          }
        );
      });
    },
    // 鼠标悬浮 服务优势
    advantage() {
      var hostdiv = $(".advantage li");
      hostdiv.each(function () {
        $(this).hover(
          function () {
            $(this).find(".advantage-item").animate(
              {
                top: "0px",
              },
              200
            );
            $(this).find(".advantage-foot").css("display", "none");
          },
          function () {
            var hosth1 = $(this).height();
            hosth1 = hosth1 + "px";
            $(this).find(".advantage-item").show();
            if (!$(this).find(".advantage-item").is(":animated")) {
              $(this).find(".advantage-item").animate(
                {
                  top: hosth1,
                },
                200
              );
              $(this).find(".advantage-foot").css("display", "block");
            } else {
              $(this).find(".advantage-item").stop();
              $(this).find(".advantage-item").animate(
                {
                  top: hosth1,
                },
                200
              );
              $(this).find(".advantage-foot").css("display", "block");
            }
          }
        );
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.li_1 {
  background-image: url("../../assets/spiritwork_img/question1.png");
}
.li_2 {
  background-image: url("../../assets/spiritwork_img/question2.png");
}
.li_3 {
  background-image: url("../../assets/spiritwork_img/question3.png");
}
.li_4 {
  background-image: url("../../assets/spiritwork_img/question4.png");
}
.li_5 {
  background-image: url("../../assets/spiritwork_img/advantage1.png");
}
.li_6 {
  background-image: url("../../assets/spiritwork_img/advantage2.png");
}
.li_7 {
  background-image: url("../../assets/spiritwork_img/advantage3.png");
}

.wrap {
  margin-top: 80px;
}

.wrap a {
  text-decoration: none;
}

.wrap a:hover {
  color: #fff;
}

.wrap a ::selection,
.wrap a ::-webkit-selection,
.wrap a ::-moz-selection {
  color: #fff;
}

.wrap .top a:hover {
  color: #5a94f3;
}

.top {
  position: relative;
}

.top img {
  width: 100%;
  height: auto;
}

.top a {
  display: block;
  width: 276px;
  height: 84px;
  font-size: 42px;
  line-height: 84px;
  border: 2px solid #5a94f3;
  color: #5a94f3;
  position: absolute;
  left: 50%;
  margin-left: -139px;
  bottom: 15px;
  text-align: center;
  border-radius: 80px;
}

.WorkTitle {
  margin-top: 30px;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
}

.painSpot {
  display: flex;
  width: 1198px;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 53px;
  margin-bottom: 20px;
}

.painSpot img {
  width: 386px;
  height: 386px;
  margin-bottom: 10px;
}

.button {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 500px;
  height: 80px;
  background: rgba(90, 149, 243, 1);
  border-radius: 40px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 80px;
}

.wrap button img,
.tosolve img {
  display: inline-block;
  width: 10px;
  height: 16px;
  margin-left: 5px;
  margin-top: -4px;
}

.value {
  margin: 60px 0 50px;
}

.valueImg {
  width: 1200px;
  height: auto;
  text-align: center;
  margin: 0 auto 20px;
  display: block;
}

.flow {
  width: 100%;
  height: 1000px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 80px;
  background: url("../../assets/spiritwork_img/bg.png") center center no-repeat;
}

.flowTit {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  padding-top: 68px;
  text-align: center;
}

.flow .img {
  width: 125px;
  height: auto;
  margin-bottom: 27px;
  z-index: 9;
  position: relative;
}

.flow ul {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 213px;
}

.flow ul li {
  position: relative;
  text-align: center;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.flow ul li span {
  display: block;
  font-size: 24px;
  font-weight: normal;
}

.flow .line {
  position: absolute;
  left: 50%;
  top: 176px;
  height: 4px;
  z-index: 0;
  width: 130%;
}

.analyze,
.advantage {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  margin-top: 55px;
}

.analyze {
  margin-bottom: 20px;
}

.advantage {
  margin-bottom: 50px;
}

.analyze li,
.advantage li {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.analyze li {
  width: 284px;
  height: 380px;
}

.advantage li {
  width: 368px;
  height: 386px;
}

.analyze li .analyze-foot,
.advantage li .advantage-foot {
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  opacity: 0.7;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.analyze li .analyze-foot {
  bottom: 12px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}

.advantage li .advantage-foot {
  bottom: 20px;
  height: 58px;
  line-height: 58px;
  font-size: 24px;
}

.analyze-item-tit,
.advantage-item-tit {
  line-height: 0;
}

.analyze-item-tit {
  line-height: 0;
  margin-bottom: 35px;
}

.advantage-item-tit {
  margin-bottom: 50px;
}

.analyze-item {
  top: 380px;
  left: 12px;
  position: absolute;
  text-align: center;
  width: 260px;
  margin: 0 auto;
  padding-top: 114px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 36px;
}

.advantage-item {
  top: 386px;
  left: 45px;
  position: absolute;
  text-align: center;
  width: 260px;
  margin: 0 auto;
  padding-top: 62px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 55px;
}

.analyze-item button,
.tosolve {
  text-align: center;
  width: 144px;
  height: 31px;
  line-height: 31px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  margin-top: 64px;
}

.analyze-scheme {
  display: none;
}

.analyze-contnet {
  text-indent: 30px;
}

.free {
  height: 100px;
  line-height: 100px;
  margin-bottom: 60px;
}

.tosolve {
  display: block;
  margin: 64px auto 0;
}
</style>
