<template>
  <div id="aboutus">
    <!-- 关于99企帮 -->
    <div class="aboutus_content" style="margin-top: 30px">
      <div>
        <img src="@/assets/aboutus_img/aboutUs/title.png" alt="" />
      </div>
      <div style="margin-top: 74px">
        <img src="@/assets/aboutus_img/aboutUs/about_qb.png" alt="" />
      </div>
    </div>
    <!-- 融资情况 -->
    <div class="aboutus_content">
      <div>
        <img src="@/assets/aboutus_img/aboutUs/title2.png" alt="" />
      </div>
      <div class="flex" style="margin-top: 60px">
        <div style="flex: 1"></div>
        <div class="flex">
          <span>
            <img src="@/assets/aboutus_img/aboutUs/plumBlossom.png" alt="" />
          </span>
          <span style="margin-left: 63px">
            <img src="@/assets/aboutus_img/aboutUs/rz_content.png" alt="" />
          </span>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
    <!-- 99企帮资质 -->
    <div class="aboutus_content">
      <div>
        <img src="@/assets/aboutus_img/aboutUs/title3.png" alt="" />
      </div>
      <div style="margin-top: 30px">
        <img src="@/assets/aboutus_img/aboutUs/zz_content.png" alt="" />
      </div>
      <div class="flex" style="margin-top: 60px">
        <!-- <div style="flex: 1"></div> -->
        <div style="width: 1231px" class="flex">
          <div v-for="(item, index) in qbzzList" :key="index">
            <img :src="item.src" alt="" style="margin: 0 27px 60px 0" />
          </div>
        </div>
        <!-- <div style="flex: 1"></div> -->
      </div>
    </div>
    <!-- '99企帮'获得各界认可 -->
    <div class="aboutus_content">
      <div>
        <img src="@/assets/aboutus_img/aboutUs/title4.png" alt="" />
      </div>
      <div class="flex" style="margin-top: 60px">
        <!-- <div style="flex: 1"></div> -->
        <div class="flex" style="width: 1231px; align-items: flex-end">
          <div v-for="(item, index) in qbrkList" :key="index">
            <img :src="item.src" alt="" style="margin: 0 27px 60px 0" />
          </div>
        </div>
        <!-- <div style="flex: 1"></div> -->
      </div>
    </div>
    <!-- 企业价值观 -->
    <div class="aboutus_content" style="padding-bottom: 200px">
      <div>
        <img src="@/assets/aboutus_img/aboutUs/title5.png" alt="" />
      </div>
      <div class="flex" style="margin-top: 60px">
        <!-- <div style="flex: 1"></div> -->
        <div
          class="flex"
          style="flex-wrap: nowrap; width: 1231px; align-items: flex-end"
        >
          <div v-for="(item, index) in qbjzList" :key="index">
            <img :src="item.src" alt="" style="margin: 0 55px 0px 0" />
          </div>
        </div>
        <!-- <div style="flex: 1"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Aboutus",
  data() {
    return {
      qbzzList: [
        // 企帮资质 数据
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img1.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img2.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img3.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img4.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img5.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img6.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img7.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/zz_img8.png"),
        },
      ],
      qbrkList: [
        // 企帮 获取各界认可 数据
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img1.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img2.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img3.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img4.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img5.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img6.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img7.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img8.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img9.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img10.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img11.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/rk_img12.png"),
        },
      ],
      qbjzList: [
        // 企业价值观
        {
          src: require("@/assets/aboutus_img/aboutUs/jz_img1.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/jz_img2.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/jz_img3.png"),
        },
        {
          src: require("@/assets/aboutus_img/aboutUs/jz_img4.png"),
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.aboutus_content {
  text-align: center;
  margin-top: 200px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>
