<template>
  <div>
    <div :class="footbg.picture ? 'footer' : 'foot'">
      <div class="foot_content">
        <div class="foot_top">
          <div class="foot_left">
            <img src="@/assets/img/footlogo.png" alt="" />
          </div>
          <div>
            <div class="foot_right">
              <div class="tabground_item">
                <div v-for="item in foot_right1" :key="item.id">
                  <div class="tabnav">
                    <a :href="item.src">{{ item.name }}</a>
                    <!-- <router-link :to="item.src">{{ item.name }}</router-link> -->
                  </div>
                </div>
              </div>
              <div class="tabground_item">
                <div v-for="item in foot_right2" :key="item.id">
                  <div class="tabnav">
                    <a :href="item.src">{{ item.name }}</a>
                    <!-- <router-link :to="item.src">{{ item.name }}</router-link> -->
                  </div>
                </div>
              </div>
              <div class="tabground_item">
                <div v-for="(item, index) in foot_right3" :key="item.id">
                  <div
                    class="tabnav"
                    :class="{ tabnavitem: index == 1 || index == 2 }"
                  >
                    <a :href="item.src">{{ item.name }}</a>
                    <!-- <router-link :to="item.src">{{ item.name }}</router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="foot_center">
          <div class="foot_left_1">
            <div class="titleuse">联系我们</div>
            <div class="useritem">
              总部地址：&nbsp;北京市朝阳区光华路7号汉威大厦东塔10层1017室
            </div>
            <div class="useritem" style="display: flex; justify-items: center">
              <div>分部地址：&nbsp;</div>
              <div>
                <div style="margin-bottom: 10px">燕郊开发区鼎盛置业7层701</div>
                <div style="margin-bottom: 10px">
                  天津市东丽区先锋路61号汇城科技大厦2003-2005
                </div>
              </div>
            </div>
            <div class="useritem">
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              箱：&nbsp;99qbfw@99qibang.com
            </div>
            <div class="useritem">监督投诉电话：13071158105</div>
            <div style="margin-top: 14px">
              <img src="@/assets/img/foot_phone.png" alt="" />
            </div>
            <div
              style="margin-top: 39px"
              v-if="$route.path == '/'"
              v-show="qblink"
            >
              <div style="font-size: 14px; color: #ffffff">友情链接：</div>
              <ul>
                <li
                  class="link-content"
                  v-for="(item, index) in qbLink"
                  :key="index"
                >
                  <a :href="item.url">{{ item.title }}</a>
                </li>
                <!-- <li><a href="http://www.zhenxing.com.cn/">无水乙醇</a></li>
                <li><a href="http://www.dgtek.com.cn/">400ge光模块</a></li>
                <li><a href="http://www.jsyhfz.com/">南京白蚁防治中心</a></li>
                <li><a href="http://www.nj-termite.com/">南京白蚁防治</a></li>
                <li><a href="http://www.hanyipo.com/">南京专利申请</a></li>
                <li><a href="http://www.synwd.com/">南京注册公司</a></li>
                <li><a href="http://www.hycs88.cn/">南京公司注册</a></li>
                <li><a href="http://www.haoyuansy.cn/">pvc颗粒</a></li>
                <li><a href="http://www.bzjiekegangqin.cn/">滨州琴行</a></li>
                <li><a href="http://www.qdhaihao.cn/">氧化蜡</a></li>
                <li><a href="http://www.gangzheng.cc/">撕碎机</a></li>
                <li><a href="http://www.juxinda.cn/">电炉加料车</a></li> -->
              </ul>
              <!-- <span style="font-size: 14px; color: #ffffff">友情链接: </span
              ><span style="font-size: 14px; color: #8b96ae; cursor: pointer"
                ><span>58注册</span> <span>58财税</span>
                <span>58律师</span></span
              > -->
            </div>
          </div>
          <div class="foot_right">
            <img class="erweima" src="@/assets/img/kfwx.png" alt="" />
            <img class="erweima" src="@/assets/img/wxnum.png" alt="" />
            <img class="erweima" src="@/assets/img/99youyuan.png" alt="" />
          </div>
        </div>
        <div class="foot_bottom"></div>
      </div>
      <div class="bottom">
        <div style="color: #fff">
          北京优企帮帮科技有限公司 版权所有的网站备案号
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="color: #fff"
            >京ICP备17057925号-1</a
          >
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <div class="sidebar">
      <div
        class="sidebar-box sidebar-first-box"
        @mouseover="sidebarPhoneModalMouseOver()"
        @mouseout="sidebarPhoneModalMouseOut()"
      >
        <div class="sidebar-content">
          <a href="javascript:void(0)" title="">
            <p>
              <img
                src="@/assets/img/icon_sidebar_phone.png"
                alt="优企帮帮电话"
                class="sidebar-img block auto"
              />
            </p>
            <span>电话</span>
          </a>
          <div class="sidebar-content-phone-modal" v-if="first">
            400-099-3699
          </div>
        </div>
      </div>
      <div class="sidebar-box sidebar-scheme" @click="showSidebarScheme()">
        <div class="sidebar-content">
          <a href="javascript:void(0);">
            <p>
              <img
                src="@/assets/img/icon_sidebar_scheme.png"
                alt="解决方案"
                class="sidebar-img block auto"
              />
            </p>
            <span>方案</span>
          </a>
          <div
            class="sidebar-content-scheme-modal"
            id="towaaaaa"
            v-if="two"
            style="display: none"
          >
            <div class="sidebar-content-scheme-modal-title">
              <span>专属行业解决方案</span>
              <img
                src="@/assets/img/icon_deep_close.png"
                alt="关闭"
                class="r pointer"
                @click="closeSidebarScheme()"
              />
            </div>
            <div class="sidebar-content-scheme-modal-input-box">
              <span>您的行业</span>
              <input
                type="text"
                placeholder="请输入您的行业"
                v-model="type"
                id="sidebar-trade"
              />
            </div>
            <div class="sidebar-content-scheme-modal-input-box">
              <span>联系人</span>
              <input
                type="text"
                placeholder="请输入联系人"
                v-model="name_1"
                id="sidebar-name"
              />
            </div>
            <div class="sidebar-content-scheme-modal-input-box">
              <span>联系电话</span>
              <input
                type="text"
                placeholder="请输入联系电话"
                v-model="tel_1"
                id="sidebar-phone"
              />
            </div>
            <div
              class="sidebar-content-scheme-modal-btn pointer"
              @click="sidebarSubmitScheme()"
            >
              立即获取解决方案
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-box">
        <div class="sidebar-content">
          <a
            id="LR_User_Icon0"
            target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=12449336&userId=24946238&siteToken=45c43fe45f4cd3f64da5cf2cf367ff0e&cp=www.99qibang.com&cr=99qibang.com&cw=sem"
          >
            <div class="talk"></div>
            <!-- <p>
              <img
                src="@/assets/img/icon_sidebar_msg.png"
                alt="留言"
                class="sidebar-img block auto"
              />
            </p> -->
            <span>留言</span>
          </a>
        </div>
      </div>
    </div>
    <!-- 底部留言框 -->
    <div class="footer-modal-msg">
      <div
        class="footer-modal-msg-container container display-flex v-center h-center"
      >
        <div class="footer-modal-msg-phone-box display-inline-flex">
          <img
            src="@/assets/img/footer_phone.png"
            alt="优企帮帮电话"
            class="footer-modal-msg-phone-box-tel-img inline-block"
          />
          <div class="inline-block">
            <p class="footer-modal-msg-phone-box-top-text">
              立即咨询财税优惠方案
            </p>
            <p class="footer-modal-msg-phone-box-bottom-text">400-099-3699</p>
          </div>
        </div>
        <input
          type="text"
          id="name"
          v-model="name"
          class="footer-modal-msg-input"
          placeholder="请输入您的姓名"
          autocomplete="off"
        />
        <input
          type="text"
          id="phone"
          v-model="tel"
          class="footer-modal-msg-input"
          placeholder="请输入您的手机号"
          autocomplete="off"
        />
        <div
          class="footer-modal-msg-btn pointer inline-block"
          @click="footerModalSubscribe()"
        >
          立即预约
        </div>
        <div
          class="footer-modal-msg-close-box inline-block pointer"
          @click="closeFooterModal()"
        ></div>
      </div>
      <div
        class="footer-modal-msg-show-arrow pointer"
        @click="showFooterModal()"
      >
        <img src="@/assets/img/footer_modal_arrow.png" alt="箭头" />
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="popup display-flex flex1 v-center h-center" v-show="modelist">
      <div class="popup-container" v-show="tanchuan">
        <div class="popup-close-box">
          <img
            src="@/assets/img/icon_close.png"
            alt="关闭"
            class="r block pointer"
            @click="closemode()"
          />
        </div>
        <img
          src="@/assets/img/logo_title.png"
          alt="优企帮帮"
          class="popup-logo block"
        />
        <!-- <p class="popup-content">
          税收奖励高达<span class="popup-red"
            >90%<span class="popup-red-plus"></span></span
          >&nbsp;&nbsp;&nbsp;综合税率低至<span class="popup-red">0.5%</span>
        </p> -->
        <p class="popup-content">现在咨询，财税师为您量身定制</p>
        <div class="popup-btn pointer" @click="gethangyecose()">
          立即获取财税方案
        </div>
      </div>
      <div class="sidebar-content-scheme-modals" v-show="coseFangan">
        <div class="sidebar-content-scheme-modals-title">
          <span>专属行业解决方案</span>
          <img
            src="@/assets/img/icon_deep_close.png"
            alt="关闭"
            class="pointer"
            style="width: 14px"
            @click="closeSidebarScheme_1()"
          />
        </div>
        <div class="sidebar-content-scheme-modals-input-box">
          <span>您的行业</span>
          <input
            v-model="type_2"
            type="text"
            placeholder="请输入您的行业"
            id="sidebar-trade-copy"
          />
        </div>
        <div class="sidebar-content-scheme-modals-input-box">
          <span>联系人</span>
          <input
            type="text"
            v-model="name_2"
            placeholder="请输入联系人"
            id="sidebar-name-copy"
          />
        </div>
        <div class="sidebar-content-scheme-modals-input-box">
          <span>联系电话</span>
          <input
            type="text"
            v-model="tel_2"
            placeholder="请输入联系电话"
            id="sidebar-phone-copy"
          />
        </div>
        <div
          class="sidebar-content-scheme-modals-btn pointer"
          @click="sidebarSubmitScheme_1()"
        >
          立即获取解决方案
        </div>
      </div>
    </div>
  </div>
</template>

<script
  language="javascript"
  src="http://dut.zoosnet.net/JS/LSJS.aspx?siteid=DUT35407628&lng=cn"
></script>

<script>
// @ is an alias to /src
import { postData } from "@/request/api";
import $ from "jquery";
import "@/assets/comment.css";
export default {
  name: "Foot",
  props: {
    footbg: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      qblink: false,
      tanchuan: false,
      modelist: false,
      coseFangan: false,
      first: false,
      two: true,
      name: "",
      tel: "",
      type: "",
      name_1: "",
      tel_1: "",
      type_2: "",
      name_2: "",
      tel_2: "",
      foot_right1: [
        {
          id: 1,
          name: "首页",
          src: "/view/index",
        },
        {
          id: 2,
          name: "关于我们",
          src: "/view/aboutUs",
        },
        {
          id: 3,
          name: "渠道加盟",
          src: "/view/channelJoin",
        },
      ],
      foot_right2: [
        {
          id: 4,
          name: "99优园",
          src: "/view/99youyuan",
        },
        {
          id: 5,
          name: "99税优",
          src: "/view/99shuiyou",
        },
        {
          id: 6,
          name: "99财税",
          src: "/view/99caishui",
        },
        {
          id: 7,
          name: "99灵工",
          src: "/view/99linggong",
        },
      ],
      foot_right3: [
        {
          id: 8,
          name: "财税咨询",
          src: "/view/taxation",
        },
        {
          id: 9,
          name: "财税政策",
          src: "/view/taxation",
        },
        {
          id: 10,
          name: "案例实务",
          src: "/view/taxation/case",
        },
      ],
      qbLink: [],
    };
  },
  created() {
    console.log();
    this.qbLink = this.$store.state.qblink;
    // this.getqblink();
  },
  mounted() {
    this.showMode();
    if (typeof LiveReceptionCode_isonline != "undefined") {
      if (LiveReceptionCode_isonline) {
        console.log(LiveReceptionCode_isonline);
        LR_GetObj("LR_User_Icon0").innerHTML =
          "<a " +
          LiveReceptionCode_BuildChatWin(
            "点击留言",
            "客服人员在线,欢迎点击咨询"
          ) +
          '><div class="talk"></div><span>留言</span></ a>';
      } else {
        LR_GetObj("LR_User_Icon0").innerHTML =
          "<a " +
          LiveReceptionCode_BuildChatWin(
            "点击留言",
            "客服人员不在线,请点击留言"
          ) +
          '><div class="talk"></div><span>留言</span></ a>';
      }
    }
  },
  methods: {
    // 获取外部链接
    async getqblink() {
      const qblink = await postData("qblink/qbLink/linkList").then((res) => {
        if (res.data === "success") {
          if (res.qbLink.length > 0) {
            this.qbLink = res.qbLink;
          }
        }
      });
    },
    // 立即预约
    footerModalSubscribe() {
      var reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/i;
      if (!this.name) {
        this.$alert("请填写您的姓名", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!this.tel) {
        this.$alert("请填写您的手机号", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!reg.test(this.tel)) {
        this.$alert("手机号格式错误", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        // $(".sidebar-content-calc-modal-farmer").show();
        var formDatas = new FormData();
        formDatas.append("name", this.name);
        formDatas.append("tel", this.tel);
        formDatas.append("guestType", 1);
        formDatas.append("position", "底部优惠方案预约");
        postData("/qbguest/qbGuest/saveGuest", formDatas).then((res) => {
          if (res.data == "success") {
            this.name = "";
            this.tel = "";
            if (res.remind) {
              this.$alert(res.remind, "提示", {
                confirmButtonText: "确定",
              });
            } else {
              this.$alert(
                "财税优惠方案明细将会发送至您的手机上 一旦发送失败，会有财税老师和您联系 请您注意查收",
                "提示",
                {
                  confirmButtonText: "确定",
                }
              );
            }
          } else {
            this.$alert(res.remind, "提示", {
              confirmButtonText: "预约失败，请稍后重试",
            });
          }
        });
      }
    },
    // 关闭底部留言框
    closeFooterModal() {
      $(".footer-modal-msg-container").hide();
      $(".footer-modal-msg").animate({
        width: "60px",
      });
      $(".footer-modal-msg-show-arrow").show();
    },
    // 打开底部留言框
    showFooterModal() {
      $(".footer-modal-msg-show-arrow").hide();
      $(".footer-modal-msg-container").show();
      $(".footer-modal-msg").animate({
        width: "100%",
      });
    },

    // 侧边栏事件
    // 鼠标悬停
    sidebarPhoneModalMouseOver() {
      // debugger
      this.first = true;
    },
    // 鼠标离开
    sidebarPhoneModalMouseOut() {
      this.first = false;
    },
    // 点击方案
    showSidebarScheme() {
      this.two = true;
      var showbox1 = document.getElementById("towaaaaa");
      showbox1.style.display = "block";
      console.log(showbox1, "111112222");
    },
    // 关闭点击方案
    closeSidebarScheme() {
      //  this.$nextTick(() => {
      var showbox2 = document.getElementById("towaaaaa");
      showbox2.style.display = "none";
      this.two = false;
      // $('#towaaaaa').toggle()
      console.log(showbox2, "111112222");

      //  })
    },
    // 立即获取解决方案
    sidebarSubmitScheme() {
      var reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/i;
      if (!this.type) {
        this.$alert("请填写您的行业", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!this.name_1) {
        this.$alert("请填写联系人", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!this.tel_1) {
        this.$alert("请填写联系电话", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!reg.test(this.tel_1)) {
        this.$alert("手机号格式错误", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        // $(".sidebar-content-calc-modal-farmer").show();
        var formDatas = new FormData();

        formDatas.append("industry", this.type);
        formDatas.append("name", this.name_1);
        formDatas.append("tel", this.tel_1);
        formDatas.append("guestType", 1);
        formDatas.append("position", "中间弹窗获取节税方案");
        postData("/qbguest/qbGuest/saveGuest", formDatas).then((res) => {
          if (res.data == "success") {
            if (res.remind) {
              this.$alert(res.remind, "提示", {
                confirmButtonText: "确定",
              });
            } else {
              this.two = false;
              this.name_1 = "";
              this.tel_1 = "";
              this.type = "";
              this.$alert(
                "财税优惠方案明细将会发送至您的手机上 一旦发送失败，会有财税老师和您联系 请您注意查收",
                "提示",
                {
                  confirmButtonText: "确定",
                }
              );
              var showbox = document.getElementById("towaaaaa");
              showbox.style.display = "none";
            }
          } else {
            this.two = false;
            this.name_1 = "";
            this.tel_1 = "";
            this.type = "";
            this.$alert(res.remind, "提示", {
              confirmButtonText: "预约失败，请稍后重试",
            });
            var showbox = document.getElementById("towaaaaa");
            showbox.style.display = "none";
          }
        });
      }
    },
    // 是否展示弹框
    showMode() {
      var that = this;
      if (!sessionStorage.getItem("_99QBPopupShow")) {
        var timer = setTimeout(function () {
          that.popupShow();
          clearTimeout(timer);
        }, 10 * 1000);
      } else if (
        sessionStorage.getItem("_99QBPopupShow") === "Y" &&
        sessionStorage.getItem("_99QBUserSessionStorage") > 0
      ) {
        that.popupInterval();
      }
    },
    popupInterval() {
      // 每 40S 记录值减一
      var that = this;
      var sessionNum = sessionStorage.getItem("_99QBUserSessionStorage"), // 返回 _99QBUserSessionStorage 对应的值
        isPopupShow = sessionStorage.getItem("_99QBPopupShow");
      var timer = setInterval(function () {
        if (sessionNum <= 0) {
          clearInterval(timer);
          return null;
        }
        if (isPopupShow === "Y") {
          sessionNum -= 1;
          sessionStorage.setItem("_99QBUserSessionStorage", sessionNum);
          that.popupShow();
          clearInterval(timer);
          return null;
        }
      }, 40 * 1000);
    },
    popupShow() {
      if (sessionStorage.getItem("_99QBPopupShow")) {
        sessionStorage.setItem("_99QBPopupShow", "Y"); // 是否已经显示过弹窗
        sessionStorage.setItem(
          "_99QBUserSessionStorage",
          sessionStorage.getItem("_99QBUserSessionStorage")
        );
      } else {
        sessionStorage.setItem("_99QBPopupShow", "N"); // 是否已经显示过弹窗
        sessionStorage.setItem("_99QBUserSessionStorage", 2);
      }

      // sessionStorage.setItem('_99QBPopupShow', 'Y');
      this.tanchuan = true;
      this.modelist = true;
      // $('.sidebar-content-scheme-modal.copy').hide();
    },
    // 点击关闭 中部弹框
    closemode() {
      this.tanchuan = false;
      this.modelist = false;
      sessionStorage.setItem("_99QBPopupShow", "Y");
      this.popupInterval();
    },
    // 点击弹框关闭行业
    closeSidebarScheme_1() {
      this.tanchuan = false;
      this.coseFangan = false;
      this.modelist = false;
      sessionStorage.setItem("_99QBPopupShow", "Y");
      this.popupInterval();
    },
    // 立即获取节税方案
    gethangyecose() {
      this.tanchuan = false;
      this.coseFangan = true;
    },
    //
    // 中部弹框 - 立即获取解决方案
    sidebarSubmitScheme_1() {
      var reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/i;
      if (!this.type_2) {
        this.$alert("请填写您的行业", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!this.name_2) {
        this.$alert("请填写您的姓名", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!this.tel_2) {
        this.$alert("请填写您的手机号", "提示", {
          confirmButtonText: "确定",
        });
      } else if (!reg.test(this.tel_2)) {
        this.$alert("手机号格式错误", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        // $(".sidebar-content-calc-modal-farmer").show();
        var formDatas = new FormData();
        formDatas.append("industry", this.type_2);
        formDatas.append("name", this.name_2);
        formDatas.append("tel", this.tel_2);
        formDatas.append("guestType", 1);
        formDatas.append("position", "中间弹窗获取节税方案");
        postData("/qbguest/qbGuest/saveGuest", formDatas).then((res) => {
          if (res.data == "success") {
            if (res.remind) {
              this.$alert(res.remind, "提示", {
                confirmButtonText: "确定",
              });
            } else {
              this.modelist = false;
              this.coseFangan = false;
              this.tanchuan = false;
              this.name_2 = "";
              this.tel_2 = "";
              this.type_2 = "";
              this.$alert(
                "财税优惠方案明细将会发送至您的手机上 一旦发送失败，会有财税老师和您联系 请您注意查收",
                "提示",
                {
                  confirmButtonText: "确定",
                }
              );
            }
          } else {
            this.modelist = false;
            this.coseFangan = false;
            this.tanchuan = false;
            this.name_2 = "";
            this.tel_2 = "";
            this.type_2 = "";
            this.$alert(res.remind, "提示", {
              confirmButtonText: "预约失败，请稍后重试",
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.foot {
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  background-color: #5b5e66;
}
.footer {
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  background-color: #cc272b;
}
.foot_content {
  border-bottom: 1px solid #9094a0;
}
.foot_top {
  display: flex;
  justify-content: center;
}
.foot_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabnav {
  margin-bottom: 18px;
  cursor: pointer;
}
.tabnav a {
  color: #ffffff;
  font-size: 18px;
}
.tabnav a:hover {
  color: #8b96ae;
}
.titleuse {
  font-size: 18px;
  color: #8b96ae;
  margin-bottom: 30px;
}
.useritem {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
}
.tabnavitem a {
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.tabnavitem:hover {
  color: #8b96a4;
}
.foot_left {
  margin-right: 215px;
}
.foot_left_1 {
  margin-right: 144px;
}
.foot_right {
  display: flex;
  justify-content: center;
}
.tabground_item {
  margin-right: 141px;
}
.erweima {
  margin-right: 110px;
}
.bottom {
  text-align: center;
  padding-top: 22px;
  padding-bottom: 44px;
}

/* 底部留言框 */
.footer-modal-msg {
  /* max-width: 1920px; */
  width: 100%;
  height: 120px;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
}
.footer-modal-msg-container {
  flex-direction: row;
  margin-top: 24px;
  position: relative;
}

.container {
  width: 1200px;
  margin: 0 auto;
}
.h-center {
  -webkit-box-pack: justify;
  align-items: center;
}
.v-center {
  -webkit-box-align: center;
  justify-content: center;
}
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-modal-msg-phone-box {
  margin-right: 124px;
  margin-top: 24px;
}
.footer-modal-msg-phone-box-tel-img {
  width: 72px;
  height: 72px;
  margin-right: 14px;
}

.display-inline-flex {
  display: inline-flex;
}
.inline-block {
  display: inline-block;
}
.footer-modal-msg-phone-box-top-text {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 36px;
}
.footer-modal-msg-phone-box-bottom-text {
  font-size: 26px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 26px;
}

.footer-modal-msg-input {
  outline: none;
  width: 234px;
  height: 34px;
  padding: 0 10px;
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-right: 20px;
  background: rgba(255, 255, 255, 1);
}
.footer-modal-msg-btn {
  text-align: center;
  line-height: 40px;
  width: 196px;
  height: 40px;
  background: rgba(226, 63, 45, 1);
  border-radius: 4px;
  margin-left: 40px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.footer-modal-msg-close-box {
  text-align: center;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: rgba(2, 2, 2, 0.7); */
  background: url("../../assets/img/cha.png") no-repeat;
  background-size: 20px 20px;
  position: absolute;
  top: -10px;
  right: 0;
  cursor: pointer;
}
.footer-modal-msg-show-arrow {
  text-align: center;
  padding-top: 35px;
}
.footer-modal-msg-show-arrow img {
  display: inline-block;
  width: 50px;
  height: 50px;
}
.pointer {
  cursor: pointer;
}
.sidebar-content-scheme-modals-title img {
  /* float: right; */
  width: 14px;
}

/* 中部弹框
 */
.popup {
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.r {
  float: right;
}
.flex1 {
  flex: 1;
}
.popup-container {
  width: 410px;
  height: 284px;
  padding: 15px 17px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
}
.popup-close-box {
  width: 100%;
  height: 24px;
  margin-bottom: 8px;
}
.popup-logo {
  margin: 0 auto 46px;
}
.popup-content {
  line-height: 24px;
  text-align: center;
  margin-bottom: 52px;
  color: #333;
  font-size: 16px;
}
.popup-red {
  color: #e23f2d;
  font-size: 24px;
}
.popup-red-plus {
  font-size: 28px;
}
.popup-btn {
  text-align: center;
  line-height: 40px;
  width: 196px;
  height: 40px;
  border-radius: 4px;
  margin: 0 auto;
  background-color: rgba(226, 63, 45, 1);
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.sidebar-content-scheme-modals {
  /* display: none; */
  width: 310px;
  height: 330px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #666;
  font-size: 13px;
  position: absolute;
  /* top: -50px; */
  /* right: 54px; */
  z-index: 9999;
}
.sidebar-content-scheme-modals-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* line-height: 40px; */
  /* width: 100%; */
  /* height: 40px; */
  padding: 10px 10px;
  background-color: #f2f2f2;
}
.sidebar-content-scheme-modals-input-box {
  line-height: 40px;
  width: 288px;
  height: 40px;
  padding-left: 10px;
  border-bottom: 1px solid #efefef;
  margin-top: 15px;
  margin-left: 10px;
}
.sidebar-content-scheme-modals-input-box input {
  display: inline-block;
  width: 160px;
  height: 25px;
  padding: 0 10px;
  border: none;
  outline: none;
}
.sidebar-content-scheme-modals-btn {
  text-align: center;
  line-height: 40px;
  width: 280px;
  height: 40px;
  border-radius: 4px;
  margin: 40px auto 0;
  background-color: #ff4546;
  color: #ffffff;
}
.link-content a {
  font-size: 14px;
  color: #8b96ae;
  cursor: pointer;
}
.el-message-box__wrapper {
  z-index: 99999999999 !important;
}
</style>
