<template>
  <div class="banner">
    <el-carousel trigger="click" :height="imgheight + 'px'">
      <el-carousel-item v-for="(item, index) in qbAdvertisements" :key="index">
        <h3 class="small">
          <!-- <div
            :style="{
              backgroundImage: 'url(' + item.picture + ')',
              width: '100%',
              height: '460px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          ></div> -->
          <div @click="bannerPush(item.url)">
            <img
              ref="qbAdvertisements"
              :src="item.picture"
              alt=""
              style="height: 100%"
            />
          </div>
        </h3>
      </el-carousel-item>
    </el-carousel>
    <a
      href="https://c.99qibang.cn/html/register.html"
      @mouseenter="onEnterTd"
      target="_blank"
    >
      <div class="tu1" style="">
        <img class="tu2" src="@/assets/User.png" alt="" style="" />
        <span>登录</span>
      </div></a
    >
    <a
      href="https://c.99qibang.cn/html/register.html"
      v-if="showDlben"
      @mouseleave="onLeaveTd"
      target="_blank"
    >
      <div class="tu1" style="">
        <img class="tu2" src="@/assets/Users.png" alt="" style="" />
        <span>登录</span>
      </div></a
    >
  </div>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";
export default {
  name: "Banner",
  props: {
    qbAdvertisements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      imgheight: "",
      showDlben: true,
    };
  },
  created() {
    // this.dataList = this.qbAdvertisements;
  },
  mounted() {
    if (this.qbAdvertisements.length <= 1) {
      $(".el-carousel--horizontal ul").hide();
      // this.options = "outside";
    }
    this.imgLoad();
    window.addEventListener("resize", () => {
      this.imgheight = (document.body.clientWidth * 23) / 96;
    });
  },
  methods: {
    onEnterTd() {
      this.showDlben = true;
    },
    //鼠标离开的事件。
    onLeaveTd() {
      this.showDlben = true;
    },
    // Dlbtn(){
    //   this.showDlben=true
    //   // window.location.href="https://c.99qibang.cn/html/register.html"
    //   setTimeout(()=>{
    //     this.showDlben=false
    //   },1500)
    // },
    imgLoad() {
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度
        this.imgheight = (document.body.clientWidth * 23) / 96;
        console.log(this.imgheight);
      });
    },
    bannerPush(url) {
      if (url) {
        if (url.substr(0, 7).toLowerCase() == "http://") {
          window.location.href = url;
        } else {
          window.location.href = "http://" + url;
        }
      }
    },
  },
};
</script>
<style scoped>
.banner {
  margin-top: 60px;
  /* height: 460px; */
}
.tu1 {
  width: 100px;
  height: 40px;
  position: absolute;
  right: 40px;
  top: 10px;
  z-index: 99999;
  border: solid 1px #cde1fa;
  border-radius: 6px;
  color: #cde1fa;
  text-align: center;
  line-height: 40px;
}
.tu2 {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-bottom: 5px;
  margin-right: 5px;
}
.small {
  width: 100%;
  height: 100%;
}
.small div {
  cursor: pointer;
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 1;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
