import Vue from "vue";
import Router from "vue-router";
import Index from "@/views/index";
import Youyuan from "@/views/99youyuan.vue";
import Shuiyou from "@/views/99shuiyou.vue";
import Caishui from "@/views/99caishui.vue";
import Linggong from "@/views/99linggong.vue";
import Aboutus from "@/views/aboutus.vue";
import Channejoin from "@/views/ChannelJoin.vue";
import Taxatoninor from "@/views/taxationInformation.vue";
import Taxacase from "@/views/taxation_case.vue";
import Program from "@/views/program.vue";
import Taxoptim from "@/views/taxOptimization.vue";
import Equity from "@/views/equity.vue";
import TaxPlan from "@/views/taxPlanning.vue";
import Planning from "@/views/planning.vue";
import Taxcut from "@/views/taxCut.vue";
import Costicket from "@/views/cosTicket.vue";
import Spiritwork from "@/views/spiritWork.vue";
import Serverdetail from "@/views/serviceDetail.vue";
import Garden from "@/views/gardentreasure.vue";
import Newsdetail from "@/views/newsDetail.vue";
import Casedetail from "@/views/casedetail.vue";
import Asingle from "@/views/aSingle.vue";
import Travel from "@/views/travel.vue";
import Taxserve from "@/views/taxation_service.vue";
import Bookkeeping from "@/views/Bookkeeping.vue";

Vue.use(Router);

export default function createRouter() {
  return new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
      // {
      //   path: "/",
      //   redirect: "/",
      // },
      {
        path: "/",
        name: "Index",
        component: Index,
      },
      {
        path: "/view/99youyuan",
        name: "YOUYUAN",
        component: Youyuan,
      },
      {
        path: "/view/99shuiyou",
        name: "SHUIYOU",
        component: Shuiyou,
      },
      {
        path: "/view/99caishui",
        name: "CAISHUI",
        component: Caishui,
      },
      {
        path: "/view/99linggong",
        name: "LINGGONG",
        component: Linggong,
      },
      {
        path: "/view/taxation",
        name: "TAXATONINOR",
        component: Taxatoninor,
      },
      {
        path: "/view/taxation/case",
        name: "TAXACASE",
        component: Taxacase,
      },
      {
        path: "/view/aboutUs",
        name: "ABOUTUS",
        component: Aboutus,
      },
      {
        path: "/view/channelJoin",
        name: "CHANNELJOIN",
        component: Channejoin,
      },
      {
        path: "/topicPage/program.html",
        name: "PROGRAM",
        component: Program,
      },
      {
        path: "/taxOptimization.html",
        name: "TAXOPTIM",
        component: Taxoptim,
      },
      {
        path: "/equity.html",
        name: "EQUITY",
        component: Equity,
      },
      {
        path: "/bookkeeping.html",
        name: "Bookkeeping",
        component: Bookkeeping,
      },
      {
        path: "/taxPlanning.html",
        name: "TAXPLAN",
        component: TaxPlan,
      },
      {
        path: "/Planning.html",
        name: "PLANNING",
        component: Planning,
      },
      {
        path: "/topicPage/taxCut.html",
        name: "TAXCUT",
        component: Taxcut,
      },
      {
        path: "/topicPage/cosTicket.html",
        name: "COSTICKET",
        component: Costicket,
      },
      {
        path: "/spiritWork.html",
        name: "SPIRITWORK",
        component: Spiritwork,
      },
      {
        path: "/serviceDetail",
        name: "SERVERDETAIL",
        component: Serverdetail,
      },
      {
        path: "/garden",
        name: "GARDEN",
        component: Garden,
      },
      {
        path: "/newsdetail",
        name: "NEWSDETAIL",
        component: Newsdetail,
      },
      {
        path: "/casedetail",
        name: "CASEDETAIL",
        component: Casedetail,
      },
      {
        path: "/aSingle.html",
        name: "ASINGLE",
        component: Asingle,
      },
      {
        path: "/travel.html",
        name: "TRAVEL",
        component: Travel,
      },
      {
        path: "/taxation_service.html",
        name: "TAXSERVE",
        component: Taxserve,
      },
      {
        path: "/programme",
        name: "Programme",
        component: () => import("../views/programme.vue"),
      },
      // {
      //   path: "*",
      //   redirect: "/index"
      // }
    ],
  });
}
// const routes = [
//   {
//     path: "/",
//     name: "Home",
//     component: Home,
//   },
//   {
//     path: "/about",
//     name: "About",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ "../views/About.vue"),
//   },
// ];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

// export default router;
