<template>
  <div class="index">
    <detail-nav-bar></detail-nav-bar>
    <banner :qbAdvertisements="qbAdvertisements"></banner>
    <index-page></index-page>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import { getData } from "@/request/api";
import { postData } from "@/request/api";
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import IndexPage from "@/views/viewpage/index.vue";
// import banner from "@/components/banner.js";

export default {
  async asyncData({ store }) {
    const qbAdvertisements_ = await getData(
      "/qbadvertisement/qbAdvertisement/indexPcPage",
      {
        positionType: "1",
        terminal: "PC官网",
      }
    );
    const qblink_ = await postData("qblink/qbLink/linkList");
    const qblink = qblink_.qbLink;
    const qbAdvertisements = qbAdvertisements_.qbAdvertisements;
    store.commit("qblink", qblink);
    store.commit("qbAdvertisements", qbAdvertisements);
    return;
  },
  metaInfo: {
    title:
      "税收优惠_核定征收_税收奖励_税务咨询_财税服务_财务外包_有限公司返还_财税咨询_账务梳理_高新认定",
    // "税收优惠_核定征收_税收奖励_税务咨询_财税服务_财务外包_灵活用工_99企帮_值得信赖的企业服务平台",
    meta: [
      {
        name: "keywords",
        content:
          "园区，政策补贴，房租补贴，医疗园区，物流园区，互联网园区，高科技园区，税收优惠，税收奖励，财税服务，财税顾问，账务梳理，账务梳理，高新技术企业认定，灵工用工，财务外包",
      },
      {
        name: "description",
        content:
          "优企帮帮集团(简称:99企帮）总部位于北京，拥有99优园、99税优、99财税、99灵工四大品牌，并提供园区招商、财税优化、财税顾问、财税资讯、灵活用工等相关服务",
      },
    ],
  },
  name: "Index",
  components: { DetailNavBar, Banner, Foot, IndexPage },
  data() {
    return {
      qbAdvertisements: [],
      footbg: {},
    };
  },

  created() {
    console.log(this.$store.state.qblink);
    this.qbAdvertisements = this.$store.state.qbAdvertisements;
    // this.qbLink = this.$store.state.qblink;

    // this.getdata(); // 获取轮播图banner
  },
  mounted() {
    console.log(this.qbLink);
  },
  methods: {
    getdata() {
      getData("/qbadvertisement/qbAdvertisement/indexPcPage", {
        positionType: "1",
        terminal: "PC官网",
      }).then((res) => {
        if (res.code == 1) {
          this.qbAdvertisements = res.qbAdvertisements;
          // window.sessionStorage.setItem("banner", JSON.stringify(res));
          // Vue.prototype.qbAdvertisements = res.qbAdvertisements; // 首页轮播图
          // console.log(Vue.prototype.qbAdvertisements);
        }
      });
    },
    // getqbAdvertisements() {
    //   this.qbAdvertisements = JSON.parse(
    //     sessionStorage.getItem("banner")
    //   ).qbAdvertisements;
    //   console.log(this.qbAdvertisements);
    // },
  },
};
</script>
