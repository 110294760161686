<template>
  <div class="aboutus">
    <detail-nav-bar></detail-nav-bar>
    <div class="top">
      <img src="@/assets/aboutus_img/about1.png" alt="" />
    </div>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <div class="aboutUs">
      <el-tabs v-model="aboutUs" stretch>
        <el-tab-pane label="关于我们" name="first">
          <aboutus></aboutus>
        </el-tab-pane>
        <el-tab-pane label="公司历程" name="second">
          <history></history>
        </el-tab-pane>
        <el-tab-pane label="战略合作" name="thired">
          <stratrgy></stratrgy>
        </el-tab-pane>
        <el-tab-pane label="联系我们" name="fourth">
          <contactus></contactus>
        </el-tab-pane>
        <el-tab-pane label="加入我们" name="fiveth">
          <joinus></joinus>
        </el-tab-pane>
      </el-tabs>
    </div>
    <foot :footbg="footbg"></foot>
  </div>
</template>
<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Aboutus from "@/views/viewpage/aboutUs/about_us.vue";
import History from "@/views/viewpage/aboutUs/enter_history.vue";
import Stratrgy from "@/views/viewpage/aboutUs/stratrgy.vue";
import Contactus from "@/views/viewpage/aboutUs/contactus.vue";
import Joinus from "@/views/viewpage/aboutUs/joinus.vue";
// import banner from "@/components/banner.js";

export default {
  metaInfo: {
    title: "关于我们_值得信赖的企业服务平台_99企帮",
    meta: [
      {
        name: "keywords",
        content:
          "财务，税务，税收优惠，税收奖励，财税服务，财税平台，账务梳理，代理记账，账务梳理，股权架构，高新技术，财务外包，99企帮",
      },
      {
        name: "description",
        content:
          "优企帮帮集团(简称:99企帮）总部位于北京。全国有12家直营分子公司，通过技术+大数据，信息+服务的立体化综合企业服务体系，为企业在财务、税务、法务、人力服务等各方面提供综合解决方案，并协助、协调企业进行全国范围内的方案落地。",
      },
    ],
  },
  name: "ABOUTUS",
  components: {
    DetailNavBar,
    // Banner,
    Foot,
    Aboutus,
    History,
    Stratrgy,
    Contactus,
    Joinus,
  },
  data() {
    return {
      aboutUs: "first",
      // qbAdvertisements: [
      //   { picture: require("@/assets/aboutus_img/banner.png") },
      // ],
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
<style>
.aboutus .top {
  margin-top: 60px;
}
.aboutus .top img {
  width: 100%;
  height: auto;
}
.aboutUs .el-tabs__item.is-active {
  color: #3d93f9 !important;
}
.aboutUs .el-tabs__active-bar {
  display: none !important;
}
.aboutUs .el-tabs__item {
  height: 60px !important;
  line-height: 60px !important;
  font-size: 17px !important;
  color: #5b5e66 !important;
}
.aboutUs .el-tabs__item:hover {
  color: #3d93f9 !important;
  cursor: pointer;
}
.aboutUs .el-tabs__nav-scroll {
  padding: 0 30% !important;
}
</style>
