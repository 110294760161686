<template>
  <div id="Costicket">
    <div class="top">
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo"
        
      >
        <img src="@/assets/costicket_img/banner.png" alt="" />
      </a>
    </div>
    <img src="@/assets/costicket_img/que.jpg" alt="" class="question" />
    <div>
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo"
        
        class="questionBtn"
        ><img src="@/assets/costicket_img/questionBtn.png" alt=""
      /></a>
    </div>

    <div class="business">
      <img
        src="@/assets/costicket_img/business.png"
        class="businessImg"
        alt=""
      />
      <div>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo"
         
          class="more"
          ><img src="@/assets/costicket_img/more.png" alt=""
        /></a>
      </div>
    </div>
    <img src="@/assets/costicket_img/safety.png" class="safety" alt="" />
    <img src="@/assets/costicket_img/success.png" class="success" alt="" />
  </div>
</template>
<script>
export default {
  name: "Costicket",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
#Costicket {
  text-align: center;
}
#Costicket {
  margin-top: 60px;
}

#Costicket .top img {
  width: 100%;
  height: auto;
}
.question {
  /* width: 1180px; */
  margin: 110px 0 22px;
}
a {
  cursor: pointer;
}
.business {
  margin-bottom: 110px;
  background: #f6f6f6;
  width: 100%;
  padding: 110px 0;
}
.safety {
  /* width: 1200px; */
  height: auto;
  margin-bottom: 110px;
}
#Costicket .success {
  width: 100%;
  height: auto;
}
</style>
