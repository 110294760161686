<template>
  <div id="joinus">
    <div class="joinus_content">
      <div class="flex">
        <div style="flex: 1"></div>
        <div class="flex">
          <div class="join_item_left">
            <div>
              <img src="@/assets/aboutus_img/joinus/left_img1.png" alt="" />
            </div>
            <div style="margin-top: 20px">
              <div class="join_text">有期权、双休,地铁周边</div>
            </div>
            <div style="margin-top: 34px">
              <img src="@/assets/aboutus_img/joinus/left_img2.png" alt="" />
            </div>
            <div style="margin-top: 20px">
              <div class="join_text">99qbfw@99qibang.com</div>
            </div>
          </div>
          <div style="margin-left: 54px; text-align: left">
            <div>
              <div>
                <img
                  src="@/assets/aboutus_img/joinus/right_title1.png"
                  alt=""
                />
              </div>
              <div style="margin: 65px 0 29px 0">
                <img
                  src="@/assets/aboutus_img/joinus/right_center1.png"
                  alt=""
                />
              </div>
              <div>
                <img src="@/assets/aboutus_img/joinus/right_img1.png" alt="" />
              </div>
              <div style="margin: 96px 0 29px 0">
                <img
                  src="@/assets/aboutus_img/joinus/right_center2.png"
                  alt=""
                />
              </div>
              <div>
                <img src="@/assets/aboutus_img/joinus/right_img2.png" alt="" />
              </div>
              <div style="margin: 58px 0 29px 0">
                <img
                  src="@/assets/aboutus_img/joinus/right_center3.png"
                  alt=""
                />
              </div>
              <div>
                <img src="@/assets/aboutus_img/joinus/right_img3.png" alt="" />
              </div>
            </div>
            <div style="margin-top: 198px">
              <div>
                <img
                  src="@/assets/aboutus_img/joinus/right_title2.png"
                  alt=""
                />
              </div>
              <div style="margin: 65px 0 29px 0">
                <img
                  src="@/assets/aboutus_img/joinus/right_center1.png"
                  alt=""
                />
              </div>
              <div>
                <img src="@/assets/aboutus_img/joinus/right_img4.png" alt="" />
              </div>
              <div style="margin: 96px 0 29px 0">
                <img
                  src="@/assets/aboutus_img/joinus/right_center2.png"
                  alt=""
                />
              </div>
              <div>
                <img src="@/assets/aboutus_img/joinus/right_img5.png" alt="" />
              </div>
              <div style="margin: 58px 0 29px 0">
                <img
                  src="@/assets/aboutus_img/joinus/right_center3.png"
                  alt=""
                />
              </div>
              <div>
                <img src="@/assets/aboutus_img/joinus/right_img6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Joinus",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.joinus_content {
  text-align: center;
  margin: 140px 0 200px 0;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.join_item_left {
  background-color: #ddd;
  text-align: center;
  padding: 40px 20px 0 20px;
  height: 287px;
}
.join_text {
  font-size: 20px;
  color: #fff;
  background-color: #3d93f9;
  border-radius: 45px;
  padding: 6px 13px 8px 13px;
}
</style>
