<template>
  <div>
    <div class="nav">
      <nav-bar class="detail-nav-bar">
        <div slot="left">
          <img src="@/assets/img/logo.png" alt="" />
        </div>
        <div slot="center" class="navlist">
          <el-menu
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            active-text-color="#3d93f9"
            text-color="#5b5e66"
          >
            <el-menu-item index="/"><a href="/"> 首页</a></el-menu-item>
            <el-menu-item index="/view/99youyuan"
              ><a href="/view/99youyuan">99优园</a></el-menu-item
            >
            <el-menu-item index="/view/99shuiyou"
              ><a href="/view/99shuiyou">99税优</a></el-menu-item
            >
            <el-menu-item index="/view/99caishui">
              <a href="/view/99caishui">99财税</a></el-menu-item
            >
            <el-menu-item index="/view/99linggong">
              <a href="/view/99linggong">99灵工</a></el-menu-item
            >
            <el-submenu index="/view/taxation">
              <template slot="title">财税资讯</template>
              <el-menu-item index="/view/taxation">
                <a href="/view/taxation" class="taxcase">政策解读</a>
              </el-menu-item>
              <el-menu-item index="/view/taxation/case">
                <a href="/view/taxation/case" class="taxcase">案例实务</a>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/view/aboutUs">
              <a href="/view/aboutUs">关于我们</a></el-menu-item
            >
            <el-menu-item index="/view/channelJoin">
              <a href="/view/channelJoin">渠道加盟</a></el-menu-item
            >
          </el-menu>
        </div>
        <div slot="right">
          <img src="@/assets/img/phone.png" alt="" />
        </div>
      </nav-bar>
    </div>
    <a
      href="https://c.99qibang.cn/html/register.html"
      @mouseenter="onEnterTd"
      target="_blank"
    >
      <div
        style="
          width: 113px;
          height: 40px;
          position: absolute;
          right: 40px;
          top: 17px;
          z-index: 99999;
          border: solid 1px #cde1fa;
          border-radius: 6px;
          color: #cde1fa;
          text-align: center;
          line-height: 40px;
        "
      >
        <img
          src="@/assets/User.png"
          alt=""
          style="
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-bottom: 5px;
            margin-right: 5px;
          "
        />
        <span>登录</span>
      </div></a
    >
    <a
      href="https://c.99qibang.cn/html/register.html"
      v-if="showDlben"
      @mouseleave="onLeaveTd"
      target="_blank"
    >
      <div
        style="
          width: 113px;
          height: 40px;
          position: absolute;
          right: 40px;
          top: 17px;
          z-index: 99999;
          background: #cde1fa;
          border: solid 1px #cde1fa;
          border-radius: 6px;
          color: #63a7f6;
          text-align: center;
          line-height: 40px;
        "
      >
        <img
          src="@/assets/Users.png"
          alt=""
          style="
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-bottom: 5px;
            margin-right: 5px;
          "
        />
        <span>登录</span>
      </div></a
    >
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/navBar/navBar.vue";
export default {
  name: "DetailNavBar",
  components: { NavBar },

  data() {
    return {
      // path: "",
      showDlben: false,
    };
  },
  created() {
    console.log(this.$store.state.route.path);
    // this.path = this.$store.state.route.path;
  },
  mounted() {
    console.log("测试999");
    console.log(window.location.href);
  },
  methods: {
    // 菜单激活回调
    handleSelect(index, indexPath) {
      console.log(index, indexPath);
      // this.activeIndex = indexPath[0];
    },
    onEnterTd() {
      this.showDlben = true;
    },
    //鼠标离开的事件。
    onLeaveTd() {
      this.showDlben = false;
    },
  },
};
</script>
<style>
.nav {
  width: 100%;
  height: 60px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  /* position: fixed;
  top: 0; */
}
.detail-nav-bar {
  margin: 0 auto;
}
.navlist {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */
.navlist .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
}

.navlist .el-submenu__title:hover,
.el-menu-item:focus,
.el-menu-item:hover {
  color: #3d93f9 !important;
}
.navlist .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}
.navlist .el-submenu__title i {
  display: none !important;
}
/* .navlist .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #5b5e66 !important;
} */
/* .navlist .el-menu--horizontal > .el-submenu.is-active .el-submenu__title a {
  text-decoration: none;
  color: #5b5e66 !important;
} */
.el-menu--horizontal ul li a {
  text-decoration: none;
  color: #5b5e66 !important;
}
.el-menu--horizontal ul li a:hover {
  color: #3d93f9 !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
/* .navlist
  .el-menu--horizontal
  > .el-submenu.is-active
  .el-submenu__title
  a:hover {
  color: #3d93f9 !important;
} */
</style>
