<template>
  <div class="head">
    <div class="nav-bar">
      <div class="left">
        <slot name="left"></slot>
      </div>
      <div class="center">
        <slot name="center"></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "NavBar",
};
</script>
<style scoped>
/* .head {
  position: fixed;
  top: 0;
} */
.nav-bar {
  margin: 0 auto;
  display: flex;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  padding-right: 15%;
}
.left {
  width: 160px;
}
.right {
  width: 185px;
}
.center {
  /* margin-left: 70px;
  margin-right: 55px; */
}
</style>
