<template>
  <div id="taxoptim">
    <div id="section" class="body-section">
      <div class="top">
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
        >
          <img src="@/assets/taxOptimization_img/1.png" alt="" />
        </a>
      </div>
      <!-- <a  target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com" target="_blank"><img
                    src="@/assets/taxOptimization_img/1.png" alt="工商服务" /></a> -->
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
        
        ><img src="@/assets/taxOptimization_img/3.jpg" alt="工商服务"
      /></a>
      <img src="@/assets/taxOptimization_img/4.jpg" alt="工商服务" />
      <img src="@/assets/taxOptimization_img/5.jpg" alt="工商服务" />
      <img src="@/assets/taxOptimization_img/6.jpg" alt="工商服务" />
      <img src="@/assets/taxOptimization_img/7.jpg" alt="工商服务" />
      <img src="@/assets/taxOptimization_img/8.jpg" alt="工商服务" />
      <img src="@/assets/taxOptimization_img/9.jpg" alt="工商服务" />
      <a
         target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
       
        ><img src="@/assets/taxOptimization_img/10.jpg" alt="工商服务"
      /></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Taxoptim",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
@media screen and (max-width: 980px) {
  .parent-container {
    width: 1200px;
  }
}
.body-section {
  margin-top: 60px;
}
.body-section .top img {
  width: 100%;
  height: auto;
}
.body-section img {
  width: 100%;
  height: 100%;
}
</style>
