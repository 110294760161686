import { get, post } from "@/request/axios";
export const getDatas = (url, params) =>
  // get(url, "https://xcxst.99youyuan.com", params); // 测试环境
  get(url, "https://xcxs.99youyuan.com", params); //线上环境
export const getData = (url, params) =>
  get(url, "http://q.99qibang.cn/qibangPc/a", params); //线上环境
// get(url, "http://q.99qibang.com.cn/qibangPc/a", params); // 测试环境
export const postData = (url, params) => post(url, params);
export const postDatas = (url, params) => post(url, "http://q.99qibang.cn/qibangPc/a", params);
// export const postformtData = (url, data) => postform(url, data);
