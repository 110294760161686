<template>
  <div id="caishui">
    <div class="top">
      <img src="@/assets/99caishui_img/caishui222.png" alt="" />
    </div>
    <!-- 服务产品 -->
    <div class="content" style="margin-top: 30px">
      <div>
        <img src="@/assets/99caishui_img/cs_title1.png" alt="" />
      </div>
      <div
        style="margin-top: 60px; display: flex; justify-content: space-between"
      >
        <!-- <span style="margin-right: 8px">
          <img src="@/assets/99caishui_img/cs_serverPro_item1.png" alt="" />
        </span>
        <span>
          <img src="@/assets/99caishui_img/cs_serverPro_item2.png" alt="" />
        </span> -->
        <div></div>
        <div>
          <div>
            <div>
              <img src="@/assets/99caishui_img/caishui1.png" alt="" />
            </div>
            <div style="background: white; padding-bottom: 10px">
              <div class="aa" style="">
                <div style="font-size: 20px; line-height: 70px">初级会员包</div>
                <div style="display: flex; color: red">
                  <div style="font-size: 39px">￥10000</div>
                  <div style="line-height: 70px">元/年</div>
                </div>
              </div>
              <!-- <div class="bb" style="">
              <div></div>
              <div class="cc" @click="kai" style="">下单</div>
            </div> -->
            </div>
            <div
              style="background: white; padding-bottom: 10px; margin-top: 10px"
            >
              <div class="aa" style="">
                <div style="font-size: 20px; line-height: 70px">中级会员包</div>
                <div style="display: flex; color: red">
                  <div style="font-size: 39px">￥30000</div>
                  <div style="line-height: 70px">元/年</div>
                </div>
              </div>
              <!-- <div class="bb" style="">
              <div></div>
              <div class="cc" @click="kai" style="">下单</div>
            </div> -->
            </div>
            <div
              style="background: white; padding-bottom: 10px; margin-top: 10px"
            >
              <div class="aa" style="">
                <div style="font-size: 20px; line-height: 70px">高级会员包</div>
                <div style="display: flex; color: red">
                  <div style="font-size: 39px">￥50000</div>
                  <div style="line-height: 70px">元/年</div>
                </div>
              </div>
              <!-- <div class="bb" style="">
              <div></div>
              <div class="cc" @click="kai" style="">下单</div>
            </div> -->
            </div>
          </div>

          <div style="margin-left: 10px">
            <div>
              <img src="@/assets/99caishui_img/caishui2.png" alt="" />
            </div>
            <div style="background: white; padding-bottom: 10px">
              <div class="aa" style="">
                <div style="font-size: 20px; line-height: 70px">
                  高级财税咨询
                </div>
                <div style="display: flex; color: red">
                  <div style="font-size: 39px">￥96000</div>
                  <div style="line-height: 70px">元/年</div>
                </div>
              </div>
              <!-- <div class="bb" style="">
              <div></div>
              <div class="cc" @click="kai" style="">下单</div>
            </div> -->
            </div>
            <div>
              <img src="@/assets/99caishui_img/caishui3.png" alt="" />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div id="zhezhao" @click="guan" style="">
      <div class="tanchaung">
        <div class="cha" @click="guan">x</div>
        <div>
          <img
            src="@/assets/99caishui_img/qrcode_99qibang.png"
            alt=""
            style="width: 400px"
            @click="buguan"
          />
        </div>
      </div>
    </div>
    <!-- 服务优势 -->
    <div class="content">
      <div>
        <img src="@/assets/99caishui_img/cs_title2.png" alt="" />
      </div>
      <div style="margin-top: 60px; display: flex">
        <div style="flex: 1"></div>
        <div v-for="(item, index) in serveGoodList" :key="index">
          <div style="margin-right: 125px">
            <div>
              <img :src="item.img1" alt="" />
            </div>
            <div style="margin-top: 50px">
              <img :src="item.img2" alt="" />
            </div>
          </div>
        </div>
        <div style="flex: 1; margin-left: -125px"></div>
      </div>
    </div>
    <!-- 财税咨询师 -->
    <!-- <div class="content">
      <div>
        <img src="@/assets/99caishui_img/cs_title3.png" alt="" />
      </div>
      <div style="margin-top: 60px">
        <img src="@/assets/99caishui_img/cs_teacher.png" alt="" />
      </div>
    </div> -->
    <!-- 案例或相关咨询 -->
    <div class="content">
      <div class="anli">
        <div>
          <img src="@/assets/99caishui_img/cs_title4.png" alt="" />
        </div>
        <div style="margin-top: 60px">
          <div style="display: flex; flex-wrap: wrap; justify-content: center">
            <div style="flex: 1"></div>
            <div style="flex: 8; display: flex; flex-wrap: wrap">
              <div v-for="(item, index) in anLiList" :key="index">
                <div class="anli_item" @click="pushcase(item.id)">
                  <div>
                    <div>{{ item.title1 }}</div>
                    <div>{{ item.title2 }}</div>
                  </div>

                  <img
                    src="@/assets/99caishui_img/Hot.png"
                    alt=""
                    v-show="item.hot"
                    class="hot_img"
                  />
                </div>
              </div>
            </div>

            <div style="flex: 1"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 月刊 -->
    <div class="content">
      <div>
        <img src="@/assets/99caishui_img/title5.png" alt="" />
      </div>
      <div style="margin-top: 60px; display: flex">
        <div style="flex: 1"></div>
        <div style="display: flex">
          <span style="margin-right: 104px">
            <img src="@/assets/99caishui_img/cs_yuekanLogo.png" alt="" />
          </span>
          <span class="yuekan" style="width: 797px">
            <el-tabs v-model="yuekan_content">
              <el-tab-pane label="2022年" name="first">
                <div style="display: flex; flex-wrap: wrap; margin-top: 40px">
                  <div v-for="(item, index) in yuekan_list" :key="index">
                    <div class="yuekan_item">
                      <a :href="item.enclosure">
                        <div>
                          {{ item.title }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="2021年" name="second">
                <div style="display: flex; flex-wrap: wrap; margin-top: 40px">
                  <div v-for="(item, index) in yuekan_list_1" :key="index">
                    <div class="yuekan_item">
                      <a :href="item.enclosure">
                        <div>
                          {{ item.title }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </span>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
    <!-- 政策解读 -->
    <div class="content">
      <div>
        <img src="@/assets/99caishui_img/title6.png" alt="" />
      </div>
      <div style="margin-top: 60px; display: flex; justify-content: center">
        <div style="flex: 1"></div>
        <div style="flex: 8; display: flex; justify-content: center">
          <span>
            <div v-for="(item, index) in qbNew1" :key="index">
              <div
                style="width: 560px; cursor: pointer"
                @click="policyDetail(item.id)"
              >
                <div style="width: 560px; height: 318px">
                  <img
                    :src="item.titlePic"
                    alt=""
                    style="width: 560px; height: 318px"
                  />
                </div>
                <div class="qbnwes_content">
                  <div style="font-size: 20px">{{ item.title }}</div>
                  <div
                    class="qbnew_items_1"
                    style="margin-top: 10px; font-size: 16px; color: #e4eaef"
                  >
                    {{ item.firstName }}
                  </div>
                  <div
                    style="margin-top: 23px; font-size: 14px; color: #e4eaef"
                  >
                    {{ item.releaseTime }}
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span style="margin-left: 40px">
            <div v-for="(item, index) in qbNew2" :key="index">
              <div @click="policyDetail(item.id)">
                <div class="qbnwes2_content" style="background-color: #fff">
                  <div
                    style="
                      font-size: 20px;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      overflow: hidden;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 1;
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="qbnew_items"
                    style="margin-top: 10px; font-size: 16px"
                  >
                    {{ item.firstName }}
                  </div>
                  <div style="margin-top: 23px; font-size: 14px">
                    {{ item.releaseTime }}
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div style="flex: 1"></div>
      </div>
      <div class="lookmore">
        <!-- <a href="/view/taxation"> -->
        <a href="/view/taxation">
          <div class="btn" :class="{ btns: more == true }">查看更多</div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/request/api";
import { getData } from "@/request/api";
export default {
  name: "Caishui",
  data() {
    return {
      more: false,
      isshow: false,
      yuekan_content: "first",
      serveGoodList: [
        // 服务优势 数据
        {
          img1: require("@/assets/99caishui_img/cs_book.png"),
          img2: require("@/assets/99caishui_img/cs_book_content.png"),
        },
        {
          img1: require("@/assets/99caishui_img/line_zixun.png"),
          img2: require("@/assets/99caishui_img/line_zixun_content.png"),
        },
        {
          img1: require("@/assets/99caishui_img/px_fn.png"),
          img2: require("@/assets/99caishui_img/px_fn_content.png"),
        },
      ],
      anLiList: [],
      yuekan_list_1: [],
      yuekan_list: [],
      qbNew1: [], //政策解读 左侧数据
      qbNew2: [], //政策解读 右侧数据
      pageSize: 10,
      pageNo: 1,
      original: 3,
      positionTyps: 1,
    };
  },
  created() {
    // this.getdata(); // 获取 政策解读数据
  },
  mounted() {
    this.getcase(); // 获取 案例实务数据
    this.getdata(); // 获取 政策解读数据
    this.getmoneyList(); //获取月刊数据
  },
  methods: {
    // 获取 政策解读数据
    getdata() {
      var formDatas = new FormData();
      formDatas.append("pageSize", this.pageSize);
      formDatas.append("pageNo", this.pageNo);
      formDatas.append("original", this.original);
      formDatas.append("positionTyps", this.positionTyps);
      formDatas.append("title", "");
      postData("/qbnews/qbNews/newlist", formDatas).then((res) => {
        if (res.data == "success") {
          if (res.qbNews.length > 4) {
            var qbnew = res.qbNews.slice(0, 4);
            this.qbNew1 = qbnew.slice(0, 1);
            this.qbNew2 = qbnew.slice(1, qbnew.length);
          } else {
            this.qbNew1 = res.qbNews.slice(0, 1);
            this.qbNew2 = res.qbNews.slice(1, res.qbNews.length);
          }
        }
      });
    },
    // 获取 案例实务数据
    getcase() {
      var formDatas = new FormData();
      formDatas.append("pageSize", this.pageSize);
      formDatas.append("pageNo", this.pageNo);
      formDatas.append("original", "4");
      formDatas.append("positionTyps", this.positionTyps);
      postData("/qbnews/qbNews/articlelist", formDatas).then((res) => {
        if (res.data == "success") {
          if (res.qbNews.length > 6) {
            this.anLiList = res.qbNews.slice(0, 6);
          } else {
            this.anLiList = res.qbNews;
          }
          this.anLiList[0].hot = true;
          // this.anLiList[1].hot = true;
          // this.anLiList[2].hot = true;
          this.anLiList.forEach((item) => {
            if (item.title.length > 10) {
              item.title1 = item.title.slice(0, 10);
              item.title2 = item.title.slice(10, item.title.length);
            } else {
              item.title1 = item.title;
            }
          });
          console.log(this.anLiList);
        }
      });
    },
    // 获取月刊数据
    getmoneyList() {
      getData("/qbmonthly/qbMonthly/monthlyList?year=2022&status='发布'").then(
        (res) => {
          if (res.data == "success") {
            this.yuekan_list = res.qbMonthly;
          }
        }
      );
      getData("/qbmonthly/qbMonthly/monthlyList?year=2021&status='发布'").then(
        (res) => {
          if (res.data == "success") {
            this.yuekan_list_1 = res.qbMonthly;
          }
        }
      );
    },
    // 查看更多
    lookmore() {
      // window.top.location.href("");
    },
    // 点击跳转政策详情
    policyDetail(id) {
      location.href = "/newsdetail?id=" + id;
      // this.$router.push("/newsdetail/" + id);
      // this.$router.push({
      //   path: "/newsdetail",
      //   query: { id: id },
      // });
    },
    kai() {
      console.log(1111);
      document.getElementById("zhezhao").style.display = "block";
    },
    guan() {
      console.log(2222);
      document.getElementById("zhezhao").style.display = "none";
    },
    buguan(event) {
      event.stopPropagation();
    },
    // 点击跳转案例实务详情
    pushcase(id) {
      location.href = "/casedetail?id=" + id;
      // this.$router.push({
      //   path: "/casedetail",
      //   query: { id: id },
      // });
    },
    // 点击下载图片
    // downloadCodeImg(img) {
    //   console.log(img);
    //   var image = new Image();
    //   image.setAttribute("crossOrigin", "anonymous");
    //   // var _this = this;
    //   image.onload = function () {
    //     var canvas = window.document.createElement("canvas");
    //     canvas.width = image.width;
    //     canvas.height = image.height;
    //     console.log(canvas);
    //     var context = canvas.getContext("2d");
    //     context.drawImage(image, 0, 0, image.width, image.height);
    //     var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    //     var a = window.document.createElement("a"); // 生成一个a元素
    //     var event = new MouseEvent("click"); // 创建一个单击事件
    //     a.download = "photo"; // 设置图片名称
    //     a.href = url; // 将生成的URL设置为a.href属性
    //     console.log(url);
    //     a.dispatchEvent(event); // 触发a的单击事件
    //   };
    //   image.src = img;
    //   image.onload();
    // },
  },
};
</script>
<style>
.content {
  text-align: center;
  margin-top: 30px;
}
.aa {
  display: flex;
  justify-content: space-between;
  padding: 23px;
  padding-bottom: 0;
}
.bb {
  display: flex;
  justify-content: space-between;
  padding-right: 23px;
}
.cc {
  width: 128px;
  background: red;
  color: white;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  cursor: pointer;
}
.anli {
  background-color: #fafafa;
  padding: 60px 0;
}
#caishui .top {
  margin-top: 60px;
}
#caishui .top img {
  width: 100%;
  height: auto;
}
.anli_item {
  position: relative;
  /* width: 392px; */
  text-align: center;
  background-color: #fff;
  margin: 0 13px 10px 0;
  cursor: pointer;
  width: 392px;
  padding: 64px 0;
  font-size: 20px;
  color: #5b5e66;
}
.anli_item:hover {
  color: #3d93f9;
}
.hot_img {
  position: absolute;
  right: 11px;
  top: 0px;
}
#zhezhao {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.tanchaung {
  width: 417px;
  margin: 0 auto;
  margin-top: 140px;
}
.cha {
  width: 18px;
  text-align: center;
  background: white;
  border-radius: 50%;
  float: right;
  cursor: pointer;
}
.yuekan_item {
  width: 382px;
  text-align: center;
  background-color: #fff;
  margin-right: 13px;
  margin-bottom: 9px;
  cursor: pointer;
  padding: 13px 0;
  font-size: 20px;
  color: #5b5e66;
}
.yuekan_item a {
  color: #5b5e66;
}
.yuekan_item a:hover {
  color: #3d93f9;
}
.yuekan_item:hover {
  color: #3d93f9;
}
.yuekan .el-tabs__item {
  height: 46px !important;
  line-height: 46px !important;
  font-size: 20px !important;
  color: #5b5e66 !important;
}
.yuekan .el-tabs__item.is-active {
  color: #3d93f9 !important;
}
.yuekan .el-tabs__item:hover {
  color: #3d93f9 !important;
  cursor: pointer;
}
.qbnwes_content {
  /* height: 150px; */
  text-align: left;
  padding: 23px 30px 21px 34px;
  background-color: #5b5e66;
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}
.qbnwes2_content {
  /* height: 150px; */
  text-align: left;
  padding: 23px 30px 21px 34px;
  background-color: #fff;
  margin-bottom: 9px;
  cursor: pointer;
}
.qbnew_items {
  width: 496px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.qbnew_items_1 {
  width: 496px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.lookmore {
  margin-top: 60px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.btn {
  text-align: center;
  width: 230px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  background-color: #5b5e66;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.lookmore a {
  color: #ffffff;
}
.btn:hover {
  background-color: #3d93f9;
  color: #ffffff;
}
.lookmore a:hover {
  color: #ffffff;
}
.btns {
  background-color: #3d93f9;
}
</style>
