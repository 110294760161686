<template>
  <div class="travel">
    <div class="top">
      <img src="@/assets\travel_img/banner.png" alt="" />
    </div>
    <div class="title_1">
      <img src="@/assets\travel_img/title1.png" alt="" />
      <div class="title1_content">
        <img src="@/assets\travel_img/title1_content.png" alt="" />
      </div>
      <div class="title_btn">
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuiwujicha
                    "
        >
          <img src="@/assets\travel_img/title1_btn.png" alt="" />
        </a>
      </div>
    </div>
    <div class="title_2">
      <div style="text-align: center">
        <div>
          <img src="@/assets\travel_img/title2.png" alt="" />
        </div>
        <div style="margin-top: 50px">
          <div style="display: flex; align-items: center">
            <div style="flex: 1"></div>
            <div style="flex: 8" class="title3_img">
              <div style="margin-right: 10px">
                <img src="@/assets\travel_img/title2_img1.png" alt="" />
              </div>
              <div style="margin-right: 10px">
                <img src="@/assets\travel_img/title2_img2.png" alt="" />
              </div>
              <div>
                <img src="@/assets\travel_img/title2_img3.png" alt="" />
              </div>
            </div>

            <div style="flex: 1"></div>
          </div>
        </div>
        <div class="title_btn">
          <a
             target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuiwujicha
                "
          >
            <img src="@/assets\travel_img/title2_btn.png" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div class="title_3">
      <div style="margin-bottom: 50px">
        <img src="@/assets\travel_img/title3.png" alt="" />
      </div>
      <div class="title3_img">
        <div style="flex: 1"></div>
        <div style="flex: 8" class="title3_img">
          <div>
            <img src="@/assets\travel_img/title3_img1.png" alt="" />
          </div>
          <div>
            <img src="@/assets\travel_img/title3_img2.png" alt="" />
          </div>
        </div>

        <div style="flex: 1"></div>
      </div>

      <div class="title3_img">
        <div style="flex: 1"></div>
        <div style="flex: 8" class="title3_img">
          <div>
            <img src="@/assets\travel_img/title3_img3.png" alt="" />
          </div>
          <div>
            <img src="@/assets\travel_img/title3_img4.png" alt="" />
          </div>
          <div>
            <img src="@/assets\travel_img/title3_img5.png" alt="" />
          </div>
        </div>

        <div style="flex: 1"></div>
      </div>
      <div style="margin-top: 62px">
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuiwujicha
                  "
        >
          <img src="@/assets\travel_img/title3_btn.png" alt="" />
        </a>
      </div>
    </div>
    <div class="title_4">
      <img src="@/assets\travel_img/title4.png" alt="" />
      <div style="margin-top: 94px">
        <img src="@/assets\travel_img/title4_content.png" alt="" />
      </div>
      <div style="margin-top: 62px">
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=shuiwujicha
                    "
        >
          <img src="@/assets\travel_img/title4_btn.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Travel",
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.travel {
  margin-top: 60px;
}

.travel .top img {
  width: 100%;
  height: auto;
}

.travel a {
  text-decoration: none;
}

.travel .title_1 {
  margin-top: 129px;
  text-align: center;
}

.travel .title1_content {
  margin-top: 46px;
}

.travel .title_btn {
  margin-top: 50px;
  cursor: pointer;
}

.travel .title_2 {
  margin-top: 130px;
  padding-top: 130px;
  padding-bottom: 130px;
  background-color: #f2f2f2;
}

.travel .title_3 {
  margin-top: 129px;
  text-align: center;
}

.travel .title3_img {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.travel .title_4 {
  margin-top: 129px;
  margin-bottom: 130px;
  text-align: center;
}
</style>
