<template>
  <div class="aboutus">
    <detail-nav-bar></detail-nav-bar>
    <div class="top">
      <img src="@/assets/taxation_img/caishui111.png" alt="" />
    </div>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <div class="aboutUs">
      <el-tabs v-model="aboutUs" stretch @tab-click="handleclick">
        <el-tab-pane label="政策解读" name="first">
          <!-- <police :plicyList="plicyList"></police> -->
        </el-tab-pane>
        <el-tab-pane label="案例实务" name="second">
          <case :caseList="caseList"></case>
        </el-tab-pane>
      </el-tabs>
    </div>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
// import Police from "@/views/viewpage/taxationInformation/policy.vue";
import Case from "@/views/viewpage/taxationInformation/case.vue";
// import banner from "@/components/banner.js";
import { postData } from "@/request/api";
export default {
  async asyncData({ store }) {
    // var FormData = require("form-data");
    // console.log(route.query.id);
    // var formdatas = new FormData();
    // const params = {
    //   id: route.query.id,
    //   positionType: "1",
    // };
    // formdatas.set("pageSize", "10");
    // formdatas.set("pageNo", "1");
    // formdatas.set("original", "3");
    // formdatas.set("positionTyps", "1");
    const heightnewList = await postData("/qbnews/qbNews/newlist");
    const case_ = await postData("/qbnews/qbNews/articlelist");
    // console.log(heightnewList);
    store.commit("policy", heightnewList);
    store.commit("case", case_);
  },
  metaInfo: {
    title: "财税资讯_值得信赖的企业服务平台_99企帮",
    meta: [
      {
        name: "keywords",
        content:
          "税收优惠，税收奖励，财税服务，财税顾问，财税平台，财税政策，账务梳理，代理记账，账务梳理，股权架构，高新认定，财务外包，政策解读，案例实务",
      },
      {
        name: "description",
        content:
          " 99企帮财税资讯栏目提供财税知识分享，财税资讯，财税政策解读，相关案例实务，并提供财务服务",
      },
    ],
  },
  name: "TAXATONINOR",
  components: {
    DetailNavBar,
    Foot,
    // Banner,
    // Police,
    Case,
  },
  data() {
    return {
      aboutUs: "second",
      // qbAdvertisements: [{ picture: require("@/assets/taxation_img/banner.png") }],
      footbg: {},
      plicyList: [],
      caseList: [],
    };
  },
  created() {
    this.plicyList = this.$store.state.policy.qbNews;
    this.caseList = this.$store.state.case.qbNews;
    // this.asyncData();/
  },
  methods: {
    handleclick(tab) {
      console.log(tab);
      if (tab.name == "first") {
        location.href = "/view/taxation";
      }
    },
  },
};
</script>
<style>
.aboutus .top {
  margin-top: 60px;
}
.aboutus .top img {
  width: 100%;
  height: auto;
}
.aboutUs .el-tabs__item.is-active {
  color: #3d93f9 !important;
}
.aboutUs .el-tabs__active-bar {
  display: none !important;
}
.aboutUs .el-tabs__item {
  height: 60px !important;
  line-height: 60px !important;
  font-size: 17px !important;
  color: #5b5e66 !important;
}
.aboutUs .el-tabs__item:hover {
  color: #3d93f9 !important;
  cursor: pointer;
}
.aboutUs .el-tabs__nav-scroll {
  padding: 0 30% !important;
}
</style>
