<template>
  <div class="taxserve">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <!-- 具体内容 -->
    <div id="taxserve">
      <div class="top">
        <img src="@/assets/taxation_service_img/banner.jpg" alt="" />
      </div>
      <img src="@/assets/taxation_service_img/title1.jpg" alt="" />
      <div style="text-align: center">
        <div>
          <div class="flex">
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title1_img5.jpg"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title1_img2.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title1_img3.jpg"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title1_img4.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <a
           target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=caishuizixunpc"
          class="point"
        >
          <img src="@/assets/taxation_service_img/title1_btn.jpg" alt="" />
        </a>
      </div>

      <img src="@/assets/taxation_service_img/title2.jpg" alt="" />
      <div style="text-align: center">
        <div>
          <div class="flex">
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title2_img1.jpg"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title2_img2.jpg"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title2_img3.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title2_img4.jpg"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title2_img5.jpg"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div>
                <img
                  src="@/assets/taxation_service_img/title2_img6.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bgc">
        <img
          src="@/assets/taxation_service_img/title3.jpg"
          style="width: 100%"
          alt=""
        />
      </div>
    </div>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
// import banner from "@/components/banner.js";

export default {
  name: "TAXSERVE",
  components: { DetailNavBar, Foot },
  data() {
    return {
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
<style>
.taxserve {
  background-color: #fff !important;
}
#taxserve {
  text-align: center;
}
#taxserve .top {
  margin-top: 60px;
}
#taxserve .top img {
  width: 100%;
  height: auto;
}
#taxserve .flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
#taxserve .point {
  cursor: pointer;
}
#taxserve .bgc {
  /* height: 646px;
  background: url("../assets/taxation_service_img/title3.jpg"); */
  width: 100%;
  /* background-repeat: no-repeat;
  background-size: cover; */
}
</style>
