<template>
  <div class="graden">
    <detail-nav-bar></detail-nav-bar>
    <div id="ServerDetail">
      <!--产品介绍-->
      <div class="product-introduction body-section">
        <!--面包屑导航-->
        <div class="container">
          <div class="guide">
            <a href="/">首页</a>&nbsp;>&nbsp;<a href="/view/99shuiyou">99税优</a
            >&nbsp;>&nbsp;<a href="javascript:void(0)">企园宝</a>
          </div>
        </div>
        <!-- 企园宝数据 -->
        <div class="content_box">
          <div>
            <img :src="merchandise.pcPic" alt="" />
          </div>
          <div class="box_left">
            <div class="name">{{ merchandise.name }}</div>
            <div class="description" style="margin-top: 17px">
              产品说明:
              <span class="data_list">{{ merchandise.description }}</span>
            </div>
            <div class="description" style="margin-top: 17px">
              市&nbsp;&nbsp;场&nbsp;&nbsp;价:
              <span class="data_list">{{ merchandise.marketPrice }}</span>
            </div>
            <div class="description" style="margin-top: 17px">
              价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格:
              <span class="data_list price"
                >￥{{ merchandise.price
                }}<span style="font-size: 16px">元/年</span></span
              >
            </div>
            <div class="description" style="margin-top: 17px">
              <div class="flex">
                <div>类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别:</div>
                <div class="data_list ul">
                  <div v-for="(item, index) in qbSku" :key="index">
                    <div
                      class="cityList"
                      :class="chose ? 'chosrcitylist' : 'cityList'"
                      @click="chosecity(index)"
                      v-show="item.province"
                    >
                      {{ item.province }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="" class="btn">
              <a
                 target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=www.99qibang.com"
              >
                <img src="@/assets/serverDetail_img/btn.png" alt="" />
              </a>
              <!-- <div class="fuwu" @click="kai">购买服务</div> -->
            </div>
          </div>
        </div>
        <div id="zhezhao" @click="guan" style="">
          <div class="tanchaung">
            <div class="cha" @click="guan">x</div>
            <div>
              <img
                src="@/assets/99caishui_img/qrcode_99qibang.png"
                alt=""
                style="width: 400px"
                @click="buguan"
              />
            </div>
          </div>
        </div>
        <!-- 商品详情 -->
        <div class="content_detail">
          <div class="detail_title">商品详情</div>
          <!-- <div>
          <img src="@/assets/serverDetail_img/1.jpg" alt="" />
        </div> -->
          <div>
            <img src="@/assets/serverDetail_img/2.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/3.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/4.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/5.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/6.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/7.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/8.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/9.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/10.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/11.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/12.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/serverDetail_img/13.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Foot from "@/components/foot/foot.vue";
// import Garden from "@/views/viewpage/gardentreasure.vue";
// import banner from "@/components/banner.js";
import { postData } from "@/request/api";
export default {
  // async asyncData({ route, store }) {
  //   console.log(route.query.id);
  //   var formdatas = new FormData();
  //   // const params = {
  //   //   id: route.query.id,
  //   //   positionType: "1",
  //   // };
  //   formdatas.set("id", route.query.id);
  //   formdatas.set("positionType", "1");
  //   const heightnewList = await postData(
  //     "/qbmerchandise/qbMerchandise/findMerDetail",
  //     formdatas
  //   );
  //   console.log(heightnewList);
  //   store.commit("heightnewList", heightnewList);
  // },
  name: "GARDEN",
  components: { DetailNavBar, Foot },
  data() {
    return {
      footbg: {},
      id: "",
      merchandise: {},
      qbSku: [],
      chose: false,
    };
  },
  created() {
    this.getDeatil();
  },
  methods: {
    async getDeatil() {
      var FormData = require("form-data");
      var formdatas = new FormData();
      // const params = {
      //   id: route.query.id,
      //   positionType: "1",
      // };
      formdatas.append("id", this.$route.query.id);
      formdatas.append("positionType", "1");
      const heightnewList = await postData(
        "/qbmerchandise/qbMerchandise/findMerDetail",
        formdatas
      );
      this.$store.commit("heightnewList", heightnewList);
      this.merchandise = this.$store.state.heightnewList.merchandise;
      this.qbSku = this.$store.state.heightnewList.qbSku;
    },
    kai() {
      console.log(1111);
      document.getElementById("zhezhao").style.display = "block";
    },
    guan() {
      console.log(2222);
      document.getElementById("zhezhao").style.display = "none";
    },
    buguan(event) {
      event.stopPropagation();
    },
    chosecity(index) {
      this.merchandise.name = this.qbSku[index].merName;
      this.merchandise.price = this.qbSku[index].price;
      this.chose = true;
    },
  },
};
</script>
<style scoped>
.body-section {
  margin-top: 80px;
}

.product-introduction {
  width: 100%;
  margin-bottom: 20px;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.guide {
  line-height: 56px;
  height: 56px;
  color: #4a4a4a;
  background-color: #fcfcfc;
}
.guide a {
  color: #4a4a4a;
}
.content_box {
  margin-top: 66px;
  display: flex;
  justify-content: center;
}
.box_left {
  margin-left: 32px;
}
.name {
  font-size: 36px;
  color: #5b5e66;
}
.description {
  font-size: 20px;
  color: #999999;
  text-align: left;
}
.data_list {
  margin-left: 35px;
  font-size: 20px;
  color: #5b5e66;
}
.price {
  font-size: 40px;
  color: #da0000;
}
.content_detail {
  text-align: center;
  margin-top: 200px;
}

.detail_title {
  height: 60px;
  line-height: 60px;
  color: #999999;
}
.btn {
  cursor: pointer;
  margin-top: 30px;
  /* width: 100%; */
  background: white;
  /* display: flex; */
}
.fuwu {
  width: 230px;
  display: inline-block;
  height: 50px;
  border-radius: 50px;
  background: red;
  /* margin-left: 20px; */
}
.flex {
  display: flex;
  align-items: center;
  justify-content: left;
}
.ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}
.cityList {
  padding: 10px 88px;
  font-size: 16px;
  border: 1px solid #999999;
  color: #5b5e66;
  margin-right: 17px;
}
.chosrcitylist {
  padding: 10px 88px;
  font-size: 16px;
  border: 1px solid #3d93f9;
  color: #3d93f9;
  margin-right: 17px;
}
#zhezhao {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.tanchaung {
  width: 417px;
  margin: 0 auto;
  margin-top: 140px;
}
.cha {
  width: 18px;
  text-align: center;
  background: white;
  border-radius: 50%;
  float: right;
  cursor: pointer;
}
</style>
