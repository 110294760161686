<template>
  <div class="taxplan">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <taxplan></taxplan>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Taxplan from "@/views/viewpage/taxPlanning.vue";
// import banner from "@/components/banner.js";

export default {
  name: "TAXPLAN",
  components: { DetailNavBar, Foot, Taxplan },
  data() {
    return {
      // qbAdvertisements: [
      //   {
      //     picture: require("@/assets/taxPlan_img/banner.png"),
      //     href: "/view/99shuiyou",
      //   },
      // ],
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
