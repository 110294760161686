<template>
  <div class="travel">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <travel></travel>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import Travel from "@/views/viewpage/travel.vue";
// import banner from "@/components/banner.js";

export default {
  name: "TRAVEL",
  components: { DetailNavBar, Foot, Travel },
  data() {
    return {
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
