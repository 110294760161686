<template>
  <div class="equity">
    <detail-nav-bar></detail-nav-bar>
    <equity></equity>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
import Equity from "@/views/viewpage/equity.vue";
import Foot from "@/components/foot/foot.vue";
// import banner from "@/components/banner.js";

export default {
  name: "EQUITY",
  components: { DetailNavBar, Equity, Foot },
  data() {
    return {
      footbg: {
        picture: require("@/assets/equity_img/gaolou.png"),
      },
    };
  },
  created() {},
  methods: {},
};
</script>
