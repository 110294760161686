<template>
  <div class="taxcut">
    <detail-nav-bar></detail-nav-bar>
    <!-- <banner :qbAdvertisements="qbAdvertisements"></banner> -->
    <tax-cut></tax-cut>
    <foot :footbg="footbg"></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import DetailNavBar from "@/components/datailNavBar/detailNavBar.vue";
// import Banner from "@/components/banners/banner.vue";
import Foot from "@/components/foot/foot.vue";
import TaxCut from "@/views/viewpage/taxCut.vue";
// import banner from "@/components/banner.js";

export default {
  name: "TAXCUT",
  components: { DetailNavBar, Foot, TaxCut },
  data() {
    return {
      // qbAdvertisements: [
      //   {
      //     picture: require("@/assets/taxcut_img/banner.png"),
      //     href: "https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo",
      //   },
      // ],
      footbg: {},
    };
  },
  created() {},
  methods: {},
};
</script>
